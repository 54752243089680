import React, { useState } from "react";
import { Redirect } from "react-router";
import PleaseWait from "../../common/PleaseWait";
import { api, baseURL, Validator } from "../../utilities";
import { useSelector } from "react-redux";

export default function ChangePassword() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const { token: AuthToken } = useSelector(store => store.user);
  const [, forceUpdate] = useState(false);
  const validator = Validator();
  const submitLogin = (e) => {
    e.preventDefault();

    if (validator.current.allValid()) {
      setLoading(true);
      let userId = localStorage.getItem("id") != null ? localStorage.getItem("id") : null;
      let user = api(
        baseURL + "/changePassword",
        "PUT",
        { "content-type": "application/json", Authorization: AuthToken },
        { userId, oldpassword: password, newpassword },
      );
      user
        .then((data) => {
          if (data.success) {
            setMessage("Your password changed successfully!");
            setLoggedIn(true);
            localStorage.clear();
            window.location.reload();
            setLoading(false);
          } else {
            setMessage("Invalid credentials");
            setLoading(false);
            setLoggedIn(false);
          }
        })
        .catch((error) => {
          setMessage("Something went wrong!");
          setLoading(false);
        });
    } else {
      validator.current.showMessages();
      forceUpdate(true);
    }
  };
  return (
    <div className="container-fluid text-center custom-bg2 vh-100">
      <div className="row justify-content-center align-items-center m-0 p-0">
        <div className="col-md-5 col-xs-12 m-3 p-3 border rounded shadow bg-dark text-white">
          <form onSubmit={submitLogin}>
            <i className="fa fa-lock" style={{ fontSize: 200, color: "orange" }} />
            <h1 className="mb-4">Change password</h1>
            <input
              type="password"
              placeholder="Old password"
              className="form-control"
              value={password}
              maxLength={16}
              minLength={8}
              //   required
              onChange={(e) => setPassword(e.target.value)}
            />
            {validator.current.message("Password ", password, "required|password")}
            <br />
            <br />
            <input
              type="password"
              placeholder="New Password"
              className="form-control"
              value={newpassword}
              maxLength={16}
              minLength={8}
              //   required
              onChange={(e) => setNewPassword(e.target.value)}
            />
            {validator.current.message("Password ", newpassword, "required|password")}
            <br />
            <br />
            <input
              type="password"
              placeholder="Confirm password"
              className="form-control"
              value={confirmpassword}
              maxLength={16}
              minLength={8}
              //   required
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            {validator.current.message("Confirm Password ", confirmpassword, "required|password")}
            {newpassword.length > 0 && confirmpassword.length > 0 && newpassword !== confirmpassword ? (
              <span className="error-message">Passwords are not matching</span>
            ) : (
              ""
            )}
            {<span>{message}</span>}
            <br />
            {!loading && <input type="submit" value="Update" className="btn btn-primary w-50" />}
            {loading && <PleaseWait content={"Please wait..."} />}
            <br />
            <br />
          </form>
        </div>
      </div>
      {loggedIn && <Redirect to="/login" />}
    </div>
  );
}
