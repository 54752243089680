import React, { useEffect, useState } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { allCoursesStored, api, baseURL } from "../../utilities";
import { useDispatch, useSelector } from "react-redux";
import { setBuynowItems, setCartCount, setCartItems, setReferralCode } from "../../redux/reducers/courseSlice";
import { openPlayer } from "../VMVideoPlayer/useCourses";
import { setPaymentAmount, setReferralCodeGlobal, setRewardPointsGlobal } from "../../redux/reducers/userSlice";

export default function PaymentStatus() {
  const { paymentToken } = useParams();
  const [verified, setVerified] = useState(false);
  const [enrolling, setEnrolling] = useState(false);
  const [loading, setLoading] = useState(false);
  const { cartItems: selectedCourses = [] } = useSelector((store) => store.course);
  const [allCourses,] = useState(allCoursesStored ? allCoursesStored : []);

  const selectedCourseComboIds = selectedCourses.length > 0 ? selectedCourses.map(course => course.courseCombo) : [];
  const selectedCourseIds = selectedCourses.length > 0 ? selectedCourses.filter(course => course.courseCombo.length === 0).map(course => course._id) : [];
  const totalCourseIds = [...selectedCourseComboIds.flat(), ...selectedCourseIds];
  const uniqueCourseIds = new Set(totalCourseIds);
  const finalSelectedCourses = allCourses.filter(course => [...uniqueCourseIds].includes(course._id));

  useEffect(() => {
    if (paymentToken) {
      setLoading(true);
      try {
        let user = api(baseURL + "/validatePaymentOrder", "POST", { "content-type": "application/json" }, {
          paymentToken
        });
        user
          .then((data) => {
            if (data.success) {
              setVerified(true);
              setLoading(false);
              setTimeout(() => {
                setEnrolling(true);
              }, 2000);
            } else {
              setVerified(false);
              setLoading(false);
              setEnrolling(false);
            }
          })
          .catch((error) => {
            // alert("Error",error);
            setVerified(false);
            setLoading(false);
            setEnrolling(false);
          });
      } catch (error) {
        console.log("Error", error);
        setVerified(false);
        setLoading(false);
        setEnrolling(false);
      }
    }
  }, [paymentToken])

  const courseNames = finalSelectedCourses.length > 0 ? finalSelectedCourses.map(course => course.title).join(",") : '';

  return (
    <>
      <div className="d-flex justify-content-center align-items-center vh-100 bg-light">
        <div className="container d-flex flex-column align-items-center gap-3">
          {loading ? <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Hourglass%20Done.png" alt="Hourglass Done" width="300" height="300" /> :
            <>
              {verified && !enrolling && <div className="success status-card border border-success">
                <h1 className="text-success">Payment Successful!</h1>
                <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Check%20Mark%20Button.png" alt="Check Mark Button" width="200" height="200" />
              </div>}

              {!verified && !enrolling && <div className="failure status-card border border-danger">
                <h1 className="text-danger">Payment Failed!</h1>
                <div>
                  <Link to='/cartitems'>
                    <button className="btn btn-primary p-4 shadowAnim"><img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Hand%20gestures/Backhand%20Index%20Pointing%20Right.png" alt="Backhand Index Pointing Right" width="25" height="25" /> Try Again!</button>
                  </Link>
                  <p className="m-0">Or</p>
                  <a href={`https://mail.google.com/mail/?view=cm&fs=1&to=mogilivenkatesh3@gmail.com&su=VM Online Training - Payment Failed❌&body=Hello Venkatesh!%0D%0A%0D%0AI'm facing an issue with payment while enrolling in these courses [${courseNames}]. Please help me to solve the issue.%0D%0A%0D%0AThank you.`} target="_blank" rel="noreferrer">
                    <button className="btn btn-primary">Contact Support</button>
                  </a>
                </div>
              </div>}


              {verified &&
                enrolling &&
                <CreateEnrolls
                  selectedCourses={finalSelectedCourses}
                  courseNames={courseNames}
                />
              }
            </>}
        </div>
      </div>
    </>
  );
}

function CreateEnrolls({ selectedCourses = [], courseNames = ''}) {
  const [enrolled, setEnrolled] = useState(null);
  const [loading, setLoading] = useState(false);
  const { token: AuthToken, id: AuthUserId, email: AuthEmail, username: AuthUsername,referralCode = null, paymentAmount = 0, rewardPoints = 0  } = useSelector(store => store.user);
  const dispatch = useDispatch();
  const history = useHistory();

  const createEnrollment = () => {
    if (AuthToken && selectedCourses.length > 0) {
      try {
        const coursesInfo = [...selectedCourses];
        setLoading(true);
        // check already enrolled!
        let user = api(baseURL + "/isEnrollAlready", "POST", { "content-type": "application/json", "Authorization": AuthToken }, {
          userId: AuthUserId,
          coursesInfo
        });
        user
          .then((data) => {
            if (data.success) {
              const enrollment = api(baseURL + "/createEnrolls", "POST", { "content-type": "application/json", "Authorization": AuthToken }, {
                userId: AuthUserId,
                name: AuthUsername,
                email: AuthEmail,
                orderDetails: {},
                coursesInfo,
                referralCode,
                paymentAmount,
                rewardPoints
              });
              enrollment
                .then((data) => {
                  if (data.success) {
                    setLoading(false);
                    setEnrolled(true);
                    setTimeout(() => {
                      localStorage.removeItem("cartItems");
                      localStorage.removeItem("buynowItems");
                      localStorage.removeItem("referralCode");
                      localStorage.removeItem("paymentAmount");
                      localStorage.removeItem("rewardPoints");
                      localStorage.removeItem("referralCodeShared");
                      dispatch(setCartCount(0));
                      dispatch(setCartItems([]));
                      dispatch(setBuynowItems([]));
                      dispatch(setReferralCodeGlobal(''));
                      dispatch(setReferralCode(null));
                      dispatch(setPaymentAmount(0));
                      dispatch(setRewardPointsGlobal(0));
                      if (coursesInfo.length === 1) {
                        openPlayer({ _id: coursesInfo[0]._id }, history)
                      } else {
                        history.replace('/vm/vmcourses');
                      }
                    }, 3000);
                  } else {
                    console.log('failed!')
                    setEnrolled(false);
                    setLoading(false);
                  }
                })
                .catch((error) => {
                  console.log("Error", error);
                  setEnrolled(false);
                  setLoading(false);
                });
            } else {
              console.log('failed!')
              setEnrolled(false);
              setLoading(false);
            }
          })
          .catch((error) => {
            console.log("Error", error);
            setEnrolled(false);
            setLoading(false);
          });

      } catch (error) {
        console.log("Error", error);
        setEnrolled(false);
        setLoading(false);
      }
    } else {
      alert("Login to proceed!");
    }
  }

  useEffect(() => {
    createEnrollment();
  }, [])

  return (
    <>
      {loading ? <div className="status-card border border-primary">
        <h1 className="text-primary">Creating Enrollments, Please wait...</h1>
        <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/People/Man%20Technologist.png" alt="Man Technologist" width="300" height="300" />
      </div> :
        <>
          {enrolled && <div className="success status-card border border-success">
            <h1 className="text-success">Enrollment Successful!</h1>
            <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Partying%20Face.png" alt="Partying Face" width="300" height="300" />
          </div>}
          {enrolled === false && <div className="failure status-card border border-danger">
            <h1 className="text-danger">Enrollment Failed!</h1>
            <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Cross%20Mark.png" alt="Cross Mark" width="200" height="200" />
            <div>
              <a href={`https://mail.google.com/mail/?view=cm&fs=1&to=mogilivenkatesh3@gmail.com&su=VM Online Training - Enrollment Failed❌&body=Hello Venkatesh!%0D%0A%0D%0AI'm facing an issue while enrolling in these courses [${courseNames}]. Please help me to solve the issue.%0D%0A%0D%0AThank you.`} target="_blank" rel="noreferrer">
                <button className="btn btn-primary"><img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Hand%20gestures/Backhand%20Index%20Pointing%20Right.png" alt="Backhand Index Pointing Right" width="25" height="25" /> Contact Support</button>
              </a>
            </div>
          </div>}
        </>}
    </>
  )
}
