import React from "react";
import ReactSpinner from "react-bootstrap-spinner";

export function LoadingIndicatorGlobal({ size = "1", color = "white", type = "border",text="Loading..." }) {
  return (
    <div className="bg-dark fixed-top bg-loader text-center opacity-80">
      <button className="btn btn-primary d-flex align-items-center">
        <ReactSpinner type={type} color={color} size={size} />
        <span className="m-2">{text}</span>
      </button>
    </div>
  );
}
