import React from 'react'
import CourseLoader from './CourseLoader'
import { filterCourseByCategory } from '../useCourses'
import { useSelector } from 'react-redux';

export default function Recommendations({ remainingCourses, loading, selectedCategory, openPlayer, history }) {
  const { id: AuthUserId } = useSelector(store => store.user);
  return (
    <>
      {remainingCourses.length > 0 && <div className='bg-dark text-white rounded p-2  mt-2 mb-2'>
        <h4 className='m-2'>Recommended Courses For You</h4>
        <div className='d-flex flex-wrap'>
          {loading ? <CourseLoader height={175} /> : (remainingCourses.length > 0 && (AuthUserId === null ? filterCourseByCategory(remainingCourses, selectedCategory) : remainingCourses).map((course, index) => (
            <div className='col-md-2 mt-2 mb-2 text-center course-item' onClick={() => openPlayer({ ...course, isNotEnrolled: true }, history)} key={index}>
              <div className='card shadow m-2'>
                <img src={course.courseLogo} alt="thumbnail" className='img-thumbnail border-0' />
                <p className='bg-warning p-2 font-weight-bold m-1 rounded'>Enroll Now</p>
              </div>
            </div>
          )))}
        </div>
      </div>}
    </>
  )
}
