import { useState } from "react";
import { allAvailableCourses, discount, filteredDate, reactExpireDate, regularBatchText, trainingDiscount } from "../../../utilities/constants";
import { Link, useHistory } from "react-router-dom";
import { openPlayer } from "../../VMVideoPlayer/useCourses";

export const useCourseData = (selectedBatch) => {
  const isRegularBatch = selectedBatch===regularBatchText;
  const history = useHistory();
  let [monthEndDate] = useState(filteredDate);
  let courseData = [
    {
      coursepath: "reacttypescript",
      courseOffer: {
        amount: "1650",
        original: 2500,
        enrollamount: "5000",
        enrolloriginal: "8000",
        discount,
        expireDate: monthEndDate,
      },
      header: {
        title: "React TypeScript",
        theme: "primary",
        courseimage: "reacttypescriptcourse.png",
        syllabus: "https://drive.google.com/file/d/1NXl_DMOYA9aXN_c2krfSwzC24ShD-exf/view",
        demo: "https://youtu.be/0R7Qm_CHA24",
        isNew: true,
        isEnroll: false,
        isPurchase: true,
        curriculum: () => (
          <div
            className="col-md-6 col-xs-12 rounded bg-dark text-white p-2 mt-3"
            style={{ border: "10px solid whitesmoke" }}>
            <h4 className="text-center">
              <b>React TypeScript Course Curriculum</b><br/><br/>
            </h4>
            <div>
              <ul>
                <li>Week-1: Introduction, Fundamentals and React Essentials </li>
                <br />
                <li>Week-2: Basic Props and Advanced Props and Hooks</li>
                <br />
                <li>Week-3: Component Props, Restricting Props and Generic Props</li>
                <br />
                <li>Week-4: Recipe and Portfolio Practical Apps</li>
                <br />
                <li>Week-5: Quiz and Music Player Practical Apps</li>
                <br />
                <br />
              </ul>
              <a
                href="https://drive.google.com/file/d/1NXl_DMOYA9aXN_c2krfSwzC24ShD-exf/view"
                target="_blank"
                rel="noopener noreferrer"
                className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                <i className="fa fa-file-pdf-o" /> VIEW DETAILED CURRICULUM
              </a>
              <div
                onClick={() => openPlayer({ _id: "6587f82ef1dc6800081c2db3" }, history)}
                target="_blank"
                rel="noopener noreferrer"
                className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded cursor-pointer text-primary p-3">
                <i className="fa fa-youtube-play text-danger" /> Start Free Demo
              </div>
            </div>
          </div>
        )
      },
      coursefeatures: {
        videos: { count: "10", duration: "9+" },
        quizzes: "6",
        tasks: "20",
        certificateRole: "React Frontend Developer",
      },
      relatedCourses: [
        { ...allAvailableCourses.javascript },
        { ...allAvailableCourses.reactredux },
        { ...allAvailableCourses.nodejs },
      ],
      requirements: {
        hardwarerequirements: {
          memory: "2/4 GB",
          processor: "i3",
          storage: "20 GB",
        },
        softwarerequirements: {
          os: "Any OS (Windows 7 or later/Linux/Mac)",
          ide: "Visual Studio Code (for web app development)",
          browser: "Chrome/Mozilla Firefox (latest version)",
        },
        certificateImage: "certificate.png",
      }
    },
    {
      coursepath: "html5",
      courseOffer: {
        amount: "1650",
        original: 2000,
        enrollamount: "5000",
        enrolloriginal: "8000",
        discount,
        expireDate: monthEndDate,
      },
      header: {
        title: "HTML5",
        theme: "danger",
        courseimage: "html5course.png",
        syllabus: "https://drive.google.com/file/d/1O0ybXXM6DXQg_GU7rz-Ig_hpAgqHslSK/view?usp=sharing",
        demo: "https://youtu.be/jrvEpbX2R4s",
        isNew: true,
        isEnroll: false,
        isPurchase: true,
        curriculum: () => (
          <div
            className="col-md-6 col-xs-12 rounded bg-dark text-white p-2 mt-3"
            style={{ border: "10px solid whitesmoke" }}>
            <h4 className="text-center">
              <b>HTML5 Course Curriculum</b>
            </h4>
            <div>
              <ul>
                <li>Week-1: Introduction, Environment Setup, Basics, HTML Fundamentals </li>
                <br />
                <li>Week-2: Colors, CSS, Links, Images, Tables, Lists, Block & Inline</li>
                <br />
                <li>Week-3: Responsive Design, HTML vs XHTML, Forms</li>
                <br />
                <li>Week-4: Multimedia, HTML APIs, Drag and Drop, Web Storage</li>
                <br />
                <li>
                  Week-5: One to One Mock Interview will be taken and interview preparation guidance will be given.
                </li>
                <br />
                <br />
              </ul>
              <a
                href="https://drive.google.com/file/d/1O0ybXXM6DXQg_GU7rz-Ig_hpAgqHslSK/view?usp=sharing"
                target="_blank"
                rel="noopener noreferrer"
                className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                <i className="fa fa-file-pdf-o" /> VIEW DETAILED CURRICULUM
              </a>
              <div
                onClick={() => openPlayer({ _id: "60f39d92b51e6f00081bb9bf" }, history)}
                target="_blank"
                rel="noopener noreferrer"
                className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded cursor-pointer text-primary p-3">
                <i className="fa fa-youtube-play text-danger" /> Start Free Demo
              </div>
            </div>
          </div>
        )
      },
      coursefeatures: {
        videos: { count: "8", duration: "6.5+" },
        quizzes: "8",
        tasks: "28",
        certificateRole: "Web Developer",
      },
      relatedCourses: [
        { ...allAvailableCourses.css3 },
        { ...allAvailableCourses.javascript },
        { ...allAvailableCourses.javascriptsmart },
      ],
      requirements: {
        hardwarerequirements: {
          memory: "2/4 GB",
          processor: "i3",
          storage: "20 GB",
        },
        softwarerequirements: {
          os: "Any OS (Windows 7 or later/Linux/Mac)",
          ide: "Visual Studio Code (for web app development)",
          browser: "Chrome/Mozilla Firefox (latest version)",
        },
        certificateImage: "html5-certificate.png",
      }
    },
    {
      coursepath: "css3",
      courseOffer: {
        amount: "1650",
        original: 2200,
        enrollamount: "5000",
        enrolloriginal: "8000",
        discount,
        expireDate: monthEndDate,
      },
      header: {
        title: "CSS3",
        theme: "success",
        courseimage: "css3course.png",
        syllabus: "https://drive.google.com/file/d/1WNH4ipulz90usq1v3d-QCbpngNaYNa9b/view?usp=sharing",
        demo: "https://youtu.be/_EOHgXCysWE",
        isNew: true,
        isEnroll: false,
        isPurchase: true,
        curriculum: () => (
          <div
            className="col-md-6 col-xs-12 rounded bg-dark text-white p-2 mt-3"
            style={{ border: "10px solid whitesmoke" }}>
            <h4 className="text-center">
              <b>CSS3 Course Curriculum</b>
            </h4>
            <div>
              <ul>
                <li>Week-1: Introduction to CSS, CSS Fundamentals</li>
                <br />
                <li>Week-2: Responsive Design, Media Queries, CSS Flexbox</li>
                <br />
                <li>Week-3: Gradients, Shadows, Animations, Transitions, Transforms</li>
                <br />
                <li>Week-4: CSS Grid, Variables, Bootstrap, Full Project and CSS Best Practices</li>
                <br />
                <li>
                  Week-5: One to One Mock Interview will be taken and interview preparation guidance will be given.
                </li>
                <br />
                <br />
              </ul>

              <Link to="/onlinetest/HTML%20Test/course"
                className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                <i className="fa fa-pencil" /> HTML Test - Prerequisite - Password: TestUser@123
              </Link>
              <a
                href="https://drive.google.com/file/d/1WNH4ipulz90usq1v3d-QCbpngNaYNa9b/view?usp=sharing"
                target="_blank"
                rel="noreferrer"
                className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                <i className="fa fa-file-pdf-o" /> VIEW DETAILED CURRICULUM
              </a>
              <div
                onClick={() => openPlayer({ _id: "614771dbc8da4a0008fe845e" }, history)}
                target="_blank"
                rel="noreferrer"
                className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded cursor-pointer text-primary p-3">
                <i className="fa fa-youtube-play text-danger" /> Start Free Demo
              </div>
            </div>
          </div>
        )
      },
      coursefeatures: {
        videos: { count: "10", duration: "11+" },
        quizzes: "8",
        tasks: "24",
        certificateRole: "Web Developer",
      },
      relatedCourses: [
        { ...allAvailableCourses.html5 },
        { ...allAvailableCourses.javascript },
        { ...allAvailableCourses.reactredux },
      ],
      requirements: {
        hardwarerequirements: {
          memory: "2/4 GB",
          processor: "i3",
          storage: "20 GB",
        },
        softwarerequirements: {
          os: "Any OS (Windows 7 or later/Linux/Mac)",
          ide: "Visual Studio Code (for web app development)",
          browser: "Chrome/Mozilla Firefox (latest version)",
        },
        certificateImage: "html5-certificate.png",
      }
    },
    {
      coursepath: "javascript",
      courseOffer: {
        amount: "1875",
        original: 2500,
        enrollamount: "8000",
        enrolloriginal: "15000",
        discount,
        expireDate: monthEndDate,
      },
      header: {
        title: "JavaScript",
        theme: "primary",
        courseimage: "javascriptcourse.png",
        syllabus: "https://drive.google.com/file/d/1lUjojj-VQzwsAqaf54ms-5tRKK5T6c6c/view?usp=sharing",
        demo: "https://youtu.be/yDVTHL9AuhE",
        isNew: true,
        isEnroll: false,
        isPurchase: true,
        curriculum: () => (
          <div
            className="col-md-6 col-xs-12 rounded bg-dark text-white p-2 mt-3"
            style={{ border: "10px solid whitesmoke" }}>
            <h4 className="text-center">
              <b>JavaScript Course Curriculum</b>
            </h4>
            <div>
              <ul>
                <li>Week-1: Introduction, Developer Tools , JavaScript Basics</li>
                <br />
                <li>Week-2: JavaScript Fundamentals, Interacting with Users</li>
                <br />
                <li>Week-3: Arrays, Objects, JSON</li>
                <br />
                <li>Week-4: Functions, Generators, Events Handling and Web Storage</li>
                <br />
                <li>Week-5: DOM, Nodes, Dates, Built-in methods, jQuery Basics</li>
                <br />
                <li>Week-6: ES6 Features in JavaScript, Object Oriented Programming</li>
                <br />
                <li>
                  Week-7: One to One Mock Interview will be taken and interview preparation guidance will be given.
                </li>
              </ul>
              <br />
              <Link to="/onlinetest/HTML%20Test/course"
                className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                <i className="fa fa-pencil" /> HTML Test - Prerequisite - Password: TestUser@123
              </Link>
              <Link to="/onlinetest/CSS%20Test/course"
                className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                <i className="fa fa-pencil" /> CSS Test - Prerequisite - Password: TestUser@123
              </Link>
              <a
                href="https://drive.google.com/file/d/1lUjojj-VQzwsAqaf54ms-5tRKK5T6c6c/view?usp=sharing"
                target="_blank"
                className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                <i className="fa fa-file-pdf-o" /> VIEW DETAILED CURRICULUM
              </a>
              <div
                onClick={() => openPlayer({ _id: "60c7110a1527710009ff2ee6" }, history)}
                target="_blank"
                className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded cursor-pointer text-primary p-3">
                <i className="fa fa-youtube-play text-danger" /> Start Free Demo
              </div>
            </div>
          </div>
        )
      },
      coursefeatures: {
        videos: { count: "12", duration: "15+" },
        quizzes: "24",
        tasks: "24",
        certificateRole: "Web Developer",
      },
      relatedCourses: [
        { ...allAvailableCourses.javascriptsmart },
        { ...allAvailableCourses.reactredux },
        { ...allAvailableCourses.nodejs },
      ],
      requirements: {
        hardwarerequirements: {
          memory: "2/4 GB",
          processor: "i3",
          storage: "20 GB",
        },
        softwarerequirements: {
          os: "Any OS (Windows 7 or later/Linux/Mac)",
          ide: "Visual Studio Code (for web app development)",
          browser: "Chrome/Mozilla Firefox (latest version)",
        },
        certificateImage: "javascript-certificate.png",
      }
    },
    {
      coursepath: "reactredux",
      courseOffer: {
        amount: "1875",
        original: 2500,
        enrollamount: "12750",
        enrolloriginal: "17000",
        discount,
        expireDate: monthEndDate,
      },
      header: {
        title: "REACT AND REDUX",
        theme: "warning",
        courseimage: "reactreduxcoursefull.png",
        syllabus: "https://drive.google.com/file/d/1lUjojj-VQzwsAqaf54ms-5tRKK5T6c6c/view?usp=sharing",
        demo: "https://youtu.be/yDVTHL9AuhE",
        isNew: true,
        isEnroll: false,
        isPurchase: true,
        curriculum: () => (
          <div
            className="col-md-6 col-xs-12 rounded bg-dark text-white p-2 mt-3"
            style={{ border: "10px solid whitesmoke" }}>
            <h4 className="text-center">
              <b>React with Redux Course Curriculum</b>
            </h4>
            <div>
              <ul>
                <li>Week-1: Introduction to React</li>
                <br />
                <li>Week-2: Components and Styling, Material Design</li>
                <br />
                <li>Week-3: Navigation with Routes</li>
                <br />
                <li>Week-4: React Hooks</li>
                <br />
                <li>Week-5: Redux with React state management</li>
                <br />
                <li>Week-6: Testing and Deployment</li>
                <br />
                <li>
                  Week-7: One to One Mock Interview will be taken and interview preparation guidance will be given.
                </li>
              </ul>
              <Link to="/onlinetest/JavaScript%20Test/course"
                className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                <i className="fa fa-pencil" /> JavaScript Test - Prerequisite - Password: TestUser@123
              </Link>
              <a
                href="https://drive.google.com/file/d/1KiTRO99iq3nOPwLkj0EyWtBBAjxXULI1/view"
                target="_blank"
                className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                <i className="fa fa-file-pdf-o" /> VIEW DETAILED CURRICULUM
              </a>
              <div
                onClick={() => openPlayer({ _id: "60c7106e1527710009ff2ee4" }, history)}
                target="_blank"
                className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded cursor-pointer text-primary p-3">
                <i className="fa fa-youtube-play text-danger" /> Start Free Demo
              </div>
            </div>
          </div>
        )
      },
      coursefeatures: {
        videos: { count: "38", duration: "41+" },
        quizzes: "24",
        tasks: "24",
        certificateRole: "React Frontend Developer",
      },
      relatedCourses: [
        { ...allAvailableCourses.reactnative },
        { ...allAvailableCourses.nodejs },
        { ...allAvailableCourses.mernstack },
      ],
      requirements: {
        hardwarerequirements: {
          memory: "2/4 GB",
          processor: "i3",
          storage: "20 GB",
        },
        softwarerequirements: {
          os: "Any OS (Windows 7 or later/Linux/Mac)",
          ide: "Visual Studio Code (for web app development)",
          browser: "Chrome/Mozilla Firefox (latest version)",
        },
        certificateImage: "certificate.png",
      }
    },
    {
      coursepath: "react-summer-2024",
      courseOffer: {
        amount: "4999",
        original: 10000,
        enrollamount: "4999",
        enrolloriginal: "10000",
        discount,
        expireDate: monthEndDate,
      },
      header: {
        title: "REACT SUMMER INTERNSHIP AND TRAINING",
        theme: "warning",
        courseimage: "/react/react-training-summer.png",
        syllabus: "https://drive.google.com/file/d/1LNtHmytA2zNHW9itHJOuMmTi8EzWSmRs/view?usp=sharing",
        demo: "https://drive.google.com/file/d/1DQmTxnYOu38lQw5ImiLAX1izdsa9ipGi/view?usp=sharing",
        isNew: true,
        isEnroll: true,
        isPurchase: false,
        curriculum: () => (
          <div
            className="col-md-6 col-xs-12 rounded bg-dark text-white p-2 mt-3"
            style={{ border: "10px solid whitesmoke" }}>
            <h4 className="text-center">
              <b>React Summer Internship and Training Curriculum</b>
            </h4>
            <div>
              <ul>
                <li>Week-1: React Fundamentals, Core Concepts</li>
                <br />
                <li>Week-2: React Forms and Routing</li>
                <br />
                <li>Week-3: React Hooks and API calls</li>
                <br />
                <li>Week-4: React Practical Projects</li>
                <br />
                <li>Week-5: Internship On React for 1 month</li>
                <br />
              </ul>
              <Link to="/onlinetest/JavaScript%20Test/course"
                className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                <i className="fa fa-pencil" /> JavaScript Test - Prerequisite - Password: TestUser@123
              </Link>
              <a
                href="https://drive.google.com/file/d/1DQmTxnYOu38lQw5ImiLAX1izdsa9ipGi/view?usp=sharing"
                target="_blank"
                className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                <i className="fa fa-book" /> VIEW Syllabus Overview
              </a>
              <a
                href="https://drive.google.com/file/d/1LNtHmytA2zNHW9itHJOuMmTi8EzWSmRs/view?usp=sharing"
                target="_blank"
                className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                <i className="fa fa-file-pdf-o" /> VIEW Detailed Syllabus
              </a>
            </div>
          </div>
        )
      },
      coursefeatures: {
        videos: { count: "38", duration: "41+" },
        quizzes: "24",
        tasks: "24",
        certificateRole: "React Frontend Developer",
      },
      relatedCourses: [
        { ...allAvailableCourses.reactnative },
        { ...allAvailableCourses.nodejs },
        { ...allAvailableCourses.mernstack },
      ],
      requirements: {
        hardwarerequirements: {
          memory: "2/4 GB",
          processor: "i3",
          storage: "20 GB",
        },
        softwarerequirements: {
          os: "Any OS (Windows 7 or later/Linux/Mac)",
          ide: "Visual Studio Code (for web app development)",
          browser: "Chrome/Mozilla Firefox (latest version)",
        },
        certificateImage: "certificate.png",
      }
    },
    {
      coursepath: "react-2024",
      courseOffer: {
        amount: "9999",
        original: 24999,
        enrollamount: "9999",
        enrolloriginal: "24999",
        discount:trainingDiscount,
        expireDate: reactExpireDate,
      },
      header: {
        title: "REACT ONLINE TRAINING",
        theme: "warning",
        courseimage: ["/react/react-training-next.png","/react/react-training-next-weekend.png"],
        syllabus: "https://drive.google.com/file/d/1LNtHmytA2zNHW9itHJOuMmTi8EzWSmRs/view?usp=sharing",
        demo: "https://drive.google.com/file/d/1DQmTxnYOu38lQw5ImiLAX1izdsa9ipGi/view?usp=sharing",
        isNew: true,
        isEnroll: true,
        isPurchase: false,
        curriculum: () => (
          <div
            className="col-md-6 col-xs-12 rounded bg-dark text-white p-2 mt-3"
            style={{ border: "10px solid whitesmoke" }}>
            <h4 className="text-center">
              <b>React Online Training Curriculum</b>
            </h4>
            <div>
              <ul className="list-group">
                <li>🔴Week-1: React Fundamentals, Core Concepts, Forms, Styles</li>
                <br />
                <li>🔴Week-2: React Event Handling, Routing, Hooks, API Calls, Deployment</li>
                <br />
                <li>🔴Week-3: E-Commerce Real-Time Project with React</li>
                <br />
                <li>
                🧑‍💻Week-4: Interview will be taken and 3 months Internship Opportunity can be provided to the top performers.
                </li>
              </ul>
              <a
                href="https://youtu.be/Q8Uhlxrdq3c"
                target="_blank"
                className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded cursor-pointer text-primary p-3">
                <i className="fa fa-youtube-play text-danger" /> Start Free Demo
              </a>
              <a
                href="https://drive.google.com/file/d/1LNtHmytA2zNHW9itHJOuMmTi8EzWSmRs/view?usp=sharing"
                target="_blank"
                className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                <i className="fa fa-file-pdf-o" /> View Curriculum
              </a>
              <a
                href={isRegularBatch?"https://drive.google.com/file/d/10LJg0TNXp-WzL8YBgOS0bNuuSHItBAMi/view?usp=drive_link":"https://drive.google.com/file/d/14z2ex5rmKxb6kcQm0Ptbp2AYgElz97EW/view?usp=sharing"}
                target="_blank"
                className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                <i className="fa fa-book" /> View Training Process
              </a>
              <Link to="/onlinetest/JavaScript%20Test/course"
                className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                <i className="fa fa-pencil" /> JavaScript Test - Prerequisite - Password: TestUser@123
              </Link>
            </div>
          </div>
        )
      },
      coursefeatures: {
        videos: { count: isRegularBatch?"9":"8", duration: "20" },
        quizzes: "8",
        tasks: "6",
        certificateRole: "React Frontend Developer",
      },
      relatedCourses: [
        { ...allAvailableCourses.reactnative },
        { ...allAvailableCourses.nodejs },
        { ...allAvailableCourses.mernstack },
      ],
      requirements: {
        hardwarerequirements: {
          memory: "2/4 GB",
          processor: "i3",
          storage: "20 GB",
        },
        softwarerequirements: {
          os: "Any OS (Windows 7 or later/Linux/Mac)",
          ide: "Visual Studio Code (for web app development)",
          browser: "Chrome/Mozilla Firefox (latest version)",
        },
        certificateImage: "certificate.png",
      }
    },
    {
      coursepath: "frontend-2024",
      courseOffer: {
        amount: "4999",
        original: 8000,
        enrollamount: "4999",
        enrolloriginal: "8000",
        discount,
        expireDate: monthEndDate,
      },
      header: {
        title: "FRONTEND ONLINE TRAINING",
        theme: "success",
        courseimage: "/react/frontend-training.png",
        syllabus: "https://drive.google.com/file/d/1zbG7Gu8PuodCHjD4mBJ7xW3SpvqHsmVU/view?usp=sharing",
        demo: "https://drive.google.com/file/d/1zbG7Gu8PuodCHjD4mBJ7xW3SpvqHsmVU/view?usp=sharing",
        isNew: true,
        isEnroll: true,
        isPurchase: false,
        curriculum: () => (
          <div
            className="col-md-6 col-xs-12 rounded bg-dark text-white p-2 mt-3"
            style={{ border: "10px solid whitesmoke" }}>
            <h4 className="text-center">
              <b>Frontend Online Course Curriculum</b>
            </h4>
            <div>
              <ul>
                <li>Week-1: HTML Fundamentals and Core Concepts</li>
                <br />
                <li>Week-2: CSS Fundamentals and Core Concepts</li>
                <br />
                <li>Week-3: JavaScript Fundamentals and Core Concepts</li>
                <br />
                <li>Week-4: HTML, CSS, JavaScript Practical Projects</li>
                <br />
                <li>
                  Week-5: Interview will be taken and 1 month Internship Opportunity can be provided to the top performers.
                </li>
              </ul>
              <a
                href="https://drive.google.com/file/d/1zbG7Gu8PuodCHjD4mBJ7xW3SpvqHsmVU/view?usp=sharing"
                target="_blank"
                className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                <i className="fa fa-book" /> VIEW Detailed Curriculum
              </a>
            </div>
          </div>
        )
      },
      coursefeatures: {
        videos: { count: "30+", duration: "40+" },
        quizzes: "28",
        tasks: "76",
        certificateRole: "Frontend Developer",
      },
      relatedCourses: [
        { ...allAvailableCourses.reactnative },
        { ...allAvailableCourses.nodejs },
        { ...allAvailableCourses.mernstack },
      ],
      requirements: {
        hardwarerequirements: {
          memory: "2/4 GB",
          processor: "i3",
          storage: "20 GB",
        },
        softwarerequirements: {
          os: "Any OS (Windows 7 or later/Linux/Mac)",
          ide: "Visual Studio Code (for web app development)",
          browser: "Chrome/Mozilla Firefox (latest version)",
        },
        certificateImage: "html5-certificate.png",
      }
    },
    {
      coursepath: "angular",
      courseOffer: {
        amount: "2250",
        original: 3000,
        enrollamount: "12750",
        enrolloriginal: "17000",
        discount,
        expireDate: monthEndDate,
      },
      header: {
        title: "ANGULAR",
        theme: "danger",
        courseimage: "angularfull.png",
        syllabus: "https://drive.google.com/file/d/1O209fypYmTXJQxIiwoTXxtSrmCBwq5iq/view",
        demo: "https://youtu.be/j0tzN7US-oU",
        isNew: true,
        isEnroll: false,
        isPurchase: true,
        curriculum: () => (
          <div
            className="col-md-6 col-xs-12 rounded bg-dark text-white p-2 mt-3"
            style={{ border: "10px solid whitesmoke" }}>
            <h4 className="text-center">
              <b>Angular Complete Course Curriculum</b>
            </h4>
            <div>
              <ul>
                <li>Week-1: Introduction to Angular, Fundamentals</li>
                <br />
                <li>Week-2: Angular Router, Navigation, Components, Services</li>
                <br />
                <li>Week-3: Angular Forms, Material, Bootstrap, Storybook</li>
                <br />
                <li>Week-4: Lifecycle hooks, PWA, Deployment</li>
                <br />
              </ul>
              <Link to="/onlinetest/JavaScript%20Test/course"
                className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                <i className="fa fa-pencil" /> JavaScript Test - Prerequisite - Password: TestUser@123
              </Link>
              <a
                href="https://drive.google.com/file/d/1O209fypYmTXJQxIiwoTXxtSrmCBwq5iq/view"
                target="_blank"
                className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                <i className="fa fa-file-pdf-o" /> VIEW DETAILED CURRICULUM
              </a>
              <div
                onClick={() => openPlayer({ _id: "63b9c079da88f700085e58b7" }, history)}
                target="_blank"
                className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded cursor-pointer text-primary p-3">
                <i className="fa fa-youtube-play text-danger" /> Start Free Demo
              </div>
            </div>
          </div>
        )
      },
      coursefeatures: {
        videos: { count: "10", duration: "10+" },
        quizzes: "10",
        tasks: "20+",
        certificateRole: "Angular Frontend Developer",
      },
      relatedCourses: [
        { ...allAvailableCourses.javascript },
        { ...allAvailableCourses.reactredux },
        { ...allAvailableCourses.reactnative },
      ],
      requirements: {
        hardwarerequirements: {
          memory: "2/4 GB",
          processor: "i3",
          storage: "20 GB",
        },
        softwarerequirements: {
          os: "Any OS (Windows 7 or later/Linux/Mac)",
          ide: "Visual Studio Code (for web app development)",
          browser: "Chrome/Mozilla Firefox (latest version)",
        },
        certificateImage: "angular-course-certificate.png",
      }
    },
    {
      coursepath: "nodejs",
      courseOffer: {
        amount: "1875",
        original: 2500,
        enrollamount: "8000",
        enrolloriginal: "15000",
        discount,
        expireDate: monthEndDate,
      },
      header: {
        title: "NodeJS",
        theme: "success",
        courseimage: "nodejscoursefull.png",
        syllabus: "https://drive.google.com/file/d/1DiwHpHVQsZGkYV8gW-4VpfLtXyIY_2IW/view?usp=sharing",
        demo: "https://www.youtube.com/watch?v=cca_x6M51zw",
        isNew: true,
        isEnroll: false,
        isPurchase: true,
        curriculum: () => (
          <div
            className="col-md-6 col-xs-12 rounded bg-dark text-white p-2 mt-3"
            style={{ border: "10px solid whitesmoke" }}>
            <h4 className="text-center">
              <b>NodeJS Course Curriculum</b>
            </h4>
            <div>
              <ul>
                <li>Week-1: Introduction, Environment Setup, Project Setup</li>
                <br />
                <br />
                <li>Week-2: Request, Response, Process, Callback, Promises</li>
                <br />
                <br />
                <li>Week-3: Databases - MySQL &amp; MongoDB Express, MVC, Postman</li>
                <br />
                <br />
                <li>Week-4: Authentication, Template Engine, Files, GraphQL</li>
                <br />
              </ul>
              <br />
              <a
                href="https://drive.google.com/file/d/1DiwHpHVQsZGkYV8gW-4VpfLtXyIY_2IW/view?usp=sharing"
                target="_blank"
                className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                <i className="fa fa-file-pdf-o" /> VIEW DETAILED CURRICULUM
              </a>
              <div
                onClick={() => openPlayer({ _id: "60c7112096998f00090e89aa" }, history)}
                target="_blank"
                className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded cursor-pointer text-primary p-3">
                <i className="fa fa-youtube-play text-danger" /> Start Free Demo
              </div>
            </div>
          </div>
        )
      },
      coursefeatures: {
        videos: { count: "10", duration: "11+" },
        quizzes: "16",
        tasks: "16",
        certificateRole: "NodeJS Developer",
      },
      relatedCourses: [
        { ...allAvailableCourses.mernstack },
        { ...allAvailableCourses.mernstackproject },
        { ...allAvailableCourses.reactredux },
      ],
      requirements: {
        hardwarerequirements: {
          memory: "2/4 GB",
          processor: "i3",
          storage: "20 GB",
        },
        softwarerequirements: {
          os: "Any OS (Windows 7 or later/Linux/Mac)",
          ide: "Visual Studio Code (for web app development)",
          browser: "Chrome/Mozilla Firefox (latest version)",
        },
        certificateImage: "nodejs-certificate.png",
      }
    },
    {
      coursepath: "jsandmernstack",
      courseOffer: {
        amount: "5950",
        original: 8500,
        enrollamount: "8000",
        enrolloriginal: "15000",
        discount,
        expireDate: monthEndDate,
      },
      header: {
        title: "JavaScript & MERN STACK",
        theme: "primary",
        courseimage: "jsandmern.png",
        syllabus: "https://drive.google.com/file/d/1DiwHpHVQsZGkYV8gW-4VpfLtXyIY_2IW/view?usp=sharing",
        demo: "https://www.youtube.com/watch?v=cca_x6M51zw",
        isNew: true,
        isEnroll: false,
        isPurchase: true,
        curriculum: () => (
          <div
            className="col-md-6 col-xs-12 rounded bg-dark text-white p-2 mt-3"
            style={{ border: "10px solid whitesmoke" }}>
            <h4 className="text-center">
              <b>JavaScript Course Curriculum</b>
            </h4>
            <div>
              <ul>
                <li>Week-1: Introduction, Developer Tools , JavaScript Basics</li>
                <li>Week-2: JavaScript Fundamentals, Interacting with Users</li>
                <li>Week-3: Arrays, Objects, JSON</li>
                <li>Week-4: Functions, Generators, Events Handling and Web Storage</li>
                <li>Week-5: DOM, Nodes, Dates, Built-in methods, jQuery Basics</li>
                <li>Week-6: ES6 Features in JavaScript, Object Oriented Programming</li>
              </ul>
              <div className="d-flex flex-wrap">
                <a
                  href="https://drive.google.com/file/d/1lUjojj-VQzwsAqaf54ms-5tRKK5T6c6c/view?usp=sharing"
                  target="_blank"
                  className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                  <i className="fa fa-file-pdf-o" /> VIEW DETAILED CURRICULUM
                </a>
                <div
                  onClick={() => openPlayer({ _id: "60c7110a1527710009ff2ee6" }, history)}
                  target="_blank"
                  className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded user-dropdown1 cursor-pointer text-primary p-3">
                  <i className="fa fa-youtube-play text-danger" /> Start Free Demo
                </div>
              </div>
            </div>
            <h4 className="text-center">
              <b>React with Redux Course Curriculum</b>
            </h4>
            <div>
              <ul>
                <li>Week-1: Introduction to React</li>
                <li>Week-2: Components and Styling, Material Design</li>
                <li>Week-3: Navigation with Routes</li>
                <li>Week-4: React Hooks</li>
                <li>Week-5: Redux with React state management</li>
                <li>Week-6: Testing and Deployment</li>
              </ul>
              <div className="d-flex flex-wrap">
                <a
                  href="https://drive.google.com/file/d/1KiTRO99iq3nOPwLkj0EyWtBBAjxXULI1/view"
                  target="_blank"
                  className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                  <i className="fa fa-file-pdf-o" /> VIEW DETAILED CURRICULUM
                </a>
                <div
                  onClick={() => openPlayer({ _id: "60c7106e1527710009ff2ee4" }, history)}
                  target="_blank"
                  className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded user-dropdown1 cursor-pointer text-primary p-3">
                  <i className="fa fa-youtube-play text-danger" /> Start Free Demo
                </div>
              </div>
            </div>
            <h4 className="text-center">
              <b>NodeJS Course Curriculum</b>
            </h4>
            <div>
              <ul>
                <li>Week-1: Introduction, Environment Setup, Project Setup</li>
                <li>Week-2: Request, Response, Process, Callback, Promises</li>
                <li>Week-3: Databases - MySQL &amp; MongoDB Express, MVC, Postman</li>
                <li>Week-4: Authentication, Template Engine, Files, GraphQL</li>
              </ul>
              <div className="d-flex flex-wrap">
                <a
                  href="https://drive.google.com/file/d/1DiwHpHVQsZGkYV8gW-4VpfLtXyIY_2IW/view?usp=sharing"
                  target="_blank"
                  className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                  <i className="fa fa-file-pdf-o" /> VIEW DETAILED CURRICULUM
                </a>
                <div
                  onClick={() => openPlayer({ _id: "60c7112096998f00090e89aa" }, history)}
                  target="_blank"
                  className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded user-dropdown1 cursor-pointer text-primary p-3">
                  <i className="fa fa-youtube-play text-danger" /> Start Free Demo
                </div>
              </div>
            </div>
            <h4 className="text-center">
              <b>MERN Stack Application</b>
            </h4>
            <div>
              <ul>
                <li>Part-1: Frontend with React, Redux, Bootstrap 5</li>
                <li>Part-2: Backend with NodeJS, Express, MySQL</li>
                <li>Part-3: Backend with NodeJS, Express, MongoDB</li>
              </ul>

              <div className="d-flex flex-wrap">
                <a
                  href="https://drive.google.com/file/d/15rU495RUPlaCP36JGnuQMUYYBGSHzFG6/view?usp=sharing"
                  target="_blank"
                  className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                  <i className="fa fa-file-pdf-o" /> VIEW DETAILED CURRICULUM
                </a>
                <a
                  href="https://youtu.be/t75DKzmr1cQ"
                  target="_blank"
                  className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded user-dropdown1 text-primary p-3">
                  <i className="fa fa-youtube-play text-danger" /> Start Free Demo
                </a>
              </div>
              <a
                href="https://drive.google.com/file/d/1OTHKkgZ_Dq41g2HxFhLn60cMrWq440hR/view?usp=sharing"
                target="_blank"
                className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded user-dropdown1 text-primary p-3">
                <i className="fa fa-file-pdf-o" /> VIEW FULL DETAILED CURRICULUM
              </a>
            </div>
          </div>
        )
      },
      coursefeatures: {
        videos: { count: "61", duration: "66+" },
        quizzes: "32",
        tasks: "64",
        certificateRole: "MERN Stack Developer",
      },
      relatedCourses: [
        { ...allAvailableCourses.reactredux },
        { ...allAvailableCourses.mernstackproject },
        { ...allAvailableCourses.html5 },
      ],
      requirements: {
        hardwarerequirements: {
          memory: "2/4 GB",
          processor: "i3",
          storage: "20 GB",
        },
        softwarerequirements: {
          os: "Any OS (Windows 7 or later/Linux/Mac)",
          ide: "Visual Studio Code (for web app development)",
          browser: "Chrome/Mozilla Firefox (latest version)",
        },
        certificateImage: "jsandmerncertificate.png",
      }
    },
    {
      coursepath: "jsandreactjs",
      courseOffer: {
        amount: "3500",
        original: 5000,
        enrollamount: "8000",
        enrolloriginal: "15000",
        discount,
        expireDate: monthEndDate,
      },
      header: {
        title: "JavaScript & React",
        theme: "primary",
        courseimage: "jsandreact.png",
        syllabus: "https://drive.google.com/file/d/1DiwHpHVQsZGkYV8gW-4VpfLtXyIY_2IW/view?usp=sharing",
        demo: "https://www.youtube.com/watch?v=cca_x6M51zw",
        isNew: true,
        isEnroll: false,
        isPurchase: true,
        curriculum: () => (
          <div
            className="col-md-6 col-xs-12 rounded bg-dark text-white p-2 mt-3"
            style={{ border: "10px solid whitesmoke" }}>
            <h4 className="text-center">
              <b>JavaScript Course Curriculum</b>
            </h4>
            <div>
              <ul>
                <li>Week-1: Introduction, Developer Tools , JavaScript Basics</li>
                <li>Week-2: JavaScript Fundamentals, Interacting with Users</li>
                <li>Week-3: Arrays, Objects, JSON</li>
                <li>Week-4: Functions, Generators, Events Handling and Web Storage</li>
                <li>Week-5: DOM, Nodes, Dates, Built-in methods, jQuery Basics</li>
                <li>Week-6: ES6 Features in JavaScript, Object Oriented Programming</li>
              </ul>
              <div className="d-flex flex-wrap">
                <a
                  href="https://drive.google.com/file/d/1lUjojj-VQzwsAqaf54ms-5tRKK5T6c6c/view?usp=sharing"
                  target="_blank"
                  className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                  <i className="fa fa-file-pdf-o" /> VIEW DETAILED CURRICULUM
                </a>
                <div
                  onClick={() => openPlayer({ _id: "60c7110a1527710009ff2ee6" }, history)}
                  target="_blank"
                  className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded user-dropdown1 cursor-pointer text-primary p-3">
                  <i className="fa fa-youtube-play text-danger" /> Start Free Demo
                </div>
              </div>
            </div>
            <br />
            <br />
            <h4 className="text-center">
              <b>React with Redux Course Curriculum</b>
            </h4>
            <div>
              <ul>
                <li>Week-1: Introduction to React</li>
                <li>Week-2: Components and Styling, Material Design</li>
                <li>Week-3: Navigation with Routes</li>
                <li>Week-4: React Hooks</li>
                <li>Week-5: Redux with React state management</li>
                <li>Week-6: Testing and Deployment</li>
              </ul>
              <div className="d-flex flex-wrap">
                <a
                  href="https://drive.google.com/file/d/1KiTRO99iq3nOPwLkj0EyWtBBAjxXULI1/view"
                  target="_blank"
                  className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                  <i className="fa fa-file-pdf-o" /> VIEW DETAILED CURRICULUM
                </a>
                <div
                  onClick={() => openPlayer({ _id: "60c7106e1527710009ff2ee4" }, history)}
                  target="_blank"
                  className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded user-dropdown1 cursor-pointer text-primary p-3">
                  <i className="fa fa-youtube-play text-danger" /> Start Free Demo
                </div>
              </div>
              <a
                href="https://drive.google.com/file/d/1rYDTMbubfzMz-KCZ0WNpx0oI9_PHcMaX/view?usp=sharing"
                target="_blank"
                className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded user-dropdown1 text-primary p-3">
                <i className="fa fa-file-pdf-o" /> VIEW FULL DETAILED CURRICULUM
              </a>
            </div>
          </div>
        )
      },
      coursefeatures: {
        videos: { count: "50", duration: "52+" },
        quizzes: "24",
        tasks: "48",
        certificateRole: "MERN Stack Developer",
      },
      relatedCourses: [
        { ...allAvailableCourses.javascriptsmart },
        { ...allAvailableCourses.jsandmern },
        { ...allAvailableCourses.mernstack },
      ],
      requirements: {
        hardwarerequirements: {
          memory: "2/4 GB",
          processor: "i3",
          storage: "20 GB",
        },
        softwarerequirements: {
          os: "Any OS (Windows 7 or later/Linux/Mac)",
          ide: "Visual Studio Code (for web app development)",
          browser: "Chrome/Mozilla Firefox (latest version)",
        },
        certificateImage: "jsandreactcertificate.png",
      }
    },
    {
      coursepath: "jsandnodejs",
      courseOffer: {
        amount: "3500",
        original: 5000,
        enrollamount: "8000",
        enrolloriginal: "15000",
        discount,
        expireDate: monthEndDate,
      },
      header: {
        title: "JavaScript & NodeJS",
        theme: "primary",
        courseimage: "jsandnode.png",
        syllabus: "https://drive.google.com/file/d/1DiwHpHVQsZGkYV8gW-4VpfLtXyIY_2IW/view?usp=sharing",
        demo: "https://www.youtube.com/watch?v=cca_x6M51zw",
        isNew: true,
        isEnroll: false,
        isPurchase: true,
        curriculum: () => (
          <div
            className="col-md-6 col-xs-12 rounded bg-dark text-white p-2 mt-3"
            style={{ border: "10px solid whitesmoke" }}>
            <h4 className="text-center">
              <b>JavaScript Course Curriculum</b>
            </h4>
            <div>
              <ul>
                <li>Week-1: Introduction, Developer Tools , JavaScript Basics</li>
                <li>Week-2: JavaScript Fundamentals, Interacting with Users</li>
                <li>Week-3: Arrays, Objects, JSON</li>
                <li>Week-4: Functions, Generators, Events Handling and Web Storage</li>
                <li>Week-5: DOM, Nodes, Dates, Built-in methods, jQuery Basics</li>
                <li>Week-6: ES6 Features in JavaScript, Object Oriented Programming</li>
              </ul>
              <div className="d-flex flex-wrap">
                <a
                  href="https://drive.google.com/file/d/1lUjojj-VQzwsAqaf54ms-5tRKK5T6c6c/view?usp=sharing"
                  target="_blank"
                  className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                  <i className="fa fa-file-pdf-o" /> VIEW DETAILED CURRICULUM
                </a>
                <div
                  onClick={() => openPlayer({ _id: "60c7110a1527710009ff2ee6" }, history)}
                  target="_blank"
                  className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded user-dropdown1 cursor-pointer text-primary p-3">
                  <i className="fa fa-youtube-play text-danger" /> Start Free Demo
                </div>
              </div>
            </div>
            <br />
            <br />
            <h4 className="text-center">
              <b>NodeJS Course Curriculum</b>
            </h4>
            <div>
              <ul>
                <li>Week-1: Introduction, Environment Setup, Project Setup</li>
                <li>Week-2: Request, Response, Process, Callback, Promises</li>
                <li>Week-3: Databases - MySQL &amp; MongoDB Express, MVC, Postman</li>
                <li>Week-4: Authentication, Template Engine, Files, GraphQL</li>
              </ul>
              <div className="d-flex flex-wrap">
                <a
                  href="https://drive.google.com/file/d/1DiwHpHVQsZGkYV8gW-4VpfLtXyIY_2IW/view?usp=sharing"
                  target="_blank"
                  className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                  <i className="fa fa-file-pdf-o" /> VIEW DETAILED CURRICULUM
                </a>
                <div
                  onClick={() => openPlayer({ _id: "60c7112096998f00090e89aa" }, history)}
                  target="_blank"
                  className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded user-dropdown1 cursor-pointer text-primary p-3">
                  <i className="fa fa-youtube-play text-danger" /> Start Free Demo
                </div>
              </div>
              <a
                href="https://drive.google.com/file/d/1ZDqX6eHFlwNUyGKFFrm2xI5qn4_giCTe/view?usp=sharing"
                target="_blank"
                className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded user-dropdown1 text-primary p-3">
                <i className="fa fa-file-pdf-o" /> VIEW FULL DETAILED CURRICULUM
              </a>
            </div>
          </div>
        )
      },
      coursefeatures: {
        videos: { count: "22", duration: "23+" },
        quizzes: "20",
        tasks: "40",
        certificateRole: "JavaScript Developer",
      },
      relatedCourses: [
        { ...allAvailableCourses.reactredux },
        { ...allAvailableCourses.webdeveloper },
        { ...allAvailableCourses.jsandreact },
      ],
      requirements: {
        hardwarerequirements: {
          memory: "2/4 GB",
          processor: "i3",
          storage: "20 GB",
        },
        softwarerequirements: {
          os: "Any OS (Windows 7 or later/Linux/Mac)",
          ide: "Visual Studio Code (for web app development)",
          browser: "Chrome/Mozilla Firefox (latest version)",
        },
        certificateImage: "jsandnodejscertificate.png",
      }
    },
    {
      coursepath: "javascriptsmart",
      courseOffer: {
        amount: "1875",
        original: 2500,
        enrollamount: "8000",
        enrolloriginal: "15000",
        discount,
        expireDate: monthEndDate,
      },
      header: {
        title: "JavaScript Smart",
        theme: "primary",
        courseimage: "javascriptsmartcourse.png",
        syllabus: "https://drive.google.com/file/d/1DiwHpHVQsZGkYV8gW-4VpfLtXyIY_2IW/view?usp=sharing",
        demo: "https://www.youtube.com/watch?v=cca_x6M51zw",
        isNew: true,
        isEnroll: false,
        isPurchase: true,
        curriculum: () => (
          <div
            className="col-md-6 col-xs-12 rounded bg-dark text-white p-2 mt-3"
            style={{ border: "10px solid whitesmoke" }}>
            <h4 className="text-center">
              <b>JavaScript Smart Course Curriculum</b>
            </h4>
            <div>
              <ul>
                <li>Week-1: Apps on JavaScript Basics</li>
                <br />
                <li>Week-2: Apps on JavaScript Fundamentals, Interacting with Users</li>
                <br />
                <li>Week-3: Apps on Arrays, Objects, JSON</li>
                <br />
                <li>Week-4: Apps on Functions, Generators, Events Handling and Web Storage</li>
                <br />
                <li>Week-5: Apps on DOM, Nodes, Dates, Built-in methods, jQuery Basics</li>
                <br />
                <li>Week-6: Apps on ES6 Features in JavaScript, Object Oriented Programming</li>
                <br />
              </ul>
              <Link to="/onlinetest/HTML%20Test/course"
                className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                <i className="fa fa-pencil" /> HTML Test - Prerequisite - Password: TestUser@123
              </Link>
              <Link to="/onlinetest/CSS%20Test/course"
                className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                <i className="fa fa-pencil" /> CSS Test - Prerequisite - Password: TestUser@123
              </Link>
              <a
                href="https://drive.google.com/file/d/1E_iaH2p2liUKuUQFMBeUHFUvKe-75P2m/view?usp=sharing"
                target="_blank"
                className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                <i className="fa fa-file-pdf-o" /> VIEW DETAILED CURRICULUM
              </a>
              <div
                onClick={() => openPlayer({ _id: "61a37c812cb98e0009dbf547" }, history)}
                target="_blank"
                className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded cursor-pointer text-primary p-3">
                <i className="fa fa-youtube-play text-danger" /> Start Free Demo
              </div>
            </div>
          </div>
        )
      },
      coursefeatures: {
        videos: { count: "12", duration: "6+" },
        quizzes: "24",
        tasks: "24",
        certificateRole: "JavaScript Developer",
      },
      relatedCourses: [
        { ...allAvailableCourses.javascript },
        { ...allAvailableCourses.css3 },
        { ...allAvailableCourses.reactredux },
      ],
      requirements: {
        hardwarerequirements: {
          memory: "2/4 GB",
          processor: "i3",
          storage: "20 GB",
        },
        softwarerequirements: {
          os: "Any OS (Windows 7 or later/Linux/Mac)",
          ide: "Visual Studio Code (for web app development)",
          browser: "Chrome/Mozilla Firefox (latest version)",
        },
        certificateImage: "javascript-certificate.png",
      }
    },
    {
      coursepath: "webdeveloper",
      courseOffer: {
        amount: "6440",
        original: 9200,
        enrollamount: "20000",
        enrolloriginal: "20000",
        discount,
        expireDate: monthEndDate,
      },
      header: {
        title: "Web Development",
        theme: "primary",
        courseimage: "webdeveloper.png",
        syllabus: "https://drive.google.com/file/d/1DiwHpHVQsZGkYV8gW-4VpfLtXyIY_2IW/view?usp=sharing",
        demo: "https://www.youtube.com/watch?v=cca_x6M51zw",
        isNew: true,
        isEnroll: false,
        isPurchase: true,
        curriculum: () => (
          <div
            className="col-md-6 col-xs-12 rounded bg-dark text-white p-2 mt-3"
            style={{ border: "10px solid whitesmoke" }}>
            <h4 className="text-center">
              <b>HTML Course Curriculum</b>
            </h4>
            <div>
              <ul>
                <li>Week-1: Introduction, Environment Setup, Basics, HTML Fundamentals </li>
                <li>Week-2: Colors, CSS, Links, Images, Tables, Lists, Block &amp; Inline</li>
                <li>Week-3: Responsive Design, HTML vs XHTML, Forms</li>
                <li>Week-4: Multimedia, HTML APIs, Drag and Drop, Web Storage</li>
              </ul>
              <div className="d-flex flex-wrap">
                <a
                  href="https://drive.google.com/file/d/1O0ybXXM6DXQg_GU7rz-Ig_hpAgqHslSK/view?usp=sharing"
                  target="_blank"
                  className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                  <i className="fa fa-file-pdf-o" /> VIEW DETAILED CURRICULUM
                </a>
                <div
                  onClick={() => openPlayer({ _id: "60f39d92b51e6f00081bb9bf" }, history)}
                  target="_blank"
                  className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded cursor-pointer text-primary p-3">
                  <i className="fa fa-youtube-play text-danger" /> Start Free Demo
                </div>
              </div>
            </div>
            <h4 className="text-center">
              <b>CSS Course Curriculum</b>
            </h4>
            <div>
              <ul>
                <li>Week-1: Introduction to CSS, CSS Fundamentals</li>
                <li>Week-2: Responsive Design, Media Queries, CSS Flexbox</li>
                <li>Week-3: Gradients, Shadows, Animations, Transitions, Transforms</li>
                <li>Week-4: CSS Grid, Variables, Bootstrap, Full Project and CSS Best Practices</li>
              </ul>
              <div className="d-flex flex-wrap">
                <a
                  href="https://drive.google.com/file/d/1WNH4ipulz90usq1v3d-QCbpngNaYNa9b/view?usp=sharing"
                  target="_blank"
                  className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                  <i className="fa fa-file-pdf-o" /> VIEW DETAILED CURRICULUM
                </a>
                <div
                  onClick={() => openPlayer({ _id: "614771dbc8da4a0008fe845e" }, history)}
                  className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded cursor-pointer text-primary p-3">
                  <i className="fa fa-youtube-play text-danger" /> Start Free Demo
                </div>
              </div>
            </div>
            <h4 className="text-center">
              <b>JavaScript Course Curriculum</b>
            </h4>
            <div>
              <ul>
                <li>Week-1: Introduction, Developer Tools , JavaScript Basics</li>
                <li>Week-2: JavaScript Fundamentals, Interacting with Users</li>
                <li>Week-3: Arrays, Objects, JSON</li>
                <li>Week-4: Functions, Generators, Events Handling and Web Storage</li>
                <li>Week-5: DOM, Nodes, Dates, Built-in methods, jQuery Basics</li>
                <li>Week-6: ES6 Features in JavaScript, Object Oriented Programming</li>
              </ul>
              <div className="d-flex flex-wrap">
                <a
                  href="https://drive.google.com/file/d/1lUjojj-VQzwsAqaf54ms-5tRKK5T6c6c/view?usp=sharing"
                  target="_blank"
                  className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                  <i className="fa fa-file-pdf-o" /> VIEW DETAILED CURRICULUM
                </a>
                <div
                  onClick={() => openPlayer({ _id: "60c7110a1527710009ff2ee6" }, history)}
                  target="_blank"
                  className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded user-dropdown1 cursor-pointer text-primary p-3">
                  <i className="fa fa-youtube-play text-danger" /> Start Free Demo
                </div>
              </div>
            </div>

            <h4 className="text-center">
              <b>JavaScript Smart Course With Practical Projects</b>
            </h4>
            <ul>
              <li>10+ Practical Projects with HTML+CSS+JAVASCRIPT.</li>
            </ul>
            <a
              href="https://drive.google.com/file/d/1x1_fuM90qpq4F4ReK-t8iEE-wg36ZJNG/view?usp=sharing"
              target="_blank"
              className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
              <i className="fa fa-file-pdf-o" /> VIEW DETAILED CURRICULUM
            </a>
          </div>
        )
      },
      coursefeatures: {
        videos: { count: "42", duration: "46+" },
        quizzes: "28",
        tasks: "76",
        certificateRole: "Web Developer",
      },
      relatedCourses: [
        { ...allAvailableCourses.mernstack },
        { ...allAvailableCourses.reactnative },
        { ...allAvailableCourses.reactredux },
      ],
      requirements: {
        hardwarerequirements: {
          memory: "2/4 GB",
          processor: "i3",
          storage: "20 GB",
        },
        softwarerequirements: {
          os: "Any OS (Windows 7 or later/Linux/Mac)",
          ide: "Visual Studio Code (for web app development)",
          browser: "Chrome/Mozilla Firefox (latest version)",
        },
        certificateImage: "html5-certificate.png",
      }
    },
    {
      coursepath: "mernstack",
      courseOffer: {
        amount: "4200",
        original: 6000,
        enrollamount: "25000",
        enrolloriginal: "40000",
        discount,
        expireDate: monthEndDate,
      },
      header: {
        title: "MERN Stack Development",
        theme: "warning",
        courseimage: "mernstack.png",
        syllabus: "https://drive.google.com/file/d/1DiwHpHVQsZGkYV8gW-4VpfLtXyIY_2IW/view?usp=sharing",
        demo: "https://www.youtube.com/watch?v=cca_x6M51zw",
        isNew: true,
        isEnroll: false,
        isPurchase: true,
        curriculum: () => (
          <div
            className="col-md-6 col-xs-12 rounded bg-dark text-white p-2 mt-3"
            style={{ border: "10px solid whitesmoke" }}>
            <h4 className="text-center">
              <b>React with Redux Course Curriculum</b>
            </h4>
            <div>
              <ul>
                <li>Week-1: Introduction to React</li>
                <li>Week-2: Components and Styling, Material Design</li>
                <li>Week-3: Navigation with Routes</li>
                <li>Week-4: React Hooks</li>
                <li>Week-5: Redux with React state management</li>
                <li>Week-6: Testing and Deployment</li>
              </ul>
              <div className="d-flex flex-wrap">
                <a
                  href="https://drive.google.com/file/d/1KiTRO99iq3nOPwLkj0EyWtBBAjxXULI1/view"
                  target="_blank"
                  className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                  <i className="fa fa-file-pdf-o" /> VIEW DETAILED CURRICULUM
                </a>
                <div
                  onClick={() => openPlayer({ _id: "60c7106e1527710009ff2ee4" }, history)}
                  target="_blank"
                  className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded user-dropdown1 cursor-pointer text-primary p-3">
                  <i className="fa fa-youtube-play text-danger" /> Start Free Demo
                </div>
              </div>
            </div>
            <h4 className="text-center">
              <b>NodeJS Course Curriculum</b>
            </h4>
            <div>
              <ul>
                <li>Week-1: Introduction, Environment Setup, Project Setup</li>
                <li>Week-2: Request, Response, Process, Callback, Promises</li>
                <li>Week-3: Databases - MySQL &amp; MongoDB Express, MVC, Postman</li>
                <li>Week-4: Authentication, Template Engine, Files, GraphQL</li>
              </ul>

              <div className="d-flex flex-wrap">
                <a
                  href="https://drive.google.com/file/d/1DiwHpHVQsZGkYV8gW-4VpfLtXyIY_2IW/view?usp=sharing"
                  target="_blank"
                  className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                  <i className="fa fa-file-pdf-o" /> VIEW DETAILED CURRICULUM
                </a>
                <div
                  onClick={() => openPlayer({ _id: "60c7112096998f00090e89aa" }, history)}
                  target="_blank"
                  className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded user-dropdown1 cursor-pointer text-primary p-3">
                  <i className="fa fa-youtube-play text-danger" /> Start Free Demo
                </div>
              </div>
            </div>
            <h4 className="text-center">
              <b>MERN Stack Application</b>
            </h4>
            <div>
              <ul>
                <li>Part-1: Frontend with React, Redux, Bootstrap 5</li>
                <li>Part-2: Backend with NodeJS, Express, MySQL</li>
                <li>Part-3: Backend with NodeJS, Express, MongoDB</li>
              </ul>

              <div className="d-flex flex-wrap">
                <a
                  href="https://drive.google.com/file/d/15rU495RUPlaCP36JGnuQMUYYBGSHzFG6/view?usp=sharing"
                  target="_blank"
                  className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                  <i className="fa fa-file-pdf-o" /> VIEW DETAILED CURRICULUM
                </a>
                <a
                  href="https://youtu.be/t75DKzmr1cQ"
                  target="_blank"
                  className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded user-dropdown1 text-primary p-3">
                  <i className="fa fa-youtube-play text-danger" /> Start Free Demo
                </a>
              </div>
            </div>
          </div>
        )
      },
      coursefeatures: {
        videos: { count: "49", duration: "55+" },
        quizzes: "20",
        tasks: "40",
        certificateRole: "MERN Stack Developer",
      },
      relatedCourses: [
        { ...allAvailableCourses.webdeveloper },
        { ...allAvailableCourses.reactnative },
        { ...allAvailableCourses.jsandmern },
      ],
      requirements: {
        hardwarerequirements: {
          memory: "2/4 GB",
          processor: "i3",
          storage: "20 GB",
        },
        softwarerequirements: {
          os: "Any OS (Windows 7 or later/Linux/Mac)",
          ide: "Visual Studio Code (for web app development)",
          browser: "Chrome/Mozilla Firefox (latest version)",
        },
        certificateImage: "merncertificate.png",
      }
    },
    {
      coursepath: "meanstack",
      courseOffer: {
        amount: "3850",
        original: 5500,
        enrollamount: "25000",
        enrolloriginal: "40000",
        discount,
        expireDate: monthEndDate,
      },
      header: {
        title: "MEAN Stack Development",
        theme: "warning",
        courseimage: "meanstack.png",
        syllabus: "https://drive.google.com/file/d/1O209fypYmTXJQxIiwoTXxtSrmCBwq5iq/view",
        demo: "https://youtu.be/j0tzN7US-oU",
        isNew: true,
        isEnroll: false,
        isPurchase: true,
        curriculum: () => (
          <div
            className="col-md-6 col-xs-12 rounded bg-dark text-white p-2 mt-3"
            style={{ border: "10px solid whitesmoke" }}>
            <h4 className="text-center">
              <b>Angular Complete Course Curriculum</b>
            </h4>
            <div>
              <ul>
                <li>Week-1: Introduction to Angular, Fundamentals</li>
                <br />
                <li>Week-2: Angular Router, Navigation, Components, Services</li>
                <br />
                <li>Week-3: Angular Forms, Material, Bootstrap, Storybook</li>
                <br />
                <li>Week-4: Lifecycle hooks, PWA, Deployment</li>
                <br />
              </ul>
              <Link to="/onlinetest/JavaScript%20Test/course"
                className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                <i className="fa fa-pencil" /> JavaScript Test - Prerequisite - Password: TestUser@123
              </Link>
              <a
                href="https://drive.google.com/file/d/1O209fypYmTXJQxIiwoTXxtSrmCBwq5iq/view"
                target="_blank"
                className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                <i className="fa fa-file-pdf-o" /> VIEW DETAILED CURRICULUM
              </a>
              <div
                onClick={() => openPlayer({ _id: "63b9c079da88f700085e58b7" }, history)}
                target="_blank"
                className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded cursor-pointer text-primary p-3">
                <i className="fa fa-youtube-play text-danger" /> Start Free Demo
              </div>
            </div>
            <h4 className="text-center">
              <b>NodeJS Course Curriculum</b>
            </h4>
            <div>
              <ul>
                <li>Week-1: Introduction, Environment Setup, Project Setup</li>
                <li>Week-2: Request, Response, Process, Callback, Promises</li>
                <li>Week-3: Databases - MySQL &amp; MongoDB Express, MVC, Postman</li>
                <li>Week-4: Authentication, Template Engine, Files, GraphQL</li>
              </ul>

              <div className="d-flex flex-wrap">
                <a
                  href="https://drive.google.com/file/d/1DiwHpHVQsZGkYV8gW-4VpfLtXyIY_2IW/view?usp=sharing"
                  target="_blank"
                  className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                  <i className="fa fa-file-pdf-o" /> VIEW DETAILED CURRICULUM
                </a>
                <div
                  onClick={() => openPlayer({ _id: "60c7112096998f00090e89aa" }, history)}
                  target="_blank"
                  className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded user-dropdown1 cursor-pointer text-primary p-3">
                  <i className="fa fa-youtube-play text-danger" /> Start Free Demo
                </div>
              </div>
            </div>
          </div>
        )
      },
      coursefeatures: {
        videos: { count: "20", duration: "21+" },
        quizzes: "26",
        tasks: "36",
        certificateRole: "MEAN Stack Developer",
      },
      relatedCourses: [
        { ...allAvailableCourses.mernstack },
        { ...allAvailableCourses.reactnative },
        { ...allAvailableCourses.jsandmern },
      ],
      requirements: {
        hardwarerequirements: {
          memory: "2/4 GB",
          processor: "i3",
          storage: "20 GB",
        },
        softwarerequirements: {
          os: "Any OS (Windows 7 or later/Linux/Mac)",
          ide: "Visual Studio Code (for web app development)",
          browser: "Chrome/Mozilla Firefox (latest version)",
        },
        certificateImage: "meancertificate.png",
      }
    },
    {
      coursepath: "reactnative",
      courseOffer: {
        amount: "2100",
        original: 3000,
        enrollamount: "8000",
        enrolloriginal: "15000",
        discount,
        expireDate: monthEndDate,
      },
      header: {
        title: "React Native",
        theme: "warning",
        courseimage: "reactnativecourse.png",
        syllabus: "https://drive.google.com/file/d/12mCeL7PUoY4Nk6lbRkqa0jqYZU5OHjEn/view?usp=sharing",
        demo: "https://youtu.be/sWzNphyuRsk",
        isNew: true,
        isEnroll: false,
        isPurchase: true,
        curriculum: () => (
          <div
            className="col-md-6 col-xs-12 rounded bg-dark text-white p-2 mt-3"
            style={{ border: "10px solid whitesmoke" }}>
            <h4 className="text-center">
              <b>React Native Course Curriculum</b>
            </h4>
            <div>
              <ul>
                <li>Week-1: Introduction and Fundamentals, Basic Components</li>
                <br />
                <li>Week-2: UI, List Views, Modal, Animations, Other Components</li>
                <br />
                <li>Week-3: Android, iOS Components, Flexbox, Positions, Simple Apps</li>
                <br />
                <li>Week-4: Navigation, Fitness App , E-Commerce App, Chat App</li>
                <br />
                <li>Week-5: Firebase Auth &amp; Database, API Calls - Axios &amp; Fetch, Unit Testing</li>
                <br />
              </ul>

              <Link to="/onlinetest/JavaScript%20Test/course"
                className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                <i className="fa fa-pencil" /> JavaScript Test - Prerequisite - Password: TestUser@123
              </Link>
              <a
                href="https://drive.google.com/file/d/12mCeL7PUoY4Nk6lbRkqa0jqYZU5OHjEn/view?usp=sharing"
                target="_blank"
                rel="noreferrer"
                className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                <i className="fa fa-file-pdf-o" /> VIEW DETAILED CURRICULUM
              </a>
              <a
                href="https://youtu.be/sWzNphyuRsk"
                target="_blank"
                rel="noreferrer"
                className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                <i className="fa fa-youtube-play text-danger" /> Start Free Demo
              </a>
              <div
                onClick={() => openPlayer({ _id: "60c7112096998f00090e89aa" }, history)}
                target="_blank"
                rel="noreferrer"
                className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded cursor-pointer text-primary p-3">
                <i className="fa fa-youtube-play text-danger" /> WATCH INTRO LECTURE
              </div>
            </div>
          </div>
        )
      },
      coursefeatures: {
        videos: { count: "10", duration: "11+" },
        quizzes: "10",
        tasks: "25",
        certificateRole: "React Native Developer",
      },
      relatedCourses: [
        { ...allAvailableCourses.reactredux },
        { ...allAvailableCourses.javascript },
        { ...allAvailableCourses.mernstack },
      ],
      requirements: {
        hardwarerequirements: {
          memory: "2/4 GB",
          processor: "i3",
          storage: "20 GB",
        },
        softwarerequirements: {
          os: "Any OS (Windows 7 or later/Linux/Mac)",
          ide: "Visual Studio Code (for web app development)",
          browser: "Chrome/Mozilla Firefox (latest version)",
          android: true,
        },
        certificateImage: "reactnative-certificate.png",
      }
    },
    {
      coursepath: "reactandreactnative",
      courseOffer: {
        amount: "3850",
        original: 5500,
        enrollamount: "8000",
        enrolloriginal: "15000",
        discount,
        expireDate: monthEndDate,
      },
      header: {
        title: "React and React Native",
        theme: "warning",
        courseimage: "reactandreactnative.png",
        syllabus: "https://drive.google.com/file/d/1L6pouobo6JJPf_lDQB6uoX9MAR0Abv9t/view",
        demo: "https://www.youtube.com/watch?v=cca_x6M51zw",
        isNew: true,
        isEnroll: false,
        isPurchase: true,
        curriculum: () => (
          <div
            className="col-md-6 col-xs-12 rounded bg-dark text-white p-2 mt-3"
            style={{ border: "10px solid whitesmoke" }}>
            <h4 className="text-center">
              <b>React with Redux Course Curriculum</b>
            </h4>
            <div>
              <ul>
                <li>Week-1: Introduction to React</li>
                <li>Week-2: Components and Styling, Material Design</li>
                <li>Week-3: Navigation with Routes</li>
                <li>Week-4: React Hooks</li>
                <li>Week-5: Redux with React state management</li>
                <li>Week-6: Testing and Deployment</li>
              </ul>
              <div className="d-flex flex-wrap">
                <a
                  href="https://drive.google.com/file/d/1KiTRO99iq3nOPwLkj0EyWtBBAjxXULI1/view"
                  target="_blank"
                  className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                  <i className="fa fa-file-pdf-o" /> VIEW DETAILED CURRICULUM
                </a>
                <div
                  onClick={() => openPlayer({ _id: "60c7106e1527710009ff2ee4" }, history)}
                  target="_blank"
                  className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded user-dropdown1 cursor-pointer text-primary p-3">
                  <i className="fa fa-youtube-play text-danger" /> Start Free Demo
                </div>
              </div>
            </div>
            <br />
            <h4 className="text-center">
              <b>React Native Course Curriculum</b>
            </h4>
            <div>
              <ul>
                <li>Week-1: Introduction and Fundamentals, Basic Components</li>
                <br />
                <li>Week-2: UI, List Views, Modal, Animations, Other Components</li>
                <br />
                <li>Week-3: Android, iOS Components, Flexbox, Positions, Simple Apps</li>
                <br />
                <li>Week-4: Navigation, Fitness App , E-Commerce App, Chat App</li>
                <br />
                <li>Week-5: Firebase Auth &amp; Database, API Calls - Axios &amp; Fetch, Unit Testing</li>
                <br />
              </ul>
              <div className="d-flex flex-wrap">
                {/*<Link to="/onlinetest/JavaScript%20Test/course"
                className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                <i className="fa fa-pencil" /> JavaScript Test - Prerequisite - Password: TestUser@123
              </Link>*/}
                <a
                  href="https://drive.google.com/file/d/12mCeL7PUoY4Nk6lbRkqa0jqYZU5OHjEn/view?usp=sharing"
                  target="_blank"
                  rel="noreferrer"
                  className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded text-primary p-3">
                  <i className="fa fa-file-pdf-o" /> VIEW DETAILED CURRICULUM
                </a>
                <div
                  onClick={() => openPlayer({ _id: "617f1615f5e59900084ad475" }, history)}
                  target="_blank"
                  rel="noreferrer"
                  className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded cursor-pointer text-primary p-3">
                  <i className="fa fa-youtube-play text-danger" /> Start Free Demo
                </div>
                <a
                  href="https://drive.google.com/file/d/1L6pouobo6JJPf_lDQB6uoX9MAR0Abv9t/view"
                  target="_blank"
                  className="nav-link text-center font-weight-bold shadow bg-white m-4 rounded user-dropdown1 w-100">
                  <i className="fa fa-file-pdf-o" /> VIEW FULL DETAILED CURRICULUM
                </a>
              </div>
            </div>
          </div>
        )
      },
      coursefeatures: {
        videos: { count: "48", duration: "52+" },
        quizzes: "32",
        tasks: "49",
        certificateRole: "MERN Stack Developer",
      },
      relatedCourses: [
        { ...allAvailableCourses.javascriptsmart },
        { ...allAvailableCourses.jsandmern },
        { ...allAvailableCourses.mernstack },
      ],
      requirements: {
        hardwarerequirements: {
          memory: "2/4 GB",
          processor: "i3",
          storage: "20 GB",
        },
        softwarerequirements: {
          os: "Any OS (Windows 7 or later/Linux/Mac)",
          ide: "Visual Studio Code (for web app development)",
          browser: "Chrome/Mozilla Firefox (latest version)",
        },
        certificateImage: "jsandreactcertificate.png",
      }
    },
  ];
return courseData;
}
