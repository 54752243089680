import React, { useState } from 'react'
import { useRef } from 'react';
import { useSelector } from 'react-redux';

export default function SubReplyComponent({ singlereply, comment, addReply, subReply, setSubReply, addLike, deleteCommentReply }) {
  const [showReply, setShowReply] = useState(false);
  const subReplyRef = useRef();
  const { id: AuthUserId } = useSelector(store => store.user);
  const show = () => {
    setShowReply(!showReply);
    setSubReply("@" + singlereply.name.split(" ").join("+"))
    //setTimeout(() => {
    //  if (showReply === true) {
    //    subReplyRef.current.focus();
    //  }
    //}, 1000);
  }
  return (
    <div>
      <button className='btn btn-outline-primary me-2' onClick={() => addLike(singlereply._id, "likes", comment._id)}><i className='fa fa-thumbs-up' /> {singlereply.likes}</button>
      {/*<button className='btn btn-outline-primary me-2'><i className='fa fa-thumbs-down' /> {singlereply.dislikes}</button>*/}
      {singlereply.userId !== AuthUserId && <button className='btn btn-outline-dark me-2' onClick={show}><i className='fa fa-reply' /> Reply</button>}
      {singlereply.userId === AuthUserId && <button className='btn btn-outline-danger me-2' onClick={() => deleteCommentReply(singlereply._id, comment._id)}><i className='fa fa-trash' /> Delete</button>}

      {/* Reply Editor */}
      {showReply && <div className='mt-2 mb-2'>
        {/* custom text editor */}
        <form onSubmit={(e) => {
          addReply(e, comment._id, subReply);
          setShowReply(false);
        }
        }>
          <input type="text" placeholder='Add a reply...' className='form-control' value={subReply} onChange={(e) => setSubReply(e.target.value)} ref={subReplyRef} />
        </form>
      </div>}
    </div>
  )
}
