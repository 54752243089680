import moment from 'moment';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { allCoursesStored, api, baseURL, handleLink, logoutUser } from '../../../../utilities';
import { useCourseLinks } from '../../useCourses';
import ReplyComponent from './ReplyComponent';
import "./style.css";
import { isNewUser } from '../VideosList';

export default function VideoDetails({ setTimeFrame, location }) {
  const { lectureDetails, isEnrolled } = useSelector(store => store.course);
  const { token: AuthToken, id: AuthUserId, username: AuthUsername } = useSelector(store => store.user);
  const [comment, setComment] = useState("");
  const [reply, setReply] = useState("");
  const [subReply, setSubReply] = useState("");
  const [commentMessage, setCommentMessage] = useState("");
  const [replyMessage, setReplyMessage] = useState("");
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const { certificate, courselink, id, course } = useCourseLinks(location);
  const history = useHistory();
  const addComment = (e) => {
    e.preventDefault();
    if (comment.trim().length > 0) {
      if (AuthUserId != null && lectureDetails !== null) {
        setLoading(true);
        let body = { userId: AuthUserId, name: AuthUsername, videoId: lectureDetails.url, comment, replies: [] };
        api(baseURL + "/createComment", "POST", { "content-type": "application/json", Authorization: AuthToken }, body)
          .then((data) => {
            if (data.success) {
              setComments((prevComments) => {
                return [{ ...data.data, replies: [], name: AuthUsername }, ...prevComments]
              });
              setLoading(false);
              setCommentMessage(data.message);
              setTimeout(() => {
                setCommentMessage("")
              }, 1000);
              setComment("");
            } else {
              //logoutUser();
              setLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      } else {
        //window.location.href = "/login";
      }
    }
  }
  const addReply = (e, replyId, replyMessage) => {
    e.preventDefault();
    if (replyMessage.trim().length > 0) {
      if (AuthUserId != null && lectureDetails !== null) {
        setLoading(true);
        let body = { userId: AuthUserId, name: AuthUsername, videoId: lectureDetails.url, comment: replyMessage, replyForCommentId: replyId };
        api(baseURL + "/createComment", "POST", { "content-type": "application/json", Authorization: AuthToken }, body)
          .then((data) => {
            if (data.success) {
              let updatedComments = [...comments];
              let findIndex = comments.findIndex(item => item._id === replyId);
              updatedComments[findIndex].replies = [{ ...data.data, replies: [], name: AuthUsername }, ...updatedComments[findIndex].replies];
              setComments(updatedComments);
              setLoading(false);
              setReplyMessage(data.message);
              setTimeout(() => {
                setReplyMessage("")
              }, 1000);
              setReply("");
              setSubReply("");
            } else {
              //logoutUser();
              setLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      } else {
        //window.location.href = "/login";
      }
    }
  }
  const getCommentsByVideo = () => {
    if (lectureDetails != null) {
      setLoading(true);
      api(baseURL + "/getAllCommentsByVideoId?videoId=" + lectureDetails.url, "GET", { Authorization: AuthToken }, {})
        .then((response) => {
          if (response.success) {
            setComments(response.data.data);
            setLoading(false);
          } else {
            //logoutUser();
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } else {
      if (id !== "undefined") {
        //window.location.href = "/login";
      }
    }
  }

  const addLike = (commentId, likeType, replyId) => {
    if (AuthUserId != null && lectureDetails !== null) {
      setLoading(true);
      let body = { userId: AuthUserId, commentId, likeType };
      api(baseURL + "/createCommentLike", "POST", { "content-type": "application/json", Authorization: AuthToken }, body)
        .then((data) => {
          if (data.success) {
            let updatedComments = [...comments];
            let findIndex = comments.findIndex(item => item._id === commentId);
            if (likeType === "likes") {
              updatedComments[findIndex].likes = data.data.likes;
            } else {
              updatedComments[findIndex].dislikes = data.data.dislikes;
            }
            setComments(updatedComments);
            setLoading(false);
            //setCommentMessage(data.message);
            //setTimeout(() => {
            //  setCommentMessage("")
            //}, 1000);
          } else {
            //logoutUser();
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } else {
      //window.location.href = "/login";
    }
  }
  const addLikeReply = (commentId, likeType, replyId) => {
    if (AuthUserId != null && lectureDetails !== null) {
      setLoading(true);
      let body = { userId: AuthUserId, commentId, likeType };
      api(baseURL + "/createCommentLike", "POST", { "content-type": "application/json", Authorization: AuthToken }, body)
        .then((data) => {
          if (data.success) {
            let updatedComments = [...comments];
            let findIndex = comments.findIndex(item => item._id === replyId);
            let replies = updatedComments[findIndex].replies;
            let findReplyIndex = replies.findIndex(item => item._id === commentId);
            if (likeType === "likes") {
              replies[findReplyIndex].likes = data.data.likes;
            } else {
              replies[findReplyIndex].dislikes = data.data.dislikes;
            }
            updatedComments[findIndex].replies = replies;
            setComments(updatedComments);
            setLoading(false);
            //setCommentMessage(data.message);
            //setTimeout(() => {
            //  setCommentMessage("")
            //}, 1000);
          } else {
            //logoutUser();
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } else {
      //window.location.href = "/login";
    }
  }

  const deleteComment = (id) => {
    if (window.confirm("Are you sure to delete?")) {
      if (AuthUserId != null && lectureDetails !== null) {
        setLoading(true);
        api(baseURL + "/deleteComment/" + id, "DELETE", { Authorization: AuthToken }, {})
          .then((data) => {
            if (data.success) {
              let updatedComments = [...comments];
              let findIndex = comments.findIndex(item => item._id === id);
              updatedComments.splice(findIndex, 1);
              setComments(updatedComments);
              setLoading(false);
              //setCommentMessage(data.message);
              //setTimeout(() => {
              //  setCommentMessage("")
              //}, 1000);
            } else {
              //logoutUser();
              setLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      } else {
        //window.location.href = "/login";
      }
    }
  }

  const deleteCommentReply = (id, commentId) => {
    if (window.confirm("Are you sure to delete?")) {
      if (AuthUserId != null && lectureDetails !== null) {
        setLoading(true);
        api(baseURL + "/deleteComment/" + id, "DELETE", { Authorization: AuthToken }, {})
          .then((data) => {
            if (data.success) {
              let updatedComments = [...comments];
              let findIndex = comments.findIndex(item => item._id === commentId);
              let replies = updatedComments[findIndex].replies;
              let findReplyIndex = replies.findIndex(item => item._id === id);
              replies.splice(findReplyIndex, 1);
              updatedComments[findIndex].replies = replies;
              setComments(updatedComments);
              setLoading(false);
            } else {
              setLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      }
    }
  }
  const openLink = (url, target = "_blank") => {
    if (isEnrolled) {
      handleLink(url);
    } else {
      window.alert("Please enroll to access the full content!");
      history.push("/purchase/" + courselink);
    }
  }
  useEffect(() => {
    if (AuthUserId !== null) {
      getCommentsByVideo();
    }
  }, [lectureDetails])
  return (
    <div className='pt-2 pb-2 enrollOption'>
      <h5><b>{lectureDetails !== null ? lectureDetails.title : ""}</b></h5>
      <div className='d-flex justify-content-between align-items-start flex-wrap'>
        <div className='d-flex'>
          <img src={"https://vmtraining.netlify.app/logo192.png"} alt="instructor" className='instructor me-2' />
          <div className='me-2'>
            <small>Instructor</small>
            <p><b>Venkatesh Mogili</b></p>
          </div>
          {/*<div className='me-2'>
            <img src={"https://vmtraining.netlify.app/logo512.png"} alt="course icon" className='course-icon' />
            <small className='m-1'>React and Redux</small>
          </div>*/}
        </div>
        <div>
          <a className='nav-link p-0' href={`https://api.whatsapp.com/send?text=Hey, I'm learning ${allCoursesStored.find(item => item._id === course)?.name} course from VM Online Training! Try it once for your better career. Everything will be in our mother tongue Telugu only:-) https://vmtraining.netlify.app/${allCoursesStored.find(item => item._id === course)?.courselink}`} target="_blank">Share this course with your friends <i className='fa fa-share' /></a>
        </div>
      </div>
      {/*<p>Published 1 year ago</p>
      <p>Updated 2 years ago</p>*/}
      {lectureDetails !== null && <p className='bg-dark rounded text-white p-2'>{lectureDetails.description}
      </p>}

      <div className='bg-dark rounded p-2 mb-3'>
        <h5 id="resources" className='text-white'>Resources: </h5>
        {lectureDetails !== null && <div>

          {lectureDetails.quiz &&
            <button className='btn btn-light download-source me-2 mt-1 mb-1' onClick={() => openLink(lectureDetails !== null ? lectureDetails.quiz : null)}><i className='fa fa-pencil' /> Quiz</button>}
          {lectureDetails.presentation &&
            <button className='btn btn-light download-source me-2 mt-1 mb-1' onClick={() => openLink(lectureDetails !== null ? lectureDetails.presentation : null)}><i className='fa fa-file-o' /> Presentations</button>}
          {lectureDetails.sourcecode &&
            <button className='btn btn-light download-source me-2 mt-1 mb-1' onClick={() => openLink(lectureDetails !== null ? lectureDetails.sourcecode : null)}><i className='fa fa-github' /> Source Code</button>}
          {lectureDetails.tasks &&
            <button className='btn btn-light download-source me-2 mt-1 mb-1' onClick={() => openLink(lectureDetails !== null ? lectureDetails.tasks : null)}><i className='fa fa-laptop' /> Tasks</button>}
        </div>}
      </div>
      {certificate && certificate.length > 0 && <div className='bg-dark rounded p-2 mb-3'>
        <h5 id="resources" className='text-white'>Certificate: </h5>
        {isNewUser ? <p className='text-warning'>Once the course is completed, you will get the certificate!</p> :
          (lectureDetails !== null && <div>

            {lectureDetails.quiz && <div className="col-lg-3" onClick={() => openLink(certificate !== null ? certificate : null)}>
              <img src={certificate} alt="certificate" className='col-lg-12 col-md-12 col-sm-12 w-100' />
              <p>
                <button className='btn btn-light  download-source me-2 mt-1 mb-1 col-lg-12'><i className='fa fa-id-card' /> Download Certificate</button>
              </p>
            </div>}
          </div>)}
      </div>}
      <div className='bg-dark rounded p-2' id="comments">
        <h5 className='text-white'>Comments: ({comments.length})</h5>
        {isEnrolled ?
          <div className='bg-light p-2 rounded'>
            <div>
              {/* custom text editor */}
              <form onSubmit={addComment}>
                <input type="text" placeholder='Add a comment...' className='form-control' value={comment} onChange={(e) => setComment(e.target.value)} />
                {commentMessage && <div className='alert alert-success'>{commentMessage}</div>}
              </form>
            </div>
            <div className='mt-2 mb-2'>
              {comments.length > 0 && comments.map((comment, index) => (
                <div className='d-flex' key={index}>
                  <img src={"https://vmtraining.netlify.app/logo192.png"} alt="instructor" className='instructor2 me-2' />
                  <div className='w-100'>
                    <div className='d-flex align-items-center flex-wrap'>
                      <span>{comment.name} </span>
                      <span className='m-1'>
                        <small className='' title={moment(comment.updatedAt).format("DD/MM/YYYY hh:mm:ss A")}>{comment.createdAt <= comment.updatedAt ? moment(comment.createdAt).fromNow() : moment(comment.updatedAt).fromNow()}</small>
                        {comment.createdAt < comment.updatedAt ? <small>(edited)</small> : null}
                      </span>
                    </div>
                    <p>{comment.comment}</p>
                    <ReplyComponent
                      comment={comment}
                      replyMessage={replyMessage}
                      addReply={addReply}
                      reply={reply} setReply={setReply}
                      subReply={subReply}
                      setSubReply={setSubReply}
                      addLike={addLike}
                      addLikeReply={addLikeReply}
                      deleteComment={deleteComment}
                      deleteCommentReply={deleteCommentReply}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div> : <div>
            <Link to={"/purchase/" + courselink} className='text-decoration-none text-warning'>Please enroll to participate in comment section to get clear your doubts! <button className='btn btn-primary btn-sm'><i className='fa fa-shopping-cart' /> Buy Now </button></Link>
          </div>}
      </div>
    </div>
  )
}
