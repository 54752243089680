import { regularBatchText } from "../../utilities";

export default function SelectBatch({ selectedBatch, setSelectedBatch }) {
  return (
    <div>
      <select className='form-select p-3' value={selectedBatch} onChange={(e) => setSelectedBatch(e.target.value)}>
          <option value={regularBatchText}>😎{regularBatchText}</option>
          {/* <option value="Evening Batch -- 9 PM - 10 PM (M,W,F)" disabled>🌙Evening Batch -- 8 PM - 9 PM (M,W,F) - Not Available</option> */}
      </select>

      {/* <a href={`https://mail.google.com/mail/?view=cm&fs=1&to=mogilivenkatesh3@gmail.com&su=VM Online Training - [React Live Training]&body=Hello Venkatesh!%0D%0A%0D%0AI'm interested to join Regular batch of React Live Training. I want to know more information. Looking forward to get in touch with you.%0D%0A%0D%0AThank you.`} className='btn btn-primary p-3 mt-3 w-100' rel="noopener noreferrer" target="_blank" >📞Contact Admin for Regular Batch  -- 7 PM - 8:30 PM (M,W,F)</a> */}
    </div>
  )
}
