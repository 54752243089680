import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { allCoursesStatic, allCoursesStored, api, baseURL, Validator } from "../utilities";
import PleaseWait from "./PleaseWait";

export default function PurchasePopup({ course = "", onClose }) {
  const [show, setShow] = useState(false);
  const [fullname, setfullname] = useState("");
  const [email, setemail] = useState("");
  const [message, setMessage] = useState("");
  const [courseName, setCourseName] = useState("");
  const [whatsappType, setWhatsappType] = useState("");
  const [referralcode, setReferralCode] = useState("VM");
  const [refereename, setRefereename] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [originalPrice, setOriginalPrice] = useState(0);
  const [courselink, setCourseLink] = useState("");
  const [, forceUpdate] = useState(false);
  const validator = Validator();
  const whatsappLinkRef = useRef();
  const whatsappLinkRef2 = useRef();

  useEffect(
    () => {
      window.scrollTo(0, 0);
      let mobile = /iphone|ipod|android|blackberry|mini|windows\sce|palm/i.test(navigator.userAgent.toLowerCase());
      if (mobile) {
        setShow(true);
      } else {
        setShow(false);
      }
      let foundCourse = allCoursesStored.filter(item => item.name === course);
      if (foundCourse.length > 0) {
        setTotalPrice(foundCourse[0].price - ((foundCourse[0].price / 100) * foundCourse[0].discount));
        setDiscount(foundCourse[0].discount);
        setOriginalPrice(foundCourse[0].price);
      }
      let foundCourse2 = allCoursesStatic.filter(item => item.courselink === course);
      console.log(foundCourse2)
      if (foundCourse2.length > 0) {
        setTotalPrice(foundCourse2[0].price - ((foundCourse2[0].price / 100) * foundCourse2[0].discount));
        setDiscount(foundCourse2[0].discount);
        setOriginalPrice(foundCourse2[0].price);
        setCourseLink(foundCourse2[0].courselink);
      }
      if (course === "reactredux" || course === "React with Redux") {
        setCourseName("React with Redux Online Course");
      } else if (course === "javascript" || course === "JavaScript") {
        setCourseName("JavaScript Online Course");
      } else if (course === "nodejs" || course === "NodeJS") {
        setCourseName("NodeJS Online Course");
      } else if (course === "mernstack" || course === "MERN Stack") {
        setCourseName("MERN Stack Online Course");
      } else if (course === "meanstack" || course === "MEAN Stack") {
        setCourseName("MEAN Stack Online Course");
      } else if (course === "jsandmernstack" || course === "JS and MERN Stack") {
        setCourseName("JavaScript and MERN Stack Online Course");
      } else if (course === "jsandreact" || course === "JS and React") {
        setCourseName("JavaScript and React Online Course");
      } else if (course === "jsandreactjs" || course === "JS and React") {
        setCourseName("JavaScript and React Online Course");
      } else if (course === "jsandnodejs" || course === "JS and NodeJS") {
        setCourseName("JavaScript and NodeJS Online Course");
      } else if (course === "mernstackproject" || course === "MERN Stack Project") {
        setCourseName("MERN Stack Project");
        setCourseLink("projects");
      } else if (course === "html5" || course === "HTML") {
        setCourseName("HTML5 Complete Course");
      } else if (course === "css3" || course === "CSS") {
        setCourseName("CSS3 Complete Course");
      } else if (course === "webdeveloper" || course === "Web Development Course") {
        setCourseName("Web Developer Full Course");
      } else if (course === "reactnative" || course === "React Native") {
        setCourseName("React Native Complete Course");
      } else if (course === "javascriptsmart" || course === "JavaScript Smart Course") {
        setCourseName("JavaScript Smart Course");
      } else if (course === "reactandreactnative" || course === "React and React Native") {
        setCourseName("React and React Native Course");
      } else if (course === "angular" || course === "Angular") {
        setCourseName("Angular Complete Course");
      } else if (course === "allcourses") {
        setCourseName("All Current Courses");
        setTotalPrice(9999)
        setDiscount(60)
        setOriginalPrice(23700);
      } else if (course === "cart") {
        let cart = localStorage.getItem("cartItems") != null ? JSON.parse(localStorage.getItem("cartItems")) : [];
        let totalAmount = 0;
        cart.forEach((item, index) => {
          let finalDiscount = 0;
          if (cart.length >= 8) {
            finalDiscount = 35;
          } else if (cart.length > 1 && cart.reduce((prev, current) => Number(prev) + Number(current.price), 0)) {
            finalDiscount = 30;
          } else {
            finalDiscount = Number(item.discount);
          }
          let finalval = parseInt(item.price) - ((parseInt(item.price) / 100) * parseInt(finalDiscount));
          totalAmount += Number(finalval);
        })
        setTotalPrice(totalAmount)
        let courses = cart.map((item, index) => " " + (index + 1) + ") " + item.title + " - Rs." + (parseInt(item.price) - ((parseInt(item.price) / 100) * parseInt(item.discount))) + "/-");
        let originalPrice = cart.reduce((total, item) => total + parseInt(item.price), 0);
        let coursesString = courses.toString();
        setCourseName("Selected Courses : \n" + coursesString + ",\n Original Price: Rs." + originalPrice + "/-,\n Final Price : Rs." + totalAmount + "/-");
        setDiscount(50)
        setOriginalPrice(originalPrice)
      }
    },
    [show, course],
  );
  const submitLogin = (e) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      setLoading(true);
      let data = {
        email,
        fullname,
        discount: discount,
        courseName: courseName,
        courseDiscountPrice: totalPrice,
        courseOriginalPrice: originalPrice,
        queryType: whatsappType,
        status: "unread"
      };
      api(baseURL + "/createConfirmOrder", "POST", { "content-type": "application/json" }, data).then((res) => {
        if (res.success) {
          setLoading(false);
          if (show) {
            whatsappLinkRef.current.click();
            setLoading(false);
            onClose();
          } else {
            whatsappLinkRef2.current.click();
            setLoading(false);
            onClose();
          }
        }
      }).catch((err) => {
        setLoading(false);
      })
    } else {
      validator.current.showMessages();
      forceUpdate(true);
    }
  };
  return (
    <div className="p-2 container-fluid custom-bg2">
      <div className="text-white">
        <h5 className="text-center">
          <Link to={"/" + courselink} className="text-decoration-none text-white"><i className="fa fa-laptop text-warning" />{" "}
            <b className="text-warning">{courseName.includes("Selected Courses") ? "Your Selected Courses" : courseName} <span className="text-success">{totalPrice > 0 ? `Rs.${totalPrice.toLocaleString("en-IN")}/-` : ''}</span> <sub className="text-danger">(<del>Rs.{originalPrice.toLocaleString('en-IN')}/-</del>)</sub><br /><span className="text-white">({discount+"%"}% Discount Applied) </span></b></Link>
        </h5>
        {courseName === "All Current Courses" && <div className="rounded bg-white text-dark shadow p-1 mb-2 text-uppercase">
          <p>HTML, CSS, JAVASCRIPT, JavaScript Smart, React, NodeJS, React Native, MERN Stack Project, Angular, React TypeScript Courses.</p>
        </div>}
      </div>
      {whatsappType === "order" &&
        show && (
          <a
            className="text-success"
            href={
              "https://api.whatsapp.com/send?phone=+918328664336&text=Hello Venkatesh!, My name is " +
              fullname +
              ", my email address is " +
              email +
              ". I have purchased " +
              courseName +
              " for Rs." + totalPrice + "/- with " + discount + "% discount." +
              " My Referral Code: " + referralcode + discount +
              //, My Referee Name is: " + (refereename.length > 0 ? refereename : "VM Training") +
              " Please let me know the status of the order."
            }
            target="_blank"
            id="link1"
            hidden
            ref={whatsappLinkRef}>
            <i className="fa fa-whatsapp text-success" /> <b>Click here</b>
          </a>
        )}
      {whatsappType === "order" &&
        !show && (
          <a
            className="text-success"
            href={
              "https://api.whatsapp.com/send?phone=+918328664336&text=Hello Venkatesh!, My name is " +
              fullname +
              ", my email address is " +
              email +
              ". I have purchased " +
              courseName +
              " for Rs." + totalPrice.toLocaleString("INR") + "/- with " + discount + "% discount." +
              " My Referral Code: " + referralcode + discount +
              //, My Referee Name is: " + (refereename.length > 0 ? refereename : "VM Training") +
              " Please let me know the status of the order."
            }
            target="_blank"
            id="link2"
            hidden
            ref={whatsappLinkRef2}>
            <i className="fa fa-whatsapp text-success" /> <b>Click here</b>
          </a>
        )}
      {whatsappType === "query" &&
        show && (
          <a
            className="text-success"
            href={
              "https://api.whatsapp.com/send?phone=+918328664336&text=Hello Venkatesh!, My name is " +
              fullname +
              ", my email address is " +
              email +
              ". I have a query regarding " +
              courseName +
              "."
            }
            target="_blank"
            id="link1"
            hidden
            ref={whatsappLinkRef}>
            <i className="fa fa-whatsapp text-success" /> <b>Click here</b>
          </a>
        )}
      {whatsappType === "query" &&
        !show && (
          <a
            className="text-success"
            href={
              "https://api.whatsapp.com/send?phone=+918328664336&text=Hello Venkatesh!, My name is " +
              fullname +
              ", my email address is " +
              email +
              ". I have a query regarding " +
              courseName +
              "."
            }
            target="_blank"
            id="link2"
            hidden
            ref={whatsappLinkRef2}>
            <i className="fa fa-whatsapp text-success" /> <b>Click here</b>
          </a>
        )}
      <div className="d-flex justify-content-between align-items-center text-dark bg-white p-2 rounded mb-2">
        <h5 className="">Step-1: Payment</h5>
        <i className="fa fa-rupee" style={{ fontSize: 50, color: "orange" }} />
      </div>
      <div className="d-flex justify-content-center p-0 m-0 flex-wrap">
        <div className="col-md-4 col-xs-12 shadow bg-white text-white p-2 mt-0">
          <img src="/images/payment.png" className="w-100 rounded" alt="payment" />
          <br />
          <br />
          <p className="text-dark text-center text-capitalize">
            Please <b>share screenshot</b> once payment is done and <b>Fill The Form</b> and click <b>Confirm Order</b> below.
          </p>
          <br />
          <br />
        </div>
        <div className="col-md-4 col-xs-12 shadow bg-white text-white p-2 mt-0">
          <img src="/images/donate/gpay.jpg" className="w-100 rounded" style={{ height: "270px" }} alt="payment" />
          <p className="text-center text-success p-2 rounded">Google Pay</p>
        </div>
        <div className="col-md-4 col-xs-12 shadow bg-white text-white p-2 mt-0">
          <img src="/images/donate/phonepe.png" className="w-100 rounded" style={{ height: "270px" }} alt="payment" />
          <p className="text-center text-success p-2 rounded">PhonePe</p>
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center text-dark bg-white p-2 rounded mb-2 mt-2">
        <h5 className="">Step-2: Confirm Order</h5>
        <i className="fa fa-check-circle-o" style={{ fontSize: 50, color: "lightgreen" }} />
      </div>
      <div className="row d-flex justify-content-center p-0 m-0">
        <div className="col-md-12 col-xs-12 shadow bg-white border text-white p-2 m-lg-3 mt-0 text-center rounded">
          <form onSubmit={submitLogin}>
            <i className="fa fa-user" style={{ fontSize: 100, color: "lightgreen" }} />
            <input
              type="text"
              placeholder="Enter your full name"
              className="form-control"
              value={fullname}
              onChange={(e) => setfullname(e.target.value)}
              onFocus={() => setMessage("")}
            />
            {validator.current.message("Full Name ", fullname, "required")}
            <br />
            <input
              type="email"
              placeholder="Enter your email address"
              className="form-control"
              value={email}
              onChange={(e) => setemail(e.target.value)}
              onFocus={() => setMessage("")}
            />
            {validator.current.message("Email Address ", email, "required|email")}
            {/*<label htmlFor="referal">Discount Referral Code</label>
            <input
              type="text"
              placeholder="Enter your referral code"
              className="form-control"
              value={referralcode + discount}
              onChange={(e) => setReferralCode(e.target.value)}
              onFocus={() => setMessage("")}
              disabled
            />*/}
            {/*{validator.current.message("Referral Code ", referralcode, "required")}*/}
            {/*<label htmlFor="referal">Discount Referee Name  (Optional)</label>
            <input
              type="text"
              placeholder="Enter your referee name"
              className="form-control"
              value={refereename}
              onChange={(e) => setRefereename(e.target.value)}
              onFocus={() => setMessage("")}
            />*/}
            <span className="error-message">{message}</span>
            {!loading ? <div className="d-flex mt-4">
              <input type="submit" value="Confirm Order" className="btn btn-primary w-50" hidden id="submitBtn" />
              {!loading && (
                <input
                  type="button"
                  onClick={() => {
                    setWhatsappType("order");
                    setTimeout(() => {
                      document.getElementById("submitBtn").click();
                    }, 100);
                  }}
                  value="Confirm Order"
                  className="btn btn-primary w-50"
                />
              )}
              &nbsp;
              {!loading && (
                <input
                  type="button"
                  onClick={() => {
                    setWhatsappType("query");

                    setTimeout(() => {
                      document.getElementById("submitBtn").click();
                    }, 100);
                  }}
                  value="Have Queries?"
                  className="btn btn-outline-danger w-50"
                />
              )}
            </div> : <div className="mt-4"><PleaseWait content={"Please wait..."} /></div>}
          </form>
        </div>
      </div>
      <div className="text-white p-3">
        <h6 className="text-center text-capitalize">If you have any queries, You can contact us through <Link to="/whatsapp" className="text-decoration-none">whatsapp 8328664336.</Link></h6>
        {/*<p className="text-center">
          Note: It is only for paid course, If you want to enroll for training please contact us through the above
          whatsapp number.
        </p>*/}
      </div>
    </div >
  );
}
