import { createSlice } from "@reduxjs/toolkit";
import { AuthUser, userSliceName } from "../constants/courseConstants";
import { addToStorage } from "../../utilities/localStorage";

const initialState = {
  token: localStorage.getItem("token") !== null ? localStorage.getItem("token") : null,
  email: localStorage.getItem("email") !== null ? localStorage.getItem("email") : null,
  username: localStorage.getItem("username") !== null ? localStorage.getItem("username") : null,
  id: localStorage.getItem("id") !== null ? localStorage.getItem("id") : null,
  referralCode: localStorage.getItem("referralCode") !== null ? localStorage.getItem("referralCode") : null,
  paymentAmount: localStorage.getItem("paymentAmount") !== null ? localStorage.getItem("paymentAmount") : 0,
  rewardPoints: localStorage.getItem("rewardPoints") !== null ? localStorage.getItem("rewardPoints") : 0,
};

const userSlice = createSlice({
  name: userSliceName,
  initialState,
  reducers: {
    setToken: (state, action) => {
      addToStorage(AuthUser.token, action.payload);
      state.token = action.payload;
    },
    setEmail: (state, action) => {
      addToStorage(AuthUser.email, action.payload);
      state.email = action.payload;
    },
    setUserName: (state, action) => {
      addToStorage(AuthUser.username, action.payload);
      state.username = action.payload;
    },
    setUserId: (state, action) => {
      addToStorage(AuthUser.id, action.payload);
      state.id = action.payload;
    },
    setReferralCodeGlobal: (state, action) => {
      addToStorage(AuthUser.referralCode, action.payload);
      state.referralCode = action.payload;
    },
    setPaymentAmount: (state, action) => {
      addToStorage(AuthUser.paymentAmount, action.payload);
      state.paymentAmount = action.payload;
    },
    setRewardPointsGlobal: (state, action) => {
      addToStorage(AuthUser.rewardPoints, action.payload);
      state.rewardPoints = action.payload;
    }
  }
})

export const { setToken, setEmail, setUserName, setUserId, setReferralCodeGlobal, setPaymentAmount, setRewardPointsGlobal } = userSlice.actions;

export default userSlice.reducer;