import React, { useState } from 'react';
import { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { allCoursesStored, api, baseURL, LoadingIndicator } from '../../utilities';
import { addToStorage } from '../../utilities/localStorage';
import { AuthUser, cartItems } from '../../redux/constants/courseConstants';
import { useDispatch } from 'react-redux';
import { setCartCount, setCartItems } from '../../redux/reducers/courseSlice';
import { useSelector } from 'react-redux';
import { useGoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { deviceInfo, saveUserSession } from '../auth/Login';
import moment from 'moment';
import { setEmail, setPaymentAmount, setReferralCodeGlobal, setRewardPointsGlobal, setToken, setUserId, setUserName } from '../../redux/reducers/userSlice';
import { ReferAFriend } from '../VMVideoPlayer/Courses/CourseWish';
import AddToCartCelebration from '../courses/AddToCartCelebration';
const clientId = "59189352007-6b69hlcdl8egpk7us5v2cmhganvqunru.apps.googleusercontent.com";

function CartItemsWithGoogle() {
  const history = useHistory();
  const login = useGoogleLogin({
    onSuccess: tokenResponse => {
      fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
        headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
      })
        .then(res => res.json())
        .then(data => proceedToCheckoutOnline(data));
    }
  });
  const loginForReferral = useGoogleLogin({
    onSuccess: tokenResponse => {
      fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
        headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
      })
        .then(res => res.json())
        .then(data => onLoginSuccess(data, checkReferralCode));
    }
  });
  const [allCourses, setAllCourses] = useState(allCoursesStored ? allCoursesStored : []);
  const [loading, setLoading] = useState(false);
  const [referralLoading, setReferralLoading] = useState(false);
  const [showDiscountGif, setShowDiscountGif] = useState(true);
  const [show, setShow] = useState(false);
  const [text, setText] = useState('');
  const [error, setError] = useState("");
  const { cartItems: cartItemsData,referralCode:referralCodeShared=null } = useSelector((store) => store.course);
  const { token: AuthToken, email: AuthEmail, username: AuthUsername, id: AuthUserId } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const [selectedCourses, setCourses] = useState(cartItemsData || []);
  const [fullStackCourses, setFullStackCourses] = useState([]);
  const [individualCourses, setIndividualCourses] = useState([]);
  const [referralCode, setReferralCode] = useState("");
  const [referralDiscount, setReferralDiscount] = useState(0);
  const [rewardPoints, setRewardPoints] = useState(0);
  const [myreferralCode, setMyReferralCode] = useState('');

  const [data, setData] = useState({});

  // Helper function to get course names from IDs
  const [selectedCourseComboIds, setSelectedCourseComboIds] = useState([]);
  const [selectedCourseIds, setSelectedCourseIds] = useState([]);
  const [totalCourseIds, setTotalCourseIds] = useState([]);
  const [uniqueCourses, setUniqueCourses] = useState(0);
  const [uniqueCoursesInfo, setUniqueCoursesInfo] = useState([]);
  const [uniqueCourseIds, setUniqueCourseIds] = useState([]);

  const onLoginSuccess = (userData, callback) => {
    setLoading(true);
    let { appCodeName,
      appName,
      appVersion,
      platform,
      userAgent,
      OSName,
      DeviceType, } = deviceInfo();
    fetch("https://api64.ipify.org/?format=json")
      .then(res => res.json())
      .then(response => {
        let browserInfo = {
          appCodeName,
          appName,
          appVersion,
          platform,
          userAgent,
          OSName,
          DeviceType,
          ipAddress: response ? response.ip : "N/A",
          lastLoginTime: moment(new Date()).format("YYYY-MM-DD hh:mm:ss A")
        }
        let user = api(baseURL + "/logingoogle", "POST", { "content-type": "application/json" }, {
          name: userData.name,
          date: Date.now(),
          userId: "VM-" + userData.name,
          email: userData.email, devices: {
            browserInfo,
            ipAddress: response ? response.ip : "N/A", OSName, DeviceType, lastLoginTime: browserInfo.lastLoginTime
          }
        });
        user
          .then((data) => {
            if (data.success) {
              setLoading(false);
              saveUserSession(data);
              dispatch(setToken(data.token));
              dispatch(setEmail(data.data.email));
              dispatch(setUserName(data.data.name));
              dispatch(setUserId(data.data.id));
              callback(null, userData, data.token);
            } else {
              alert(data.message);
              setLoading(false);
            }
          }).catch((error) => {
            alert("Network Error!");
            setLoading(false);
          });
      });
  };

  const getCourseNames = (ids) =>
    ids
      .map((id) => {
        const isRepeated = totalCourseIds.filter(courseId => courseId === id).length > 1;
        const course = allCourses.find((c) => c._id === id);
        return course ? `${course.title} ${isRepeated ? ' (Shared)' : ''}` : null;
      })
      .filter(Boolean); // Remove null values

  const applyDiscount = (price, discount) => price - (price * discount) / 100;

  const calculateComboPrice = (ids, discount) => {
    const total = ids.reduce((sum, id) => {
      const course = allCourses.find((c) => c._id === id);
      return sum + (course?.price || 0);
    }, 0);

    return applyDiscount(total, discount);
  };

  function handleDeleteItem(_id) {
    const isComboCourse = selectedCourses.filter(course => course._id === _id)[0].courseCombo;
    let removeItems = [...selectedCourses];
    if (isComboCourse.length > 0) {
      const courseIds = isComboCourse;
      removeItems = selectedCourses.filter(item => !courseIds.includes(item._id));
    }
    removeItems = removeItems.filter(item => item._id !== _id);
    addToStorage(cartItems, JSON.stringify(removeItems));
    setCourses(removeItems);
    dispatch(setCartCount(removeItems.length));
    dispatch(setCartItems(removeItems));
    setShowDiscountGif(false);
  }

  const fetchCourses = () => {
    //setLoading(true);
    //api(baseURL + "/getAllCoursesPublic/", "GET", {}, {})
    //  .then((data) => {
    //    if (data.success) {
    //      setLoading(false);
    //      setAllCourses(data.data)
    //let updatedResult = data.data.filter(course => course.courseType !== "inactive").map(course => {
    //  let result = allCoursesStored.filter(item => item.id === course._id);
    //  return { ...course, title: course.name, image1: course.courseLogo, image2: result.length > 0 ? result[0].image2 : "", isNew: course.isNewCourse, courselink: result.length > 0 ? result[0].courselink : "", demolink: course.demo }
    //})
    let premium = allCourses.filter(course => course.courseType === "Premium");
    let existingIds = localStorage.getItem("cartItems") != null ? JSON.parse(localStorage.getItem("cartItems")) : [];
    if (existingIds !== null) {
      existingIds = existingIds.map(course => course._id);
      let resultt = [];
      premium.forEach(course => {
        if (existingIds.filter(item => course.courseCombo.includes(item)).length === 0 && existingIds.includes(course._id)) {
          resultt.push(course)
        }
      })
      setCourses(resultt);
    }
    setError("")
    //} else {
    //  setError("")
    //  setLoading(false);
    //}
    //}).catch(error => {
    //  setError(error.toString())
    //})
  }
  useEffect(() => {
    fetchCourses();
  }, []);

  useEffect(()=>{
    if(!referralCode && referralCodeShared){
      setReferralCode(referralCodeShared);
    }
  },[referralCodeShared])

  useEffect(() => {
    const cartItemsDatas = cartItemsData || [];
    if (cartItemsDatas.length > 0) {
      const coursesData = [...cartItemsDatas];
      const sortedCourses = coursesData.sort((a, b) => b.price - a.price);
      setCourses(sortedCourses);
      setFullStackCourses(sortedCourses.filter((course) => course.courseCombo.length > 0));
      setIndividualCourses(sortedCourses.filter((course) => course.courseCombo.length === 0));
    } else {
      setCourses([]);
      setFullStackCourses([]);
      setIndividualCourses([]);
    }
  }, [cartItemsData]);

  useEffect(() => {
    if (selectedCourses.length > 0) {
      setSelectedCourseComboIds(selectedCourses.map(course => course.courseCombo));
      setSelectedCourseIds(selectedCourses.filter(course => course.courseCombo.length === 0).map(course => course._id));
      const totalCourseIds = [...selectedCourseComboIds.flat(), ...selectedCourseIds];
      const uniqueCoursesInfo = allCourses.filter(course => totalCourseIds.includes(course._id));
      setUniqueCoursesInfo(uniqueCoursesInfo);
      setTotalCourseIds(totalCourseIds);
      setUniqueCourses(new Set(totalCourseIds).size);
      setUniqueCourseIds([...new Set(totalCourseIds)]);
      dispatch(setCartCount(new Set(totalCourseIds).size));
    } else {
      setSelectedCourseComboIds([]);
      setSelectedCourseIds([]);
      setUniqueCoursesInfo([]);
      setTotalCourseIds([]);
      setUniqueCourses(0);
      setUniqueCourseIds([]);
      dispatch(setCartCount(0));
    }
  }, [selectedCourses])


  const totalAmount = [...fullStackCourses, ...individualCourses].reduce((prev, current) => prev + current.price, 0);
  const totalDiscountAmount = [...fullStackCourses, ...individualCourses].reduce((prev, current) => {
    return prev + (current.courseCombo.length > 0 ? calculateComboPrice(current.courseCombo, current.discount) : applyDiscount(current.price, current.discount))
  }, 0);
  let totalDiscountedPrice = uniqueCourses === 10 ? 9999 : totalDiscountAmount;
  // totalDiscountedPrice = uniqueCourses < 10 && uniqueCourses % 2 === 0 ? totalAmount / 2 : totalDiscountedPrice;
  const totalSavingsPrice = totalAmount - totalDiscountedPrice;
  const totalDiscountPercentage = totalAmount > 0 ? Math.round(((totalAmount - totalDiscountedPrice) / totalAmount) * 100) : 0;
  const taxPercentage = 5;
  const taxPercentageValue = taxPercentage / 100;
  const taxPrice = Math.ceil(totalDiscountedPrice * taxPercentageValue);
  let totalDiscountedPriceWithTax = totalDiscountedPrice + taxPrice;
  totalDiscountedPriceWithTax = totalDiscountedPriceWithTax - referralDiscount;
  const maxDiscount = Math.floor(totalDiscountedPriceWithTax * 0.25);
  const rewardPointsDiscount = Math.min(rewardPoints, maxDiscount);
  totalDiscountedPriceWithTax = totalDiscountedPriceWithTax - rewardPointsDiscount;

  // Direct Payment
  const proceedToCheckout = () => {
    history.push("/purchase/cart", { selectedCourses });
  }

  // Online Payment
  const proceedToCheckoutOnline = (data) => {
    setLoading(true);
    if (!AuthToken && (data && !data.email)) {
      console.log('Step-1: Create user!');
      // anonymous user
      login();
    } else {
      console.log('Step-2: Login user!');
      // logged in user
      if (uniqueCourses > 0) {
        try {
          const coursesInfo = uniqueCoursesInfo;
          // let totalCoursesNames = getCourseNames(uniqueCourseIds).join(",") || [];
          const totalCoursesNames = 'VM Online Training Courses';
          if (AuthToken) {
            console.log('Step-3: Create Order - Loggedin!');
            let user = api(baseURL + "/createPayuOrder", "POST", { "content-type": "application/json" }, {
              amount: totalDiscountedPriceWithTax,
              firstname: AuthUsername,
              email: AuthEmail,
              txnid: `TID${Date.now()}`,
              productinfo: totalCoursesNames,
              userId: AuthUserId,
              coursesInfo
            });
            user
              .then((data) => {
                if (data.success) {
                  console.log('Step-4: Create Order Successful!', data);
                  setData(data.data.form);
                  setLoading(false);
                  dispatch(setPaymentAmount(totalDiscountedPriceWithTax));
                  dispatch(setRewardPointsGlobal(rewardPointsDiscount));
                  if (referralDiscount > 0) {
                    dispatch(setReferralCodeGlobal(referralCode));
                  }
                } else {
                  console.log('Step-4: Create Order Failed!', data);
                  alert(data.message);
                  setLoading(false);
                }
              })
              .catch((error) => {
                console.log('Step-4: Create Order Error!', error);
                alert("Error" + error);
                setLoading(false);
              });
          } else {
            console.log('Step-3: Fetch IP Address - Anonymous!');
            let { appCodeName,
              appName,
              appVersion,
              platform,
              userAgent,
              OSName,
              DeviceType, } = deviceInfo();
            fetch("https://api64.ipify.org/?format=json")
              .then(res => res.json())
              .then(response => {
                let browserInfo = {
                  appCodeName,
                  appName,
                  appVersion,
                  platform,
                  userAgent,
                  OSName,
                  DeviceType,
                  ipAddress: response ? response.ip : "N/A",
                  lastLoginTime: moment(new Date()).format("YYYY-MM-DD hh:mm:ss A")
                }
                console.log('Step-3.1: Create Order - Anonymous!');
                let user = api(baseURL + "/createPayuOrder", "POST", { "content-type": "application/json" }, {
                  amount: totalDiscountedPriceWithTax,
                  firstname: data.name,
                  name: data.name,
                  date: Date.now(),
                  email: data.email,
                  txnid: `TID${Date.now()}`,
                  productinfo: totalCoursesNames,
                  userId: '',
                  coursesInfo,
                  devices: {
                    browserInfo,
                    ipAddress: response ? response.ip : "N/A", OSName, DeviceType, lastLoginTime: browserInfo.lastLoginTime
                  }
                });
                user
                  .then((data) => {
                    if (data.success) {
                      console.log('Step-4: Create Order Successful!', data);
                      setData(data.data.form);
                      addToStorage(AuthUser.token, data.data.userData.token);
                      addToStorage(AuthUser.username, data.data.userData.name);
                      addToStorage(AuthUser.email, data.data.userData.email);
                      addToStorage(AuthUser.id, data.data.userData.id);
                      setLoading(false);
                      dispatch(setPaymentAmount(totalDiscountedPriceWithTax));
                      dispatch(setRewardPointsGlobal(rewardPointsDiscount));

                      if (referralDiscount > 0) {
                        dispatch(setReferralCodeGlobal(referralCode));
                      }
                    } else {
                      console.log('Step-4: Create Order Failed!', data);
                      alert(data.message);
                      setLoading(false);
                    }
                  })
                  .catch((error) => {
                    alert("Error" + error);
                    setLoading(false);
                  });
              });
          }
        } catch (error) {
          console.log('Step-4: Create Order Error!', error);
          console.log("Error", error);
          setLoading(false);
        }
      } else {
        console.log("No items in cart!");
        setLoading(false);
      }
    }
  }

  // apply referral code
  const checkReferralCode = (e = null, userData = null, userToken = null) => {
    const authUserToken = AuthToken || userToken;
    const authEmail = AuthEmail || (userData ? userData.email : null);
    if (authUserToken) {
      if (e) {
        e.preventDefault();
      }
      if (referralCode.trim().length === 6) {

        setReferralLoading(true);
        let email = authEmail;
        const coursePrice = totalDiscountedPrice;
        let user = api(baseURL + "/check-referral-code", "POST", { "content-type": "application/json", "Authorization": authUserToken }, {
          email,
          referralCode,
          coursePrice
        });
        user
          .then((data) => {
            if (data.success) {
              console.log('Step-1: Referral Code applied!', data);
              // alert(data.message);
              setReferralDiscount(data.data.discountApplied);
              setReferralLoading(false);
              if (data.data.discountApplied > 0) {
                setShow('https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Partying%20Face.png');
                setText("Referral Discount Applied!");
              }
            } else {
              console.log('Step-1: Error!', data);
              alert(data.message);
              setReferralLoading(false);
              setReferralDiscount(0);
            }
          })
          .catch((error) => {
            console.log('Step-4: Error!', error);
            alert("Error" + error);
            setReferralLoading(false);
          });
      } else {
        alert("Please enter valid referral code!");
        setReferralLoading(false);
      }
    } else {
      if (e) {
        e.preventDefault();
      }
      loginForReferral();
    }
  }

  // apply reward points
  const getRewardPoints = () => {
    if (AuthToken && uniqueCourses > 0) {
      let user = api(baseURL + "/getRewardPointsByUserId/" + AuthUserId, "GET", { "Authorization": AuthToken });
      user
        .then((data) => {
          if (data.success) {
            console.log('Step-1: Rewards Points!', data);
            setLoading(false);
            setRewardPoints(data.data.rewardPoints);
            setMyReferralCode(data.data.referralCode);
            if (data.data.rewardPoints > 0) {
              setShow('https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Dollar%20Banknote.png');
              setText("Cashback From Reward Points Applied");
            }
          } else {
            console.log('Step-1: Error!', data);
            alert(data.message);
            setLoading(false);
            setRewardPoints(0);
          }
        })
        .catch((error) => {
          console.log('Step-4: Error!', error);
          alert("Error" + error);
          setLoading(false);
          setRewardPoints(0);
        });
    }
  }

  useEffect(() => {
    if (data && typeof data === "string") {
      const formWrapper = document.getElementById("paymentData");
      formWrapper.innerHTML = data;
      const formElement = formWrapper.querySelector("form");
      if (formElement) {
        formElement.submit();
      }
    }
  }, [data]);

  useEffect(() => {
    if (uniqueCourses > 0) {
      if(showDiscountGif){
      setShow('https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Star-Struck.png');
      setText(totalDiscountPercentage+"% VM Discount Applied!");
      }
      setTimeout(() => {
        getRewardPoints();
      }, 3000);
    }
  }, [uniqueCourses,showDiscountGif])

  return (
    <>
      {show && <AddToCartCelebration show={show} setShow={setShow} text={text} />}
      {/* <InstallApp /> */}
      <div className="d-none" id="paymentData" dangerouslySetInnerHTML={{ __html: data }}></div>
      <div className="container padding-bottom-3x mb-1 pb-3 pt-3 mt-4 custom-bg rounded popup-animation">
        {selectedCourses.length > 0 && <div className='d-flex justify-content-between align-items-center'>
          <h1 className='text-white mt-4'><img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Smiling%20Face%20with%20Sunglasses.png" alt="Smiling Face with Sunglasses" width="50" height="50" />VM Cart</h1>
          <h1 className='text-white mt-4'>({uniqueCourses} {uniqueCourses > 1 ? "Courses" : "Course"})</h1>
        </div>}
        {error.length > 0 ? <h3 className="text-danger text-center">{error}</h3> : null}
        {loading ? <div className="vh-100 text-center"><LoadingIndicator size={"5"} /></div> :
          <div className="card">
            {selectedCourses.length === 0 &&
              <div className='text-center p-3'>
                <h1 className='text-warning'>
                  <div>
                    <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Face%20with%20Monocle.png" alt="Face with Monocle" width="100" height="100" />
                  </div>
                  Your Cart Is Empty!<br /></h1>
                <Link to="/allcourses" className='btn btn-primary shadowAnim p-3 w-50'>
                  <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Nerd%20Face.png" alt="Nerd Face" width="25" height="25" /> <i className='fa fa-cart-plus' /> Add Courses</Link>
              </div>}

            {fullStackCourses.length > 0 &&
              <div className='p-3'>
                <h3>Full Stack Courses</h3>
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th className="col-3">Course</th>
                      <th className="col-2">Price</th>
                      <th className="col-2"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {fullStackCourses.length > 0 && fullStackCourses.map((course, index) => (
                      <tr key={index}>

                        <td>
                          <Link to={"/" + course.courselink} className="text-decoration-none">
                            <h5>
                              <img src={course.courseLogo} className='course-thumbnail rounded' alt="course logo" /> {course.title}
                            </h5>
                            <small>Includes ({getCourseNames(course.courseCombo).length} courses):<br /> {getCourseNames(course.courseCombo).join(", ")}</small>
                          </Link>
                        </td>
                        <td>
                          <h5 className='mt-3'>
                            ₹{course.price.toLocaleString('en-IN')}/-
                          </h5>
                        </td>
                        <td>
                          <button className="btn btn-danger" aria-label="Delete" onClick={() => handleDeleteItem(course._id)}>
                            <i className="fa fa-trash-o" aria-hidden="true"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>}
            {individualCourses.length > 0 &&
              <div className='p-3'>
                <h3>Individual Courses</h3>
                <hr />
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th className="col-3">Course</th>
                      <th className="col-2">Price</th>
                      <th className="col-2"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {individualCourses.length > 0 && individualCourses.map((course, index) => {
                      const isInBundle = fullStackCourses.some((bundle) =>
                        bundle.courseCombo.includes(course._id)
                      );
                      if (isInBundle) return null;

                      return (
                        <tr key={index}>
                          <td>
                            <Link to={"/" + course.courselink} className="text-decoration-none">
                              <h5>
                                <img src={course.courseLogo} className='course-thumbnail rounded' alt="course logo" /> {course.title}
                              </h5>
                            </Link>
                          </td>
                          <td>
                            <h5 className='mt-3'>
                              ₹{course.price.toLocaleString('en-IN')}/-
                            </h5>
                          </td>
                          <td>
                            <button className="btn btn-danger" aria-label="Delete" onClick={() => handleDeleteItem(course._id)}>
                              <i className="fa fa-trash-o" aria-hidden="true"></i>
                            </button>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>}
            {uniqueCourses > 0 ?
              <CartSummary
                totalDiscountPercentage={totalDiscountPercentage}
                totalSavingsPrice={totalSavingsPrice}
                totalDiscountedPrice={totalDiscountedPrice}
                totalAmount={totalAmount}
                totalDiscountedPriceWithTax={totalDiscountedPriceWithTax}
                proceedToCheckout={proceedToCheckout}
                proceedToCheckoutOnline={proceedToCheckoutOnline}
                uniqueCourses={uniqueCourses}
                checkReferralCode={checkReferralCode}
                setReferralCode={setReferralCode}
                referralCode={referralCode}
                referralDiscount={referralDiscount}
                setReferralDiscount={setReferralDiscount}
                taxPrice={taxPrice}
                loading={referralLoading}
                rewardPoints={rewardPoints}
                rewardPointsDiscount={rewardPointsDiscount}
                myreferralCode={myreferralCode}
                AuthToken={AuthToken}
              /> :
              null}
          </div>}
      </div>
    </>
  );
}

const CartSummary = ({ totalDiscountPercentage = 0, totalSavingsPrice = 0, totalDiscountedPrice = 0, totalAmount = 0, totalDiscountedPriceWithTax = 0, proceedToCheckout, proceedToCheckoutOnline, uniqueCourses, checkReferralCode, setReferralCode, referralCode, referralDiscount = 0, taxPrice = 0, setReferralDiscount, loading, rewardPoints = 0, rewardPointsDiscount = 0, myreferralCode = '', AuthToken }) => {
  return (
    <div className="container p-4 shadow-lg rounded bg-white w-100">
      <div className='d-flex justify-content-between align-items-center'>
        <h2 className="h5 fw-bold text-dark">Cart Summary</h2>
        {AuthToken && <h2 className="h5 fw-bold text-dark"> Cashback: ₹{rewardPoints - rewardPointsDiscount}/-

          &nbsp;<i className='fa fa-info-circle text-success' title="Cashback = 25% of the course price from the available reward points." />
        </h2>}
      </div>

      <div className='alert alert-primary d-flex justify-content-between'>
        <div className=" d-flex flex-column">
          <p className="mb-1 fw-bold"><img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Star-Struck.png" alt="Star-Struck" width="25" height="25" /> {totalDiscountPercentage}% VM Discount Applied!</p>
        </div>
        <p className="mb-0">
          You save <strong>₹{totalSavingsPrice.toLocaleString("en-IN")}/-</strong>
        </p>
        {/* {uniqueCourses < 10 &&
          <Link to="/allcourses" className='btn btn-primary p-3'>
            <i className='fa fa-cart-plus' /> Add More Courses</Link>} */}
      </div>
      <hr />
      <table className='table table-hover text-right'>
        <tr>
          <td>
            <p>Subtotal</p>
          </td>
          <td>
            <div className="d-flex justify-content-end">
              <p className="text-dark">₹{totalDiscountedPrice.toLocaleString("en-IN")}/-</p>
              <p className="text-muted text-decoration-line-through">₹{totalAmount.toLocaleString("en-IN")}/-</p>
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <p>Payment Gateway Charges (Tax)</p>
          </td>

          <td className='d-flex justify-content-end'>
            <p className="text-dark">+₹{(taxPrice).toLocaleString("en-IN")}/-</p>
          </td>
        </tr>

        {rewardPointsDiscount > 0 && <tr>
          <td>
            <p className='fw-bold text-success'><img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Dollar%20Banknote.png" alt="Dollar Banknote" width="25" height="25" /> Cashback</p>
          </td>
          <td className='d-flex justify-content-end'>
            <p className="text-success fw-bold">-₹{(rewardPointsDiscount).toLocaleString("en-IN")}/-</p>
          </td>
        </tr>}
        {Boolean(referralDiscount) && <tr>
          <td>
            <p className='text-primary fw-bold'>
              <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Partying%20Face.png" alt="Partying Face" width="25" height="25" /> Referral Discount</p>
          </td>
          <td className='d-flex justify-content-end'>
            <p className="text-primary text-right fw-bold">-₹{(referralDiscount).toLocaleString("en-IN")}/-</p>
          </td>
        </tr>}
      </table>
      <hr />
      <div className="d-flex justify-content-between align-items-center">
        <div className='d-flex m-2'>
          <ReferAFriend textColor="primary" />
        </div>
        <p className="h4 fw-bold text-dark d-flex justify-content-end">₹{(totalDiscountedPriceWithTax).toLocaleString("en-IN")}/-</p>
      </div>
      <hr />

      <form onSubmit={checkReferralCode} className='d-flex mb-2'>
        <input type="text" placeholder='Enter your referral code' className='form-control me-1' required maxLength={6} minLength={6} disabled={referralCode.length === 6 && referralDiscount > 0} value={referralCode} onChange={(e) => {
          setReferralCode(e.target.value.toUpperCase());
          if (referralDiscount > 0) {
            setReferralDiscount(0);
          }
        }} />
        <button className='btn btn-dark me-1 w-25'>{loading ? "Checking..." : "Apply"}</button>
      </form>


      <div className="d-flex justify-content-between">
        {/* <button className="btn btn-primary w-100 m-1 shadowAnim" onClick={proceedToCheckout}>
          <strong><img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Nerd%20Face.png" alt="Nerd Face" width="25" height="25" /> Checkout</strong> <i className='fa fa-angle-double-right' /> <strong>₹{totalDiscountedPrice.toLocaleString("en-IN")}/-</strong>
        </button> */}
        {/* <button className="btn btn-warning w-50 m-1" onClick={proceedToCheckout}>
          PhonePe (Delayed Enrollment) – <strong>₹{totalDiscountedPrice.toLocaleString("en-IN")}/- (No Tax)</strong>
          <br />
          <span className="small text-dark"><img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Thinking%20Face.png" alt="Thinking Face" width="25" height="25" /> Enrollment may take 30 min – 1 hour</span>
        </button> */}

        <button className="btn btn-primary w-100 m-1 p-3 fw-bold fs-4" onClick={() => proceedToCheckoutOnline({})}>
          Pay Now – <strong>₹{totalDiscountedPriceWithTax.toLocaleString("en-IN")}/-</strong>
        </button>
      </div>
    </div>
  );
};

export default function CartItems() {
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <CartItemsWithGoogle />
    </GoogleOAuthProvider>
  )
}