import React, { useState, useEffect, useRef } from 'react';
import './QuizPopup.css';
import { useHistory } from 'react-router-dom';
import { api, baseURL, LoadingIndicator } from '../../utilities';
import { removeFromStorage } from '../../utilities/localStorage';
import { pollCloseTime, quizCloseTime, quizConfig, quizDuration, randomItem } from './constants';
import { useSelector } from 'react-redux';

const QuizPopup = ({ question, resetQuiz }) => {
  const [answers, setAnswers] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [result, setResult] = useState({});
  const [loading, setLoading] = useState(false);
  const timerRef = useRef(0);
  const timerPollRef = useRef(0);
  const timeoutRef = useRef(0);
  const autoSubmitRef = useRef(0);
  const history = useHistory();
  const { token: AuthToken, email: AuthEmail } = useSelector(store => store.user);
  const isAdmin = AuthToken && AuthEmail === quizConfig.adminEmail;
  const isRegular = window.location.pathname.includes('regular');

  const isQuizType = question.question_type === "quiz";
  const timeLeftPercentage = (timeLeft / quizDuration) * 100;
  const remainingTime = (360 * (1 - ((100 - timeLeftPercentage) / 100))).toFixed(2);
  const isNotAnswered = !answers.question1;
  const hasSubmitted = result.hasOwnProperty('score');
  const isDisabledSubmit = isNotAnswered;
  const isShowTimer = timeLeft > 0 && !hasSubmitted;
  const hasOptionsAndNotSubmitted = (question.options?.length > 0 && !hasSubmitted) || !isQuizType;
  const isTimeOut = isNotAnswered && timeLeft < 1;
  const appreciationText = randomItem(quizConfig.appreciations);
  const appreciationIcon = randomItem(quizConfig.clappings);
  const wrongAnswerIcon = randomItem(quizConfig.wrongs);
  // TODO: wrongAnswerText - To be added in future.
  const submitText = isQuizType ? "Check Answer" : "Submit";
  const correctAnswer = result.score === 1;
  const wrongAnswer = result.score === 0;
  const pollPercentage = (ind) => {
    if (result.questionOpinions && result.questionOpinions.length > 0) {
      let quantity = result.questionOpinions[ind].quantity || 0;
      const answerPercentage = ((quantity / result.allAnswers.length) * 100).toFixed(0);
      const percentage = (answerPercentage * 27) / 100;
      return { width: percentage + "vw", quantity }
    } else {
      return { width: "0vw" }
    }
  }

  // Note: Initialization
  useEffect(() => {
    const quizCompleted = localStorage.getItem('quizCompleted');
    // TODO: Redux state required
    if (!quizCompleted) {
      setShowPopup(true);
      if (!isAdmin) {
        initializeTimer();
      }
    }
  }, []);

  const initializeTimer = () => {
    const storedStartTime = localStorage.getItem('quizStartTime');
    const now = Math.floor(Date.now() / 1000);

    if (storedStartTime) {
      const elapsed = now - parseInt(storedStartTime, 10);
      if (elapsed < quizDuration) {
        setTimeLeft(quizDuration - elapsed);
      } else {
        localStorage.removeItem('quizStartTime'); // Expired, clear storage
        setTimeLeft(0);
      }
    } else {
      localStorage.setItem('quizStartTime', now);
      setTimeLeft(quizDuration);
    }
  };

  // Note: Timer
  useEffect(() => {
    if (!showPopup || timeLeft <= 0) return;

    timerRef.current = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
      if (timeLeft === 0) {
        clearInterval(timerRef.current);
      }
    }, 1000);

    if (timeLeft === 1 && !hasSubmitted) {
      autoSubmitRef.current = setTimeout(() => {
        if (answers.question1) {
          // NOTE: Auto Submit when option selected and timeout.
          submitTest();
          clearInterval(timerRef.current);
        }
        localStorage.setItem("quizCompleted", true);
        // setShowPopup(false);
      }, 1000);
    }

    return () => {
      clearInterval(timerRef.current);
      clearTimeout(autoSubmitRef.current);
    }
  }, [showPopup, timeLeft]);


  // Note: Timer for refetching if poll.
  useEffect(() => {
    if ((!isQuizType && hasSubmitted) || isAdmin) {
      timerPollRef.current = setInterval(() => {
        refetchPollResults();
      }, 5000);
    }

    return () => clearInterval(timerPollRef.current);
  }, [isQuizType, hasSubmitted]);

  // Note: Handle Input Change
  const handleChange = (e) => {
    const { value, name } = e.target;
    const ans = { ...answers };
    ans[name] = value;
    setAnswers(ans);
    console.log(ans);
  }

  // Note: Submit Test
  const submitTest = () => {
    if (AuthToken) {
      setLoading(true);
      removeFromStorage("quizStartTime");
      api(baseURL + '/getResultsTraining', 'POST', { "content-type": "application/json", Authorization: AuthToken }, {
        questionsCategory: {
          quizName: "React Online Training",
          quizType: "course",
          questionId: question._id,
        },
        answers,
        testEmail: AuthEmail
      })
        .then(response => {
          if (response.success) {
            localStorage.setItem("quizCompleted", true);
            setResult(response.data);
            api(baseURL + '/createTestTraining', 'POST', { "content-type": "application/json", Authorization: AuthToken }, {
              quizName: "React Online Training",
              quizType: "course",
              answers,
              testEmail: AuthEmail,
              score: response.data.score,
              quizCategoryId: response.data.quizCategoryId,
              questionId: question._id,
            })
              .then(response2 => {
                if (response2.success) {
                  clearInterval(timerRef.current);
                  setResult(response.data);
                  refetchPollResults();
                  timeoutRef.current = setTimeout(() => {
                    closeQuizPopup();
                  }, isQuizType ? quizCloseTime : pollCloseTime);
                  if (response.data.score === 1) {
                    const audio = new Audio('/audio/short-crowd-cheer-6713.mp3');
                    audio.play();
                  }
                  setLoading(false);
                } else {
                  setLoading(false);
                  console.log("Something went wrong!");
                }
              })
              .catch(error => console.log(error))
          } else {
            console.log("Something went wrong!");
            setLoading(false);
          }
        })
        .catch(error => console.log(error))
    } else {
      history.push("/login?backlog=/vmlive/react-2024-" + (isRegular ? 'regular' : 'evening'));
    }
  }

  const refetchPollResults = () => {
    if (AuthToken) {
      api(baseURL + '/getResultsTraining', 'POST', { "content-type": "application/json", Authorization: AuthToken }, {
        questionsCategory: {
          quizName: "React Online Training",
          quizType: "course",
          questionId: question._id,
        },
        answers,
        testEmail: AuthEmail
      })
        .then(response => {
          if (response.success) {
            setResult(response.data);
          } else {
            console.log("Something went wrong!");
          }
        })
        .catch(error => console.log(error))
    } else {
      history.push("/login?backlog=/vmlive/react-2024-" + (isRegular ? 'regular' : 'evening'));
    }
  }

  // Note: Close Popup
  const closeQuizPopup = () => {
    setShowPopup(false);
    clearInterval(timerRef.current);
    clearInterval(timerPollRef.current);
    clearTimeout(timeoutRef.current);
    clearTimeout(autoSubmitRef.current);
    localStorage.removeItem("quizCompleted");
    setTimeLeft(0);
    setResult({});
    resetQuiz();
    if (isAdmin) {
      localStorage.setItem("sessionClosed", true);
      reloadPage();
    }
  }

  const reloadPage = () => {
    window.location.reload();
  }


  return (showPopup || hasSubmitted) && (
    <>
      {loading ? <LoadingIndicator /> : null}
      <div className="quiz-popup">
        {(hasSubmitted || isTimeOut) && <div>
          <button onClick={closeQuizPopup} className='close-quiz'>X</button>
          <button onClick={reloadPage} className='refresh-quiz'><i className='fa fa-refresh' /></button>
        </div>
        }
        {!isTimeOut && <button className='xp-points'>{isQuizType ? 20 : 10} XP</button>}

        {
          isTimeOut && !isAdmin ? <h1 className='text-danger time-out-quiz'>🕒Timeout</h1> :
            <>
              <div className='text-center col-md-6'>
                {isShowTimer &&
                  <div
                    className="circle mt-5"
                    style={{ "--progress": remainingTime + "deg" }}
                  >
                    <h1 className='time-left'>
                      {timeLeft}
                    </h1>
                  </div>
                }
                <h2>{question.question}</h2>
                <div className="d-flex flex-column justify-content-start align-self-start">
                  {(hasOptionsAndNotSubmitted || isAdmin) && question.options.map(({ value, key }, ind) => (
                    <div key={ind} className='form-control mb-2'
                    >
                      <div className='d-flex align-items-center'>
                        <input type="radio" name={"question1"} value={value}
                          disabled={isTimeOut || hasSubmitted}
                          onChange={(e) => {
                            if (!hasSubmitted) {
                              handleChange({ target: { value: e.target.value, name: "question1" } })
                            }
                          }
                          }
                          id={"option" + ind} className='cursor-pointer' />
                        &nbsp;
                        <label htmlFor={"option" + ind} className='cursor-pointer d-flex justify-content-between align-items-center w-100'>
                          <span>{key}</span>
                          {result.questionOpinions && <span> {pollPercentage(ind).quantity > 0 ? pollPercentage(ind).quantity : ''}</span>}
                        </label>
                      </div>
                      <div
                        className='progress-background'
                        style={{ width: pollPercentage(ind).width }}
                      >
                      </div>
                    </div>
                  ))}
                </div>
                {(!hasSubmitted && !isAdmin) &&
                  <button
                    onClick={submitTest}
                    className={`mt-3 submit-btn ${isDisabledSubmit ? 'disabled' : ''} `}
                    disabled={isDisabledSubmit}>
                    {submitText}
                  </button>
                }
              </div>

              {/* Quiz Result */}
              {(isQuizType && !isAdmin) && <div>
                {correctAnswer && <>
                  <div className="mt-3 text-center">
                    <p className='text-success'>Answer: {result.answers[0].answer}</p>
                    <p className='quiz-description'>{result.descriptions[0].description}</p>
                    <h1 className='correct-answer'>🥳Correct Answer</h1>
                    <p>{appreciationText} 👏 +20 XP Points!</p>
                  </div>
                  <img src={"/images/interactive/" + appreciationIcon} alt="success" className='circle mt-5' />
                </>
                }

                {wrongAnswer && <>
                  <div className="mt-3 text-center">
                    <h1 className='text-warning'>🧐Wrong Answer</h1>
                    <p>The answer is: {result.answers[0].answer}</p>
                    <p>{result.descriptions[0].description}</p>
                  </div>
                  <img src={"/images/interactive/" + wrongAnswerIcon} alt="wrong" className='circle mt-5' />
                </>
                }
              </div>}
            </>
        }

      </div>
    </>
  )
};

export default QuizPopup;
