import React, { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import PleaseWait from "../../common/PleaseWait";
import { api, baseURL, LoadingIndicatorGlobal, Validator } from "../../utilities";
import { GoogleLogin } from "react-google-login";
import InstallApp from "../InstallApp";
import { useEffect } from "react";
import moment from "moment";
import { addToStorage } from "../../utilities/localStorage";
import { AuthUser } from "../../redux/constants/courseConstants";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setEmail, setToken, setUserId, setUserName } from "../../redux/reducers/userSlice";
import { Modal } from "react-bootstrap";

export const saveUserSession = (data) => {
  addToStorage(AuthUser.token, data.token);
  addToStorage(AuthUser.username, data.data.name);
  addToStorage(AuthUser.email, data.data.email);
  addToStorage(AuthUser.id, data.data.id);
}

// const testUsers = ["ramanirayudu1318@gmail.com","testuser1@gmail.com","testuser2@gmail.com"];
const testUsers = [];

export const internUsers = ["v.sunithasankar@gmail.com", "rohinipolina0518@gmail.com"];

export const recordedUsers = ["httpchary@gmail.com", "parvinshaik9080@gmail.com", "skthalll2000@gmail.com"];

export const regularBatchUsers = ["nunna.akhil.6@gmail.com", "gnagaraju9928@gmail.com", "v.sunithasankar@gmail.com", "rohinipolina0518@gmail.com", "sudanagunta.riteesh@gmail.com", "kumarbollaboina@gmail.com",
  ...testUsers,
  ...recordedUsers
];

export const eveningBatchUsers = ["balakrishna.nudurumati@gmail.com", "nagarjuna.yadika@gmail.com", "kranthikumar492@gmail.com", "boga.nithin@gmail.com",
  ...testUsers
];

export const deviceInfo = () => {
  let { appCodeName, appName,
    appVersion,
    platform,
    userAgent } = window.navigator;
  let OSName = "Unknown";
  let DeviceType = "Unknown";
  if (window.navigator.userAgent.indexOf("Windows NT 10.0") != -1) OSName = "Windows 10";
  if (window.navigator.userAgent.indexOf("Windows NT 6.3") != -1) OSName = "Windows 8.1";
  if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1) OSName = "Windows 8";
  if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1) OSName = "Windows 7";
  if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1) OSName = "Windows Vista";
  if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1) OSName = "Windows XP";
  if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1) OSName = "Windows 2000";
  if (window.navigator.userAgent.indexOf("Mac") != -1) OSName = "Mac/iOS";
  if (window.navigator.userAgent.indexOf("X11") != -1) OSName = "UNIX";
  if (window.navigator.userAgent.indexOf("Linux") != -1) OSName = "Linux";
  //device type
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
    DeviceType = "tablet";
  } else if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      userAgent
    )
  ) {
    DeviceType = "mobile";
  } else {
    DeviceType = "desktop";
  }
  return {
    DeviceType, OSName, appCodeName, appName,
    appVersion,
    platform,
    userAgent
  }
}
export default function Login() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [show, setShow] = useState("");
  const [message, setMessage] = useState("");
  const { cartItems = [] } = useSelector(store => store.course);
  const { id: AuthUserId, token: AuthToken } = useSelector(store => store.user);
  const [, forceUpdate] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const backlog = queryParams.get('backlog');
  const validator = Validator();
  const history = useHistory();
  const dispatch = useDispatch();
  const clientId = "59189352007-6b69hlcdl8egpk7us5v2cmhganvqunru.apps.googleusercontent.com";

  const onLoginSuccess = (res) => {
    setLoading(true);
    let { appCodeName,
      appName,
      appVersion,
      platform,
      userAgent,
      OSName,
      DeviceType, } = deviceInfo();
    fetch("https://api64.ipify.org/?format=json")
      .then(res => res.json())
      .then(response => {
        let browserInfo = {
          appCodeName,
          appName,
          appVersion,
          platform,
          userAgent,
          OSName,
          DeviceType,
          ipAddress: response ? response.ip : "N/A",
          lastLoginTime: moment(new Date()).format("YYYY-MM-DD hh:mm:ss A")
        }
        let user = api(baseURL + "/logingoogle", "POST", { "content-type": "application/json" }, {
          name: res.profileObj.name,
          date: Date.now(),
          userId: "VM-" + res.profileObj.name,
          email: res.profileObj.email, devices: {
            browserInfo,
            ipAddress: response ? response.ip : "N/A", OSName, DeviceType, lastLoginTime: browserInfo.lastLoginTime
          }
        });
        user
          .then((data) => {
            if (data.success) {
              setMessage("You have successfully logged in!");
              setLoading(false);
              saveUserSession(data);
              dispatch(setToken(data.token));
              dispatch(setEmail(data.data.email));
              dispatch(setUserName(data.data.name));
              dispatch(setUserId(data.data.id));
              if (data.data.isNewAccount) {
                setShow(true);
              } else {
                setTimeout(() => {
                  setLoggedIn(true);
                  setLoading(false);
                  if (cartItems.length > 0) {
                    history.push("/cartitems");
                  } else {
                    history.push("/vm/vmcourses");
                  }
                }, 1000);
              }
            } else {
              setMessage(data.message ? data.message : "Wrong Username/Password");
              setLoading(false);
            }
          }).catch((error) => {
            setMessage("Network Error!");
            setLoading(false);
          });
      });
  };
  const onFailureSuccess = (res) => {
    console.log(res);
    setLoading(false);
    if (res.error && res.error === "popup_closed_by_user") return;
    setMessage("Wrong Username/Password");
  };
  const submitLogin = (e) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      setLoading(true);
      let { appCodeName,
        appName,
        appVersion,
        platform,
        userAgent,
        OSName,
        DeviceType, } = deviceInfo();
      //fetch("https://api64.ipify.org/?format=json")
      //  .then(res => res.json())
      //  .then(response => {
      let response = { ip: "N/A" };
      let browserInfo = {
        appCodeName,
        appName,
        appVersion,
        platform,
        userAgent,
        OSName,
        DeviceType,
        ipAddress: response ? response.ip : "N/A",
        lastLoginTime: moment(new Date()).format("YYYY-MM-DD hh:mm:ss A")
      }
      //if (response) {
      let user = api(baseURL + "/login", "POST", { "content-type": "application/json" }, { email: username, password, devices: { browserInfo, ipAddress: response ? response.ip : "N/A", OSName, DeviceType, lastLoginTime: browserInfo.lastLoginTime } });
      user
        .then((data) => {
          if (data.success) {
            //setMessage("You have successfully logged in!");
            saveUserSession(data);
            dispatch(setToken(data.token));
            dispatch(setEmail(data.data.email));
            dispatch(setUserName(data.data.name));
            dispatch(setUserId(data.data.id));
            setTimeout(() => {
              setLoggedIn(true);
              setLoading(false);

              let commonLink = "/vm/vmcourses";
              if (cartItems.length > 0) {
                history.push("/cartitems");
              } else if (recordedUsers.includes(data.data.email)) {
                commonLink = "/vmlive/react-2024/recorded";
              } else if (regularBatchUsers.includes(data.data.email)) {
                commonLink = "/vmlive/react-2024-regular";
              } else if (eveningBatchUsers.includes(data.data.email)) {
                commonLink = "/vmlive/react-2024-evening";
              }

              history.push(backlog ? backlog : commonLink);
              // window.location.reload();
            }, 3000);
          } else {
            setMessage(data.message ? data.message : "Wrong Username/Password");
            setLoading(false);
          }
        })
        .catch((error) => {
          setMessage("Network Error!");
          setLoading(false);
        });
      //}
      //})
    } else {
      validator.current.showMessages();
      forceUpdate(true);
    }
  };

  const updateUserData = (e) => {
    e.preventDefault();
    setLoading(true);
    let user = api(baseURL + "/updateUserData/" + AuthUserId, "PUT", { "content-type": "application/json", "Authorization": AuthToken }, { name: fullName });
    user
      .then((data) => {
        if (data.success) {
          setTimeout(() => {
            setLoggedIn(true);
            setLoading(false);
            if (cartItems.length > 0) {
              history.push("/cartitems");
            } else {
              history.push("/vm/vmcourses");
            }
          }, 1000);
        } else {
          setLoading(false);
          alert(data.message);
        }
      }).catch((error) => {
        setLoading(false);
        alert("Network Error!");
      });
  }

  useEffect(() => {
    if (AuthUserId !== null) {
      history.push("/vm/vmcourses");
    }
  }, [])
  return (
    <div className="container-fluid text-center vh-100 custom-bg2">
      <InstallApp />
      {!loggedIn && (
        <div className="row justify-content-center align-items-center m-0 p-0 pt-4">
          {loading ? <LoadingIndicatorGlobal text="Logging in..." /> : <div className="col-md-4 col-xs-12 m-3 p-3 border rounded shadow bg-dark text-white">

            <form onSubmit={submitLogin}>
              <i className="fa fa-user" style={{ fontSize: 200, color: "orange" }} />

              <h3 className="mb-2">Signup/Login to your account</h3>
              <div className="google-btn w-100 m-1">
                {!loading && <GoogleLogin
                  className="font-weight-bold w-100 rounded"
                  clientId={clientId}
                  buttonText="Signup/Login with Google"
                  onSuccess={onLoginSuccess}
                  onFailure={onFailureSuccess}
                  cookiePolicy={"single_host_origin"}
                />}
              </div>

              <hr />
              <small className="mb-2">
                Or login with email and password
              </small>
              <hr />
              <input
                type="email"
                placeholder="Username"
                className="form-control"
                value={username}
                // required
                onChange={(e) => setUsername(e.target.value)}
                onFocus={() => setMessage("")}
              />
              {validator.current.message("Username ", username, "required|email")}
              <br />
              <input
                type="password"
                placeholder="Password"
                className="form-control"
                value={password}
                maxLength={16}
                minLength={8}
                // required
                onChange={(e) => setPassword(e.target.value)}
                onFocus={() => setMessage("")}
              />
              {validator.current.message("Password", password, "required|password")}
              <small className="error-message text-warning">{message}</small>
              <br />
              <div className="d-flex justify-content-between align-items-center flex-lg-nowrap flex-wrap">
                <div className="w-100 m-1">
                  {!loading && <button type="submit" value="Login" className="btn btn-outline-warning w-100">Login</button>}
                  {loading && <div className="w-100"><PleaseWait content={"Logging in..."} /></div>}
                </div>
                <div className="w-100 m-1">
                  <Link to="/forgotpassword">
                    <button className="btn btn-outline-info w-100">Forgot Password?</button>
                  </Link>
                </div>
              </div>
            </form>
            <br />
          </div>}
        </div>
      )}




      {/* <Modal show={show}>
        <form onSubmit={updateUserData} className="text-center">
          <div className="form-group">
            <label htmlFor="fullName">Full Name</label>
            <input type="text" id="fullName" placeholder="Enter your full name" value={fullName} onChange={(e) => setFullName(e.target.value)} className="form-control" />
          </div>
          <button className="btn btn-primary w-50">Confirm</button>
        </form>
      </Modal> */}
      {/*{loggedIn && <Redirect to="/vm/vmcourses" />}*/}
    </div>
  );
}
