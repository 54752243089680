import { createSlice } from "@reduxjs/toolkit";
import { CourseModes, courseSliceName, lectureDetails, undefinedType } from "../constants/courseConstants";
import { addToStorage, removeFromStorage } from "../../utilities/localStorage";

const initialState = {
  playerMode: localStorage.getItem(CourseModes.playerMode) !== null ? localStorage.getItem(CourseModes.playerMode) : CourseModes.learning,
  lectureDetails: localStorage.getItem(lectureDetails) && localStorage.getItem(lectureDetails) !== undefined && localStorage.getItem(lectureDetails) !== undefinedType && localStorage.getItem(lectureDetails) !== null ? JSON.parse(localStorage.getItem(lectureDetails)) : null,
  isEnrolled: false,
  cartCount: localStorage.getItem("cartItems") != null ? JSON.parse(localStorage.getItem("cartItems")).length : 0,
  cartItems: localStorage.getItem("cartItems") != null ? JSON.parse(localStorage.getItem("cartItems")) : [],
  buynowItems: localStorage.getItem("buynowItems") != null ? JSON.parse(localStorage.getItem("buynowItems")) : [],
  referralCode: localStorage.getItem("referralCodeShared") != null ? JSON.parse(localStorage.getItem("referralCodeShared")) : null,
};

const courseSlice = createSlice({
  name: courseSliceName,
  initialState,
  reducers: {
    getPlayerMode: (state, action) => {
      state.playerMode = localStorage.getItem(CourseModes.playerMode) !== null ? localStorage.getItem(CourseModes.playerMode) : CourseModes.learning;
    },
    setPlayerMode: (state, action) => {
      state.playerMode = action.payload;
      addToStorage(CourseModes.playerMode, action.payload);
    },
    setLectureDetails: (state, action) => {
      state.lectureDetails = action.payload;
      // TODO: Check `undefinedType`, it seems not required.
      if (action.payload !== undefined && action.payload !== undefinedType) {
        addToStorage(lectureDetails, JSON.stringify(action.payload));
      } else {
        addToStorage(lectureDetails, null);
      }
    },
    clearLectureDetails: (state) => {
      state.lectureDetails = null;
      removeFromStorage(lectureDetails);
    },
    setEnrolled: (state, action) => {
      state.isEnrolled = action.payload;
    },
    setCartCount: (state, action) => {
      state.cartCount = action.payload;
    },
    setCartItems: (state, action) => {
      state.cartItems = action.payload;
    },
    setBuynowItems: (state, action) => {
      state.buynowItems = action.payload;
    },
    setReferralCode: (state, action) => {
      addToStorage('referralCodeShared', JSON.stringify(action.payload));
      state.referralCodeShared = action.payload;
    }
  }
})

export const { getPlayerMode, setPlayerMode, setLectureDetails, setEnrolled, clearLectureDetails, setCartCount,setCartItems,setBuynowItems,setReferralCode } = courseSlice.actions;

export default courseSlice.reducer;