import React, { useEffect } from 'react'
import './AddToCartCelebration.css';

export default function AddToCartCelebration({show=null,setShow=null,text=null}) {
  useEffect(()=>{
    setTimeout(() => {
      setShow('');
    }, 3000);
  },[setShow])

  return (
    <div className="overlay-celebration">
      <div className="container-celebration">
      <img src={show?show:"https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Partying%20Face.png"} alt="Partying Face" width="300" height="300" />
      {/* <img src={show==='add'?"https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Partying%20Face.png":"https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Confused%20Face.png"} alt="Partying Face" width="300" height="300" /> */}
      {/* <h1 className='text-white text-shadow'>{show==="add"?"Add To Cart Successful!":"Removed From Cart"}</h1> */}
      {text?<h1 className='text-white text-shadow'>{text}</h1>:null}
      </div>
    </div>
  )
}
