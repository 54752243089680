import React, { useState, useEffect, useMemo } from "react";
import moment from "moment";
import { useSpeechSynthesis } from "react-speech-kit";
import Footer from "../../common/Footer";
import Statistics from "../Statistics";
import CourseFeatures4Weeks from "../../common/CourseFeatures";
import PurchaseOption from "./PurchaseOption";
import PurchaseOption2 from "./PurchaseOption2";
import OtherCourses from "./OtherCourses";
import { Link, useParams, useHistory } from "react-router-dom";
import InstallApp from '../InstallApp';
import { useCourseData } from './CourseDetails/CourseData';
import { allCoursesStored, discountDuration, reactExpireDate, regularBatchText } from "../../utilities";
import useCourses from "../VMVideoPlayer/useCourses";
// import DiscountPopup from "./DiscountPopup";
import AlreadyFilled from './AlreadyFilled';
import FAQs from "./FAQs";
import ReviewsRatings from "./ReviewsRatings";
import { addToStorage } from "../../utilities/localStorage";
import { cartItems } from "../../redux/constants/courseConstants";
import { useDispatch } from "react-redux";
import { setCartCount, setCartItems } from "../../redux/reducers/courseSlice";

const surpriseDiscount = localStorage.getItem("surpriseDiscount");
const discount = surpriseDiscount ? surpriseDiscount : 0;


export default function CourseDetails() {
  const { speak, voices } = useSpeechSynthesis();
  const { course } = useParams();
  const [remainingTime, setRemaining] = useState("");
  const [selectedBatch, setSelectedBatch] = useState(regularBatchText);
  const courseData = useCourseData(selectedBatch);
  const courseDataMemo = useMemo(() => courseData, [courseData])
  const [currentCourse, setCourse] = useState(courseDataMemo[0])
  const [days, setDays] = useState("");
  const [timeLeft, setTimeLeft] = useState(0); // initialize timeLeft
  const { courses } = useCourses();
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    let courseDetails = courseDataMemo.filter(coursedata => coursedata.coursepath === course);
    // if no course found
    if (!courseDetails.length) {
      history.replace("/page/notfound")
    }
    const courseInfo = allCoursesStored.find(coursedata => coursedata.courselink === course);
    const isEnrolledCourse = courses.findIndex(course => course._id === courseInfo?._id) !== -1;
    const enrolledIds = courses.map(course => course._id);
    const comboCourses = allCoursesStored.filter(course => course.courseCombo.length > 0)
    let courseCombo = allCoursesStored.filter(course => course.courseCombo.length > 0 && course._id === courseInfo?._id);
    courseCombo = courseCombo.length > 0 ? courseCombo.map(course => course.courseCombo) : [];
    const isEnrolledToCombo = comboCourses.findIndex(course =>
      enrolledIds.some(item => course.courseCombo.includes(item))
    ) !== -1;
    const isEnrolled = isEnrolledCourse;
    if (courseDetails.length > 0) {
      setCourse({ ...courseDetails[0], _id: courseInfo?._id, isEnrolled, courseCombo: courseCombo.length > 0 ? courseCombo[0] : [] });
    }
  }, [course, courses, selectedBatch])
  useEffect(
    () => {
      const countDownDate = new Date(course === "react-2024" ? reactExpireDate : currentCourse.courseOffer.expireDate).getTime();
      // Update the count down every 1 second
      const x = setInterval(() => {
        // Get todays date and time
        const now = new Date().getTime();
        // Find the distance between now an the count down date
        const distance = countDownDate - now;
        if (moment(countDownDate).diff(now, "hours") >= 24) {
          let days = moment(countDownDate).diff(now, "days");
          setDays(days);
        }
        // Time calculations for days, hours, minutes and seconds
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        const hh = hours < 10 ? "0" + hours + "H " : hours + "H ";
        const mm = minutes < 10 ? "0" + minutes + "M " : minutes + "M ";
        const ss = seconds < 10 ? "0" + seconds + "S " : seconds + "S ";
        let remaining = hh + " " + mm + " " + ss;
        if (distance < 0) {
          clearInterval(x);
          remaining = "EXPIRED";
        }
        setRemaining(remaining);
      }, 1000);
    },
    [remainingTime],
  );

  // Initialize or calculate time left for the discount
  const initializeTimer = () => {
    const storedStartTime = localStorage.getItem('discountStartTime');
    const now = Math.floor(Date.now() / 1000);

    if (storedStartTime) {
      const elapsed = now - parseInt(storedStartTime, 10);
      if (elapsed < discountDuration) {
        setTimeLeft(discountDuration - elapsed);
      } else {
        localStorage.removeItem('discountStartTime'); // Expired, clear storage
        setTimeLeft(0);
      }
    }
  };

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (isReactTrainingPage) {
        speak({ text: "Hey Hi! Welcome To VM Online Training!", voice: voices[3] })
      }
    }, 1000);
    initializeTimer();
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (timeLeft <= 0) return;

    const timer = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins > 0 ? mins + 'M' : ''} ${secs < 10 ? '0' : ''}${secs}S`;
  };

  const isReactTrainingPage = window.location.pathname.includes("/react-2024");
  const isDiscountTimeOut = discount > 0 && timeLeft > 0 && isReactTrainingPage;
  const discountPrice = 4999 - (isDiscountTimeOut ? discount : 0);

  const addToCart = (selectedCourse) => {
    const selectedCourses = allCoursesStored.filter(course => course._id === selectedCourse._id);
    addToStorage(cartItems, JSON.stringify(selectedCourses));
    dispatch(setCartCount(selectedCourses.length));
    dispatch(setCartItems(selectedCourses));
    history.push('/cartitems');
  }

  return (
    <div className="custom-bg2">
      {/* {isReactTrainingPage &&<DiscountPopup triggerScrollY={100} />} */}
      {remainingTime !== "EXPIRED" && (
        isReactTrainingPage ? <Link to={"/enroll/react-2024?batch=" + regularBatchText} className="text-decoration-none">
          <p
            style={{ fontSize: 25 }}
            className="text-center custom-bg text-white align-items-center d-flex justify-content-center flex-wrap mb-0">
            COURSE PRICE: &nbsp;<b style={{ color: "rgb(231 241 51)" }}>Rs.{isDiscountTimeOut ? discountPrice.toLocaleString('en-IN') : parseInt((parseInt(currentCourse.courseOffer.original) - ((parseInt(currentCourse.courseOffer.original) / 100) * currentCourse.courseOffer.discount))).toLocaleString('en-IN')}/- </b>{" "}
            <sub>
              <del>Rs.{parseInt(currentCourse.courseOffer.original).toLocaleString('en-IN')}/-</del>
            </sub>{" "}
            &nbsp;(
            {isDiscountTimeOut ? <>80% DISCOUNT ENDS IN &nbsp;<b style={{ fontSize: 25, color: "rgb(231 241 51)" }}>{formatTime(timeLeft)}</b></> :
              <>
                {currentCourse.courseOffer.discount}%  DISCOUNT ENDS IN &nbsp;
                <b style={{ fontSize: 25, color: "rgb(231 241 51)" }}>
                  {days ? days + (days > 1 ? " Days" : " Day") + ", " : ""}
                  {remainingTime}
                </b>
              </>})
          </p>
        </Link> :
          <p
            style={{ fontSize: 25 }}
            className="text-center custom-bg text-white align-items-center d-flex justify-content-center flex-wrap mb-0">
            COURSE PRICE: &nbsp;<b style={{ color: "rgb(231 241 51)" }}>Rs.{isDiscountTimeOut ? discountPrice.toLocaleString('en-IN') : parseInt((parseInt(currentCourse.courseOffer.original) - ((parseInt(currentCourse.courseOffer.original) / 100) * currentCourse.courseOffer.discount))).toLocaleString('en-IN')}/- </b>{" "}
            <sub>
              <del>Rs.{parseInt(currentCourse.courseOffer.original).toLocaleString('en-IN')}/-</del>
            </sub>{" "}
            &nbsp;(
            {isDiscountTimeOut ? <>80% DISCOUNT ENDS IN &nbsp;<b style={{ fontSize: 25, color: "rgb(231 241 51)" }}>{formatTime(timeLeft)}</b></> :
              <>
                {currentCourse.courseOffer.discount}% DISCOUNT ENDS IN &nbsp;
                <b style={{ fontSize: 25, color: "rgb(231 241 51)" }}>
                  {days ? days + (days > 1 ? " Days" : " Day") + ", " : ""}
                  {remainingTime}
                </b>
              </>})
          </p>
      )}
      {remainingTime === "EXPIRED" && (
        <p
          style={{ fontSize: 25 }}
          className="text-center bg-secondary text-white align-items-center d-flex justify-content-center flex-wrap">
          REGISTRATIONS ARE CLOSED. PLEASE CONTACT ADMIN FOR UPCOMING BATCH/COURSE DETAILS.
        </p>
      )}

      {/* {isReactTrainingPage && <AlreadyFilled />} */}
      {isDiscountTimeOut && <div className="bg-dark text-white p-2 rounded">
        <p className="text-center m-0"> ️‍🔥Special Discount Ends In: <b>{formatTime(timeLeft)}️‍🔥</b>
        </p>
      </div>}
      {!isReactTrainingPage && <InstallApp />}
      <div className="container mt-4">
        <h1 className="text-center rounded text-white">
          <b className={`text-${currentCourse.header.theme}`}>{currentCourse.header.title}</b> {!["REACT ONLINE TRAINING", "FRONTEND ONLINE TRAINING", "REACT SUMMER INTERNSHIP AND TRAINING"].includes(currentCourse.header.title) ? "COMPLETE COURSE" : ''}{" "}
          {currentCourse.header.isNew && <span className="badge bg-primary badge-pill" style={{ fontSize: 14 }}>
            New
          </span>}
        </h1>
        <div className="popup-animation row justify-content-between m-0 p-0 bg-light border rounded">
          <div
            className={`col-md-6 col-xs-12 rounded text-white p-2 mt-3 bg-${currentCourse.header.theme}`}
            style={{ border: "10px solid whitesmoke" }}>
            {["react-2024", "frontend-2024"].includes(currentCourse.coursepath) ?
              <Link
                to={(["react-2024", "frontend-2024"].includes(currentCourse.coursepath) ? "/enroll/" : "/purchase/") + currentCourse.coursepath + (['react-2024'].includes(currentCourse.coursepath) ? "?batch=" + selectedBatch : '')}>
                <img
                  src={"/images/" + (Array.isArray(currentCourse.header.courseimage) ? (selectedBatch === regularBatchText ? currentCourse.header.courseimage[1] : currentCourse.header.courseimage[0]) : currentCourse.header.courseimage)}
                  className="w-100 cursor-pointer"
                  alt={`${currentCourse.header.title} Course`}
                />
              </Link> :
              <div
                onClick={() => addToCart(currentCourse)}
                to={(["react-2024", "frontend-2024"].includes(currentCourse.coursepath) ? "/enroll/" : "/purchase/") + currentCourse.coursepath + (['react-2024'].includes(currentCourse.coursepath) ? "?batch=" + selectedBatch : '')}>
                <img
                  src={"/images/" + (Array.isArray(currentCourse.header.courseimage) ? (selectedBatch === regularBatchText ? currentCourse.header.courseimage[1] : currentCourse.header.courseimage[0]) : currentCourse.header.courseimage)}
                  className="w-100 cursor-pointer"
                  alt={`${currentCourse.header.title} Course`}
                />
              </div>
              }
            <PurchaseOption
              to={`/purchase/${currentCourse.coursepath}`}
              enrollto={`/enroll/${currentCourse.coursepath}`}
              isEnroll={currentCourse.header.isEnroll}
              isPurchase={currentCourse.header.isPurchase}
              amount={(parseInt(currentCourse.courseOffer.original) - ((parseInt(currentCourse.courseOffer.original) / 100) * currentCourse.courseOffer.discount))}
              original={currentCourse.courseOffer.original}
              enrollamount={isDiscountTimeOut ? discountPrice : currentCourse.courseOffer.enrollamount}
              enrolloriginal={currentCourse.courseOffer.enrolloriginal}
              isEnrolled={currentCourse.isEnrolled}
              course={currentCourse.courseCombo?.length > 0 ? { _id: currentCourse.courseCombo[0] } : currentCourse}
              selectedBatch={selectedBatch}
              setSelectedBatch={setSelectedBatch}
              showTimer={!isDiscountTimeOut}
              currentCourse={currentCourse}
            />
          </div>
          {currentCourse.header.curriculum()}
          <br />
        </div>
        <CourseFeatures4Weeks coursefeatures={currentCourse.coursefeatures} />
        {isReactTrainingPage ? <ReviewsRatings /> : null}
        {isReactTrainingPage ? <FAQs /> : null}
        {!isReactTrainingPage ? <OtherCourses courses={currentCourse.relatedCourses} /> : null}
        <Statistics />
        <div className="container mt-4 mb-4 d-flex justify-content-between flex-wrap border p-2 bg-light rounded">
          <div>
            <h3 className="text-warning"><i className="fa fa-desktop" /> Hardware Requirements:</h3>
            <ul>
              <li>Memory – Minimum {currentCourse.requirements.hardwarerequirements.memory} GB RAM</li>
              <li>Processor – Intel Core {currentCourse.requirements.hardwarerequirements.processor} CPU @2.00 GHz or later</li>
              <li>Storage – {currentCourse.requirements.hardwarerequirements.storage} GB HDD/SDD or above</li>
            </ul>
            <h3 className="text-warning"><i className="fa fa-windows" /> Software Requirements:</h3>
            <ul>
              <li>Operating System – {currentCourse.requirements.softwarerequirements.os}</li>
              <li>{currentCourse.requirements.softwarerequirements.ide}</li>
              <li>{currentCourse.requirements.softwarerequirements.browser}</li>
              {currentCourse.requirements.softwarerequirements.android && <li>Android Studio (latest version) </li>}
              {currentCourse.requirements.softwarerequirements.android && <li>NodeJS (latest version) </li>}
              {currentCourse.requirements.softwarerequirements.android && <li>JDK 8 or above version </li>}
            </ul>
          </div>
          <div className="col-md-6 col-xs-12">
            <img src={`/images/${currentCourse.requirements.certificateImage}`} alt="certificate" className="w-100" />
          </div>
        </div>
        <PurchaseOption2
          to={`/purchase/${currentCourse.coursepath}`}
          enrollto={`/enroll/${currentCourse.coursepath}`}
          isEnroll={currentCourse.header.isEnroll}
          isPurchase={currentCourse.header.isPurchase}
          amount={currentCourse.courseOffer.amount}
          original={currentCourse.courseOffer.original}
          enrollamount={currentCourse.courseOffer.enrollamount}
          enrolloriginal={currentCourse.courseOffer.enrolloriginal}
          discount={currentCourse.courseOffer.discount}
        />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}
