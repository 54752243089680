import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import PurchasePopup from "../../common/PurchasePopup";
import VMModal from "../../common/VMModal";
import { allCoursesStored } from "../../utilities";
import { openPlayer } from "../VMVideoPlayer/useCourses";
import SelectBatch from "./SelectBatch";
import { useSelector } from "react-redux";
import { addToStorage } from "../../utilities/localStorage";
import { cartItems } from "../../redux/constants/courseConstants";
import { useDispatch } from "react-redux";
import { setCartCount, setCartItems } from "../../redux/reducers/courseSlice";

export default function PurchaseOption({
  to = "",
  enrollto = "",
  isPurchase = false,
  isEnroll = false,
  amount = 0,
  original = 0,
  enrollamount = 0,
  enrolloriginal = 0,
  isEnrolled = false,
  course = {},
  selectedBatch,
  setSelectedBatch,
  showTimer = false,
  currentCourse = null
}) {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const { cartCount = 0, cartItems: cartItemsData = [] } = useSelector(store => store.course);
  const { token: AuthToken } = useSelector(store => store.user);
  const dispatch = useDispatch();
  const isLoggedIn = AuthToken !== null;
  useEffect(
    () => {
      window.scrollTo(0, 0);
      let mobile = /iphone|ipod|android|blackberry|mini|windows\sce|palm/i.test(navigator.userAgent.toLowerCase());
      if (mobile) {
        setShow(true);
      } else {
        setShow(false);
      }
    },
    [show],
  );
  return (
    <div>
      {isEnroll ? (
        <div>
          {/* <a href="https://www.youtube.com/watch?v=FK3yeRkwiwU" target="_blank"
            style={{ cursor: "pointer" }}
            rel="noreferrer"
            className="nav-link text-center font-weight-bold shadow bg-dark text-white p-4 m-2 rounded">
            <i className="fa fa-play-circle" /> <b className="text-primary">CLICK HERE</b> FOR <b>DEMO SESSION</b> (New Batch - August 13th)
          </a> */}
          <SelectBatch selectedBatch={selectedBatch} setSelectedBatch={setSelectedBatch} />
          <Link
            to={enrollto + "?batch=" + selectedBatch}
            style={{ cursor: "pointer" }}
            className="nav-link text-center font-weight-bold shadow bg-dark p-4 m-2 rounded text-white enroll-link d-flex justify-content-center align-items-center">
            <div class="blinking-dot"></div> &nbsp; {show ? "ENROLL NOW " : "ENROLL NOW "}
            (<b className="text-yellow">Rs.
              {parseInt(enrollamount).toLocaleString('en-IN')}
              /-</b>){" "}
            <sub>
              <del>{parseInt(enrolloriginal).toLocaleString('en-IN')}</del>
            </sub>
          </Link>
          {/* <div className="timer-container">
          {showTimer && <TimerCountDown/>}
          </div> */}
        </div>
      ) : (
        <React.Fragment>
          <br />
          <br />
          <br />
        </React.Fragment>
      )}

      {isPurchase &&
        <div
          onClick={() => {
            if (isLoggedIn && isEnrolled) {
              openPlayer(course, history)
            } else {
              if (currentCourse) {
                const course = allCoursesStored.find(course => course._id === currentCourse._id);
                // let updatedCart = [];
                // updatedCart = [course];
                // addToStorage(buynowItems, JSON.stringify(updatedCart));
                // dispatch(setBuynowItems(updatedCart));
                // history.push('/buynowcourses');
                let cart = cartItemsData;
                if (cart.length > 0) {
                  let updatedCart = [];
                  updatedCart = [...cart, course]
                  if (cart.find(item => item.title === course.title)) {
                    history.push('/cartitems');
                  } else {
                    addToStorage(cartItems, JSON.stringify(updatedCart));
                    dispatch(setCartCount(cartCount + (course.courseCombo.length > 0 ? course.courseCombo.length : updatedCart.length)));
                    dispatch(setCartItems(updatedCart));
                    history.push('/cartitems');
                  }
                } else {
                  let updatedCart = [];
                  updatedCart = [course]
                  addToStorage(cartItems, JSON.stringify(updatedCart));
                  dispatch(setCartCount(cartCount + (course.courseCombo.length > 0 ? course.courseCombo.length : updatedCart.length)));
                  dispatch(setCartItems(updatedCart));
                  history.push('/cartitems');
                }
              }
            }
          }}
          style={{ cursor: "pointer" }}
          className="nav-link text-center font-weight-bold shadow bg-white m-2 rounded text-primary p-3"
        >
          {isLoggedIn && isEnrolled ? <><i className="fa fa-play-circle" /> LEARN NOW</> :
            <>
              <i className="fa fa-shopping-cart" /> BUY NOW (Rs.{parseInt(amount).toLocaleString('en-IN')}/-)
              <sub>
                <del>{parseInt(original).toLocaleString('en-IN')}</del>
              </sub>
            </>}
        </div>}

      {showPayment && <VMModal proceed={false} size="lg" title="Payment and Confirm Order" component={<PurchasePopup course={to.split("/purchase/")[1]} onClose={setShowPayment} />} onClose={setShowPayment} />}
    </div>
  );
}
