import React, { useRef, useState } from 'react'
import { useEffect } from 'react';
import { api, baseURL } from '../../../utilities';
import { useSelector } from 'react-redux';

export default function CourseWish() {
  return (
    <div className='d-flex justify-content-between flex-wrap align-items-center flex-wrap bg-dark text-white rounded'>
      <div className='d-flex align-items-center flex-wrap'>
        <img src="https://vmtraining.netlify.app/images/logo.png" alt="user logo" className='img-circle' />
        <GoodMorningWish />
      </div>

      <ReferAFriend />

      {/* <div className='d-flex align-items-center flex-wrap'>
        <i className='fa fa-laptop display-3 animcolor' />
        <Link to="/purchase/allcourses" className='text-decoration-none text-warning'>Purchase All Courses for <h4 className='animcolor'>60% Discount!</h4></Link>
      </div> */}
    </div>
  )
}

export function ReferAFriend({ textColor = "warning" }) {
  const [show, setShow] = useState(false);
  const [myreferralCode, setMyReferralCode] = useState('');
    const whatsappLinkRef = useRef();
    const mailLinkRef = useRef();
  const { token: AuthToken, id: AuthUserId, username: AuthUsername } = useSelector(store => store.user);
  const getRewardPoints = () => {
    if (AuthToken) {
      let user = api(baseURL + "/getRewardPointsByUserId/" + AuthUserId, "GET", { "Authorization": AuthToken });
      user
        .then((data) => {
          if (data.success) {
            console.log('Step-1: Rewards Points!', data);
            setMyReferralCode(data.data.referralCode);
          } else {
            console.log('Step-1: Error!', data);
            // alert(data.message);
          }
        })
        .catch((error) => {
          console.log('Step-4: Error!', error);
          // alert("Error" + error);
        });
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
      let mobile = /iphone|ipod|android|blackberry|mini|windows\sce|palm/i.test(navigator.userAgent.toLowerCase());
      if (mobile) {
        setShow(true);
      } else {
        setShow(false);
      }
    getRewardPoints();
  }, [])

  const handleReferLink=()=>{
    if(show){
    whatsappLinkRef.current.click();
    } else{
    mailLinkRef.current.click();
    }
  }

  if(!AuthToken) return null;
  return (
    <div className='d-flex align-items-center'>
      <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Smiling%20Face%20with%20Heart-Eyes.png" alt="Smiling Face with Heart-Eyes" width="25" height="25" /> <div className={`nav-link text-${textColor} cursor-pointer`} onClick={handleReferLink}>Refer a friend to get 500 reward points!</div>

{/* mail */}
      <a href={`https://mail.google.com/mail/?view=cm&fs=1&to=your-friend-email-address&&cc=mogilivenkatesh3@gmail.com&su=Get ₹500 Off on Full-Stack App Development Courses! 🚀&body=Hey!😃%0D%0A%0D%0AI've been learning Full-Stack App Development on the VM Online Training Platform, and it's been an amazing experience! You should try it too! Use my referral code ${myreferralCode} to get an instant ₹500 discount on any course. 🥳%0D%0A%0D%0A🔥 Popular Courses:%0D%0A1.MERN Stack - https://vmtraining.netlify.app/mernstack%0D%0A2.MEAN Stack - https://vmtraining.netlify.app/meanstack%0D%0A3.Web Development - https://vmtraining.netlify.app/webdeveloper%0D%0A4.JavaScript - https://vmtraining.netlify.app/javascript%0D%0A5.React with Redux - https://vmtraining.netlify.app/reactredux%0D%0A6.Nodejs with mongodb - https://vmtraining.netlify.app/nodejs%0D%0A7.Angular - https://vmtraining.netlify.app/angular%0D%0A8.React Native - https://vmtraining.netlify.app/reactnative%0D%0A9.React TypeScript - https://vmtraining.netlify.app/reacttypescript%0D%0A10.More Courses - https://vmtraining.netlify.app/courses.%0D%0A%0D%0A💡 How to Enroll:%0D%0A1.Choose a course%0D%0A2.Enter Referral Code: ${myreferralCode}%0D%0A3.Apply and Pay%0D%0A%0D%0A🎉 Referral Link: https://vmtraining.netlify.app/courses?referralcode=${myreferralCode}%0D%0A🎉 Referral Code: ${myreferralCode}%0D%0ALet's level up our skills together! 💪🚀❤️%0D%0A%0D%0ABest Regards,%0D%0A${AuthUsername}.`} target="_blank" rel="noreferrer" hidden className={`nav-link text-${textColor}`} ref={mailLinkRef}>Refer a friend to get 500 reward points!</a>

{/* whatsapp */}
      <a
        className="text-success"
        href={
          `https://api.whatsapp.com/send?text=Hey!😃 I've been learning Full-Stack App Development on the VM Online Training Platform, and it's been an amazing experience! You should try it too! Use my referral code ${myreferralCode} to get an instant ₹500 discount on any course. 🥳

          🔥 Popular Courses:
          1.MERN Stack - https://vmtraining.netlify.app/mernstack
          2.MEAN Stack - https://vmtraining.netlify.app/meanstack
          3.Web Development - https://vmtraining.netlify.app/webdeveloper
          4.JavaScript - https://vmtraining.netlify.app/javascript
          5.React with Redux - https://vmtraining.netlify.app/reactredux
          6.Nodejs with mongodb - https://vmtraining.netlify.app/nodejs
          7.Angular - https://vmtraining.netlify.app/angular
          8.React Native - https://vmtraining.netlify.app/reactnative
          9.React TypeScript - https://vmtraining.netlify.app/reacttypescript
          10.More Courses - https://vmtraining.netlify.app/courses.

          💡 How to Enroll:
          1.Choose a course
          2.Enter Referral Code: ${myreferralCode}
          3.Apply and Pay

          🎉 Referral Link: https://vmtraining.netlify.app/courses?referralcode=${myreferralCode}
          🎉 Referral Code: ${myreferralCode}
          Let's level up our skills together! 💪🚀❤️

          Your well wisher! ${AuthUsername}😊`
        }
        target="_blank"
        id="link1"
        hidden
        ref={whatsappLinkRef}
        rel="noreferrer">
        <i className="fa fa-whatsapp text-success" /> <b>Click here</b>
      </a>
    </div>
  );
}
export function GoodMorningWish() {
  const [wish, setWish] = useState("");
  useEffect(() => {
    const hours = new Date().getHours();
    if (hours < 12 && hours > 6) {
      setWish(`Morning`);
    } else {
      if (hours > 12 && hours < 18) {
        setWish(`Afternoon`);
      } else {
        setWish(`Evening`);
      }
    }
  }, []);
  const brilliantWords = ["Genius", "Brilliant", "VM Star"];
  const randomWord = brilliantWords[Math.floor(Math.random() * brilliantWords.length)];
  return (<span className='text-white m-2 p-0'>Good {wish} <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Nerd%20Face.png" alt="Nerd Face" width="25" height="25" />{(localStorage.getItem("username") !== null ? " " + localStorage.getItem("username") : " " + randomWord)}!</span>
  )
}