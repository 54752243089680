import React, { useState, useEffect } from "react";
import { api, baseURL, logoutUser, Validator } from "../../utilities";
import ReactStars from "react-rating-stars-component";
import PleaseWait from "../../common/PleaseWait";
import { useSelector } from "react-redux";

export default function Feedback() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [feedback, setFeedback] = useState("");
  const [rating, setRating] = useState([]);
  const { token: AuthToken, email: AuthEmail } = useSelector(store => store.user);
  const [, forceUpdate] = useState(false);
  const validator = Validator();
  useEffect(() => {
    let userId = localStorage.getItem("id");
    let token = localStorage.getItem("token");
    if (userId != null) {
      setLoading(true);
      api(
        baseURL + "/getfeedbacks",
        "POST",
        { "content-type": "application/json", Authorization: token },
        { email: AuthEmail },
      )
        .then((data) => {
          if (data.success) {
            setLoading(false);
            alert("Already Feedback Given");
            window.location.href = "/vm/vmcourses";
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          // alert("Something went wrong");
        });
      api(baseURL + "/getCoursesById/" + userId, "GET", { Authorization: token }, {})
        .then((data) => {
          if (data.success) {
            setLoading(false);
            let rates = data.data.map((course) => {
              let obj = {};
              obj.name = course.name;
              obj.value = null;
              obj.courseId = course._id;
              return obj;
            });
            setRating(rates);
          } else {
            logoutUser();
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          // alert("Something went wrong");
        });
    } else {
      window.location.href = "/login";
    }
  }, []);
  const submitLogin = (e) => {
    e.preventDefault();

    let userId = localStorage.getItem("id");
    if (validator.current.allValid()) {
      setLoading(true);
      let result = rating.map((rate) => {
        return api(
          baseURL + "/feedback",
          "POST",
          { "content-type": "application/json", Authorization: AuthToken },
          { feedback, courseId: rate.courseId, courseName: rate.name, email: AuthEmail, userId, rating: rate.value },
        ).then((data) => data.success);
      });
      Promise.all(result)
        .then((values) => {
          if (!values.includes(false)) {
            setLoading(false);
            setMessage("Feedback Submitted Successfully!");
            setTimeout(() => {
              window.location.href = "/vm/vmcourses";
            }, 3000);
          } else {
            setLoading(false);
            setMessage("Feedback Already Submitted Successfully!");
            setTimeout(() => {
              window.location.href = "/vm/vmcourses";
            }, 3000);
          }
        })
        .catch((error) => {
          setLoading(false);
          setMessage("Something went wrong");
          console.log("Something went wrong");
        });
    } else {
      validator.current.showMessages();
      forceUpdate(true);
    }
  };
  return (
    <div>
      <div className="container-fluid text-center custom-bg2">
        <div className="row justify-content-center align-items-center m-0 p-0">
          <div className="col-md-5 col-xs-12 m-3 p-3 border rounded shadow bg-dark text-white">
            <form onSubmit={submitLogin}>
              <i className="fa fa-send" style={{ fontSize: 200, color: "orange" }} />
              <h1 className="mb-4">Feedback</h1>
              <textarea
                type="text"
                placeholder="Write your feedback here..."
                className="form-control"
                value={feedback}
                // required
                onChange={(e) => setFeedback(e.target.value)}
                onFocus={() => setMessage("")}
              />

              {validator.current.message("Feedback ", feedback, "required")}
              {rating.map(({ name, value }, index) => (
                <div key={index}>
                  <div className="row align-items-center">
                    <div className="text-left col-lg-6">{name}</div>
                    &nbsp;&nbsp;
                    <div className="text-left col-lg-5">
                      <ReactStars
                        count={5}
                        onChange={(value) => {
                          let copy = [...rating];
                          copy[index].value = value;
                          setRating(copy);
                        }}
                        value={value}
                        size={24}
                        isHalf={true}
                        emptyIcon={<i className="far fa-star" />}
                        halfIcon={<i className="fa fa-star-half-alt" />}
                        fullIcon={<i className="fa fa-star" />}
                        activeColor="#ffd700"
                      />
                    </div>
                    {validator.current.message("Rating for " + name, value, "required")}
                  </div>
                </div>
              ))}
              {<span>{message}</span>}
              <br />
              {!loading && <input type="submit" value="Submit" className="btn btn-primary w-50" />}
              {loading && <PleaseWait content={"Please wait..."} />}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
