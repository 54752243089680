import React, { useEffect, useState } from "react";
import "../../styles/custom-styles.css";
import Festival from "../../common/Festival";
import Footer from "../../common/Footer";
import CoursesList from "./CoursesList";
import moment from "moment";
import { allCoursesStored, LoadingIndicatorGlobal } from "../../utilities";
import { addToStorage } from "../../utilities/localStorage";
import { cartItems } from "../../redux/constants/courseConstants";
import { useDispatch } from "react-redux";
import { setCartCount, setCartItems, setReferralCode } from "../../redux/reducers/courseSlice";
import { useHistory } from "react-router-dom";
import useCourses from "../VMVideoPlayer/useCourses";

export default function Courses() {
  let [monthEnd] = useState(moment(new Date()).format("MMMM"))
  let [monthEndDate] = useState(moment(new Date()).endOf('month').format("Do"));
  const dispatch = useDispatch();
  const history = useHistory();
  const { courses: enrolledCourses = [], loading } = useCourses();

  useEffect(()=>{
    let url=window.location.href;
    const urlParams = new URL(url).searchParams;
    const referralCode = urlParams.get("referralcode");
    if(referralCode){
      dispatch(setReferralCode(referralCode));
    }
  },[])

  const addAllToCart = () => {
    const selectedCourses = allCoursesStored.filter(course => !course.courseCombo.length && course.price > 0);
    addToStorage(cartItems, JSON.stringify(selectedCourses));
    dispatch(setCartCount(selectedCourses.length));
    dispatch(setCartItems(selectedCourses));
    history.push('/cartitems');
  }
  let offer = () => (
    <div
      className="text-capitalize font-weight-bold text-primary"
      style={{ fontFamily: "Century Gothic", fontWeight: "bold" }}>
      <hr />
      {enrolledCourses.length === 0 && <p>
        <div className="cursor-pointer text-white" onClick={addAllToCart}>
          &nbsp;
          <b className="text-uppercase"> <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Hand%20gestures/Backhand%20Index%20Pointing%20Right.png" alt="Backhand Index Pointing Right" width="25" height="25" /> <b className="animcolor">BUY All COURSES FOR <small className="text-warning">~60%</small> DISCOUNT WITH LIFETIME ACCESS</b>
          &nbsp;
          <i className="fa fa-angle-right animcolor2" /> <i className="fa fa-angle-right animcolor3" /> Rs.9,999
          <sub className="text-secondary">(<del>Rs.23,700</del>)</sub>🔥
          </b>
        </div>
        <hr />
      </p>}

      {/* <b className="text-warning">🔥 <b className="text-primary">1+1 Offer</b> on all courses (HTML, JS, CSS, ReactJS, NodeJS, React Native, Angular) on ₹1500+ purchase till <b className="text-primary">29th Jan 2025!</b> 🔥</b> */}
      <div className="p-2 rounded alert alert-primary bg-primary text-white">
      <b className="text-white"> <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Heart%20on%20Fire.png" alt="Heart on Fire" width="25" height="25" /> Get 25% Discount Offer</b> On Any Course
      </div>
      {/* <b className="text-warning"> Minimum <b className="text-info">30% Discount</b> On <b className="text-info">Any Combo Course</b> Till</b> <b className="text-info">31st July 2024.</b> */}
      {/*<i className="fa fa-check" />*/}
      {/*<b className="text-warning"> 75% Discount</b> For Any Course Till 16th August 2022 11:59 PM.*/}
    </div>
  )
  if (loading) return <LoadingIndicatorGlobal />;
  return (
    <div className="container-fluid text-center custom-bg2">
      <Festival />
      <CoursesList title="Full Courses Discount" offer={offer()} enrolledCourses={enrolledCourses} />
      <Footer />
    </div>
  );
}
