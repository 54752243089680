import React, { useState } from 'react';
import { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { allCoursesStored, api, baseURL, LoadingIndicator } from '../../utilities';
import { addToStorage } from '../../utilities/localStorage';
import { AuthUser, cartItems } from '../../redux/constants/courseConstants';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useGoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { deviceInfo } from '../auth/Login';
import moment from 'moment';
import {  setCartItems } from '../../redux/reducers/courseSlice';
const clientId = "59189352007-6b69hlcdl8egpk7us5v2cmhganvqunru.apps.googleusercontent.com";

function BuyNowItemsWithGoogle() {
  const history = useHistory();
  const login = useGoogleLogin({
    onSuccess: tokenResponse => {
      fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
        headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
      })
        .then(res => res.json())
        .then(data => proceedToCheckoutOnline(data));
    }
  });
  const [allCourses, setAllCourses] = useState(allCoursesStored ? allCoursesStored : []);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { buynowItems: cartItemsData } = useSelector((store) => store.course);
  const { token: AuthToken, id: AuthUserId, email: AuthEmail, username: AuthUsername } = useSelector(store => store.user);
  const dispatch = useDispatch();
  const [selectedCourses, setCourses] = useState(cartItemsData || []);
  const [fullStackCourses, setFullStackCourses] = useState([]);
  const [individualCourses, setIndividualCourses] = useState([]);

  const [data, setData] = useState({});

  // Helper function to get course names from IDs
  const [selectedCourseComboIds, setSelectedCourseComboIds] = useState([]);
  const [selectedCourseIds, setSelectedCourseIds] = useState([]);
  const [totalCourseIds, setTotalCourseIds] = useState([]);
  const [uniqueCourses, setUniqueCourses] = useState(0);
  const [uniqueCoursesInfo, setUniqueCoursesInfo] = useState([]);
  const [uniqueCourseIds, setUniqueCourseIds] = useState([]);

  const getCourseNames = (ids) =>
    ids
      .map((id) => {
        const isRepeated = totalCourseIds.filter(courseId => courseId === id).length > 1;
        const course = allCourses.find((c) => c._id === id);
        return course ? `${course.title} ${isRepeated ? ' (Shared)' : ''}` : null;
      })
      .filter(Boolean); // Remove null values

  const applyDiscount = (price, discount) => price - (price * discount) / 100;

  const calculateComboPrice = (ids, discount) => {
    const total = ids.reduce((sum, id) => {
      const course = allCourses.find((c) => c._id === id);
      return sum + (course?.price || 0);
    }, 0);

    return applyDiscount(total, discount);
  };

  const fetchCourses = () => {
    let premium = allCourses.filter(course => course.courseType === "Premium");
    let existingIds = localStorage.getItem("buynowItems") != null ? JSON.parse(localStorage.getItem("buynowItems")) : [];
    if (existingIds !== null) {
      existingIds = existingIds.map(course => course._id);
      let resultt = [];
      premium.forEach(course => {
        if (existingIds.filter(item => course.courseCombo.includes(item)).length === 0 && existingIds.includes(course._id)) {
          resultt.push(course)
        }
      })
      setCourses(resultt);
    }
    setError("")
  }
  useEffect(() => {
    fetchCourses();
  }, []);

  useEffect(() => {
    const cartItemsDatas = cartItemsData || [];
    if (cartItemsDatas.length > 0) {
      const coursesData = [...cartItemsDatas];
      const sortedCourses = coursesData.sort((a, b) => b.price - a.price);
      setCourses(sortedCourses);
      setFullStackCourses(sortedCourses.filter((course) => course.courseCombo.length > 0));
      setIndividualCourses(sortedCourses.filter((course) => course.courseCombo.length === 0));
    } else {
      setCourses([]);
      setFullStackCourses([]);
      setIndividualCourses([]);
    }
  }, [cartItemsData]);

  useEffect(() => {
    if (selectedCourses.length > 0) {
      setSelectedCourseComboIds(selectedCourses.map(course => course.courseCombo));
      setSelectedCourseIds(selectedCourses.filter(course => course.courseCombo.length === 0).map(course => course._id));
      const totalCourseIds = [...selectedCourseComboIds.flat(), ...selectedCourseIds];
      const uniqueCoursesInfo = allCourses.filter(course => totalCourseIds.includes(course._id));
      setUniqueCoursesInfo(uniqueCoursesInfo);
      setTotalCourseIds(totalCourseIds);
      setUniqueCourses(new Set(totalCourseIds).size);
      setUniqueCourseIds([...new Set(totalCourseIds)]);
    } else {
      setSelectedCourseComboIds([]);
      setSelectedCourseIds([]);
      setUniqueCoursesInfo([]);
      setTotalCourseIds([]);
      setUniqueCourses(0);
      setUniqueCourseIds([]);
    }
  }, [selectedCourses])


  const totalAmount = [...fullStackCourses, ...individualCourses].reduce((prev, current) => prev + current.price, 0);
  const totalDiscountAmount = [...fullStackCourses, ...individualCourses].reduce((prev, current) => {
    return prev + (current.courseCombo.length > 0 ? calculateComboPrice(current.courseCombo, current.discount) : applyDiscount(current.price, current.discount))
  }, 0);
  let totalDiscountedPrice = uniqueCourses === 10 ? 9999 : totalDiscountAmount;
  // totalDiscountedPrice = uniqueCourses < 10 && uniqueCourses % 2 === 0 ? totalAmount / 2 : totalDiscountedPrice;
  const totalSavingsPrice = totalAmount - totalDiscountedPrice;
  const totalDiscountPercentage = totalAmount > 0 ? Math.round(((totalAmount - totalDiscountedPrice) / totalAmount) * 100) : 0;
  const taxPercentage = 5;
  const taxPercentageValue = taxPercentage / 100;
  const taxPrice = Math.ceil(totalDiscountedPrice * taxPercentageValue);
  const totalDiscountedPriceWithTax = totalDiscountedPrice + taxPrice;

  // Direct Payment
  const proceedToCheckout = () => {
    history.push("/purchase/cart", { selectedCourses });
  }

  // Online Payment
  const proceedToCheckoutOnline = (data) => {
    setLoading(true);
    if (!AuthToken && (data && !data.email)) {
      console.log('Step-1: Create user!');
      // anonymous user
      login();
    } else {
      console.log('Step-2: Login user!');
      // logged in user
      if (uniqueCourses > 0) {
        try {
          localStorage.setItem(cartItems, JSON.stringify(cartItemsData));
          dispatch(setCartItems(cartItemsData));
          const coursesInfo = uniqueCoursesInfo;
          // let totalCoursesNames = getCourseNames(uniqueCourseIds).join(",") || [];
          const totalCoursesNames = 'VM Online Training Courses';
          if (AuthToken) {
            console.log('Step-3: Create Order - Loggedin!');
            let user = api(baseURL + "/createPayuOrder", "POST", { "content-type": "application/json" }, {
              amount: totalDiscountedPriceWithTax,
              firstname: AuthUsername,
              email: AuthEmail,
              txnid: `TID${Date.now()}`,
              productinfo: totalCoursesNames,
              userId: AuthUserId,
              coursesInfo
            });
            user
              .then((data) => {
                if (data.success) {
                  console.log('Step-4: Create Order Successful!', data);
                  setData(data.data.form);
                  setLoading(false);
                } else {
                  console.log('Step-4: Create Order Failed!', data);
                  alert(data.message);
                  setLoading(false);
                }
              })
              .catch((error) => {
                console.log('Step-4: Create Order Error!', error);
                alert("Error" + error);
                setLoading(false);
              });
          } else {
            console.log('Step-3: Fetch IP Address - Anonymous!');
            let { appCodeName,
              appName,
              appVersion,
              platform,
              userAgent,
              OSName,
              DeviceType, } = deviceInfo();
            fetch("https://api64.ipify.org/?format=json")
              .then(res => res.json())
              .then(response => {
                let browserInfo = {
                  appCodeName,
                  appName,
                  appVersion,
                  platform,
                  userAgent,
                  OSName,
                  DeviceType,
                  ipAddress: response ? response.ip : "N/A",
                  lastLoginTime: moment(new Date()).format("YYYY-MM-DD hh:mm:ss A")
                }
                console.log('Step-3.1: Create Order - Anonymous!');
                let user = api(baseURL + "/createPayuOrder", "POST", { "content-type": "application/json" }, {
                  amount: totalDiscountedPriceWithTax,
                  firstname: data.name,
                  name: data.name,
                  date: Date.now(),
                  email: data.email,
                  txnid: `TID${Date.now()}`,
                  productinfo: totalCoursesNames,
                  userId: '',
                  coursesInfo,
                  devices: {
                    browserInfo,
                    ipAddress: response ? response.ip : "N/A", OSName, DeviceType, lastLoginTime: browserInfo.lastLoginTime
                  }
                });
                user
                  .then((data) => {
                    if (data.success) {
                      console.log('Step-4: Create Order Successful!', data);
                      setData(data.data.form);
                      addToStorage(AuthUser.token, data.data.userData.token);
                      addToStorage(AuthUser.username, data.data.userData.name);
                      addToStorage(AuthUser.email, data.data.userData.email);
                      addToStorage(AuthUser.id, data.data.userData.id);
                      setLoading(false);
                    } else {
                      console.log('Step-4: Create Order Failed!', data);
                      alert(data.message);
                      setLoading(false);
                    }
                  })
                  .catch((error) => {
                    alert("Error" + error);
                    setLoading(false);
                  });
              });
          }
        } catch (error) {
          console.log('Step-4: Create Order Error!', error);
          console.log("Error", error);
          setLoading(false);
        }
      } else {
        console.log("No items in cart!");
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    if (data && typeof data === "string") {
      const formWrapper = document.getElementById("paymentData");
      formWrapper.innerHTML = data;
      const formElement = formWrapper.querySelector("form");
      if (formElement) {
        formElement.submit();
      }
    }
  }, [data]);

  return (
    <>
      {/* <InstallApp /> */}
      <div className="d-none" id="paymentData" dangerouslySetInnerHTML={{ __html: data }}></div>
      <div className="container padding-bottom-3x mb-1 pb-3 pt-3 mt-4 custom-bg rounded popup-animation">
        {selectedCourses.length > 0 && <div className='d-flex justify-content-between align-items-center'>
          <h1 className='text-white mt-4'><img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Smiling%20Face%20with%20Sunglasses.png" alt="Smiling Face with Sunglasses" width="50" height="50" />VM Cart</h1>
          <h1 className='text-white mt-4'>({uniqueCourses} {uniqueCourses > 1 ? "Courses" : "Course"})</h1>
        </div>}
        {error.length > 0 ? <h3 className="text-danger text-center">{error}</h3> : null}
        {loading ? <div className="vh-100 text-center"><LoadingIndicator size={"5"} /></div> :
          <div className="card">
            {selectedCourses.length === 0 &&
              <div className='text-center p-3'>
                <h1 className='text-warning'>
                  <div>
                    <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Face%20with%20Monocle.png" alt="Face with Monocle" width="100" height="100" />
                  </div>
                  Your Cart Is Empty!<br /></h1>
                <Link to="/allcourses" className='btn btn-primary shadowAnim p-3 w-50'>
                  <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Nerd%20Face.png" alt="Nerd Face" width="25" height="25" /> <i className='fa fa-cart-plus' /> Add Courses</Link>
              </div>}

            {fullStackCourses.length > 0 &&
              <div className='p-3'>
                <h3>Full Stack Courses</h3>
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th className="col-3">Course</th>
                      <th className="col-2">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fullStackCourses.length > 0 && fullStackCourses.map((course, index) => (
                      <tr key={index}>

                        <td>
                          <Link to={"/" + course.courselink} className="text-decoration-none">
                            <h5>
                              <img src={course.courseLogo} className='course-thumbnail rounded' alt="course logo" /> {course.title}
                            </h5>
                            <small>Includes ({getCourseNames(course.courseCombo).length} courses):<br /> {getCourseNames(course.courseCombo).join(", ")}</small>
                          </Link>
                        </td>
                        <td>
                          <h5 className='mt-3'>
                            ₹{course.price.toLocaleString('en-IN')}/-
                          </h5>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>}
            {individualCourses.length > 0 &&
              <div className='p-3'>
                <h3>Individual Courses</h3>
                <hr />
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th className="col-3">Course</th>
                      <th className="col-2">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {individualCourses.length > 0 && individualCourses.map((course, index) => {
                      const isInBundle = fullStackCourses.some((bundle) =>
                        bundle.courseCombo.includes(course._id)
                      );
                      if (isInBundle) return null;

                      return (
                        <tr key={index}>
                          <td>
                            <Link to={"/" + course.courselink} className="text-decoration-none">
                              <h5>
                                <img src={course.courseLogo} className='course-thumbnail rounded' alt="course logo" /> {course.title}
                              </h5>
                            </Link>
                          </td>
                          <td>
                            <h5 className='mt-3'>
                              ₹{course.price.toLocaleString('en-IN')}/-
                            </h5>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>}
            {uniqueCourses > 0 ?
              <CartSummary
                totalDiscountPercentage={totalDiscountPercentage}
                totalSavingsPrice={totalSavingsPrice}
                totalDiscountedPrice={totalDiscountedPrice}
                totalAmount={totalAmount}
                totalDiscountedPriceWithTax={totalDiscountedPriceWithTax}
                proceedToCheckout={proceedToCheckout}
                proceedToCheckoutOnline={proceedToCheckoutOnline}
                uniqueCourses={uniqueCourses}
              /> :
              null}
          </div>}
      </div>
    </>
  );
}

const CartSummary = ({ totalDiscountPercentage = 0, totalSavingsPrice = 0, totalDiscountedPrice = 0, totalAmount = 0, totalDiscountedPriceWithTax = 0, proceedToCheckout, proceedToCheckoutOnline, uniqueCourses }) => {
  return (
    <div className="container p-4 shadow-lg rounded bg-white w-100">

      <div className="d-flex justify-content-between align-items-center">
        <h2 className="h5 fw-bold text-dark">Cart Summary</h2>

        <div className="d-flex">
          <p className="h4 fw-bold text-dark">₹{totalDiscountedPrice.toLocaleString("en-IN")}/-</p>
          <p className="text-muted text-decoration-line-through">₹{totalAmount.toLocaleString("en-IN")}/-</p>
        </div>
      </div>

      <div className='alert alert-primary d-flex justify-content-between'>
        <div className=" d-flex flex-column">
          <p className="mb-1 fw-bold"><img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Star-Struck.png" alt="Star-Struck" width="25" height="25" /> {totalDiscountPercentage}% Discount Applied!</p>
          <p className="mb-0">
            You save <strong>₹{totalSavingsPrice.toLocaleString("en-IN")}/-</strong>
          </p>
        </div>
      </div>

      <div className="d-flex justify-content-between">
        {/* <button className="btn btn-primary w-100 m-1 shadowAnim" onClick={proceedToCheckout}>
          <strong><img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Nerd%20Face.png" alt="Nerd Face" width="25" height="25" /> Checkout</strong> <i className='fa fa-angle-double-right' /> <strong>₹{totalDiscountedPrice.toLocaleString("en-IN")}/-</strong>
        </button> */}
        <button className="btn btn-warning w-50 m-1" onClick={proceedToCheckout}>
          PhonePe (Delayed Enrollment) – <strong>₹{totalDiscountedPrice.toLocaleString("en-IN")}/- (No Tax)</strong>
          <br />
          <span className="small text-dark"><img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Thinking%20Face.png" alt="Thinking Face" width="25" height="25" /> Enrollment may take 30 min – 1 hour</span>
        </button>

        <button className="btn btn-primary w-50 m-1" onClick={() => proceedToCheckoutOnline({})}>
          Pay Online (Instant Enrollment) – <strong>₹{totalDiscountedPriceWithTax.toLocaleString("en-IN")}/- (Including Tax)</strong>
          <br />
          <span className="small"><img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Partying%20Face.png" alt="Partying Face" width="25" height="25" /> Get enrolled immediately</span>
        </button>
      </div>
    </div>
  );
};

export default function BuyNowItems() {
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <BuyNowItemsWithGoogle />
    </GoogleOAuthProvider>
  )
}